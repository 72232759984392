<div style="padding-top: 45px">
  <div class="card animated fadeIn shadow-z1 m-sm" style="cursor: pointer;"
       (click)="goto(court)" *ngFor="let court of favCourts"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-xs-4">
          <img loading="lazy" src={{court.logo}} width="100%">
        </div>
        <div class="col-xs-8">
          <div class="card-content">
            <div class="card-title">{{court.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

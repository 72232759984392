
import {first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CancelReservationStatus} from '@model/reservation/CancelReservationStatus';
import {UserReservation} from '@model/reservation/UserReservation';
import {API_HOST} from '../../consts';

@Injectable()
export class MyReservationsService {

    constructor(private http: HttpClient) {
    }

    getUserReservations(): Observable<UserReservation> {
        return this.http.get<UserReservation>(`${API_HOST}/reservation/list`);
    }

    cancelReservation(resrvationId: string): Observable<CancelReservationStatus> {
        return this.http.post<CancelReservationStatus>(`${API_HOST}/reservation/cancel/${resrvationId}`, {});
    }

    pushAutomation(reservation_id: string, action_id: string, method: string) {
        return this.http.post<any>(`${API_HOST}/reservation/action`, {
            method,
            reservation_id,
            action_id
        });
    }

    // /medicover
    // {
    //     "publicUuid": <public_uuid z reserwacji>,
    //     "secondPhoneNumber": <nrTel, string, opcjonalny - używany jedynie w przypadku gdy odbijamy dwie karty medicover>
    // }
    useMedicoverOneCard(reservationId: string, secondPhoneNumber: string|null = null): Observable<any> {
        return this.http.post<any>(`${API_HOST}/medicover/`, {
            publicUuid: reservationId,
            secondPhoneNumber
        });
    }
}

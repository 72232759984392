import {Component, OnInit} from '@angular/core';
import {AdsService} from '@shared/utils/serivces/ads.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AD_DISPLAY_INTERVAL, API_HOST} from '../../../consts';

const MODAL_ID = 'adModal';

export interface CurrentAdDetails {
    link: string;
    url: string;
}

@Component({
    selector: 'app-ad-display',
    template: `
        <ngx-smart-modal [identifier]="'adModal'" [closable]="true">
            <div class="card" style="box-shadow: none; border:none; width: fit-content; height: auto; margin: 30px auto;">
                <div class="card-content" *ngIf="showAd && adImageUrl">
                    <div class="text-center">
<!--                        <h3>Reklama text</h3>-->
                        <img (click)="closeModal()" *ngIf="adImageUrl" [src]="adImageUrl" alt="Ad" style="width: 100%; max-height: calc(100vh - 200px); object-fit: contain; cursor:pointer;">
<!--                        <button class="btn btn-primary btn-lg mt-20" (click)="closeModal()">Przejdź!</button>-->
                    </div>
                </div>
            </div>
        </ngx-smart-modal>
    `,
    styles: [`
        
    `],
})
export class AdDisplayComponent implements OnInit {
    public adImageUrl: string = null;
    public showAd = false;
    public adImageLink: string = null;

    constructor(private ngxSmartModalService: NgxSmartModalService,
                private readonly adService: AdsService) {

        const lastAdView = localStorage.getItem(`${API_HOST}-lastAdView`);
        if (lastAdView) {
            const lastAdViewDate = new Date(lastAdView);
            const now = new Date();
            if (now.getTime() - lastAdViewDate.getTime() < AD_DISPLAY_INTERVAL) {
                this.showAd = false;
            } else {
                localStorage.setItem(`${API_HOST}-lastAdView`, new Date().toISOString());
                this.showAd = true;
            }
        } else {
            localStorage.setItem(`${API_HOST}-lastAdView`, new Date().toISOString());
            this.showAd = true;
        }
    }

    ngOnInit(): void {
        if (!this.showAd) {
            return;
        }
        this.adService.getAd().subscribe(next => {
            if (!next) {
                debugger;
                return;
            }
            this.adImageUrl = next.url;
            this.adImageLink = next.link;
            if (!this.adImageUrl) {
                return;
            }
            // jeśli adImageUrl zawiera tekst '78.47.27.60' to zastąp go przez API_HOST
            if (this.adImageUrl.includes('78.47.27.60')) {
                this.adImageUrl = this.adImageUrl.replace('78.47.27.60', API_HOST);
            }
            this.ngxSmartModalService.getModal(MODAL_ID).open();
        });
    }

    closeModal() {
        this.adService.clickOnAdd().subscribe();
        this.ngxSmartModalService.getModal(MODAL_ID).close();
        if (this.adImageLink) {
            window.open(this.adImageLink, '_blank');
        }
    }
}

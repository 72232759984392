<table class="table table-striped animated fadeIn">
    <tbody>
    <tr>
        <td><em class="ion-key icon-fw mr"></em>Stare hasło</td>
        <td>
            <input class="form-control" type="password" [(ngModel)]="passwordDto.oldPassword"
                   placeholder="Stare hasło" #passwordOld="ngModel" required>
            <div *ngIf="passwordOld.invalid && (passwordOld.dirty || passwordOld.touched)"
                 class="text-danger">
                <div class="animated flash" *ngIf="passwordOld.errors.required">
                    Pole jest wymagane
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td><em class="ion-key icon-fw mr"></em>Nowe hasło</td>
        <td>
            <input class="form-control" type="password" [(ngModel)]="passwordDto.newPassword"
                   placeholder="Nowe hasło" #passwordRep="ngModel"
                   required minlength="6"
                   [ngModelOptions]="{standalone: true}">
            <div *ngIf="passwordRep.invalid && (passwordRep.dirty || passwordRep.touched)"
                 class="text-danger">
                <div class="animated flash" *ngIf="passwordRep.errors.required">
                    Pole jest wymagane
                </div>
                <div class="animated flash" *ngIf="passwordRep.errors.minlength">
                    Hasło musi się składać z minimum 6 znaków
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td><em class="ion-key icon-fw mr"></em>Powtórz nowe hasło</td>
        <td>
            <input class="form-control" type="password" #password="ngModel"
                   required  minlength="6"
                   [(ngModel)]="passwordDto.newPassRepeat" placeholder="Powtórz nowe hasło"
                   [ngModelOptions]="{standalone: true}">
            <div *ngIf="password.invalid && (password.dirty || password.touched)"
                 class="text-danger">
                <div class="animated flash" *ngIf="password.errors.required">
                    Pole jest wymagane
                </div>
                <div class="animated flash" *ngIf="password.errors.minlength">
                    Hasło musi się składać z minimum 6 znaków
                </div>
            </div>
        </td>
    </tr>
    </tbody>
</table>

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tournament-status',
  template: `
    <span *ngIf="status === 'CREATED'" class="badge badge-boxed bg-created">zapisy</span>
    <span *ngIf="status === 'STARTED'" class="badge badge-boxed bg-started">rozpoczęty</span>
    <span *ngIf="status === 'FINISHED'" class="badge badge-boxed bg-finished">zakończony</span>
    <span *ngIf="status === 'APPLICATIONS_CLOSED'" class="badge badge-boxed">zamknięcie zapisów</span>
    <span *ngIf="status === 'GENERATED'" class="badge badge-boxed">losowanie</span>
  `,
  styles: [`
      .bg-created {
          background-color: #8BC34A;
      }

      .bg-started {
          background-color: #607D8B;
      }

      .bg-finished {
          background-color: #F5F5F5;
          color: #000;
      }
  `]
})
export class TournamentStatusComponent {
  @Input() status: string;
}

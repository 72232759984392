import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapCourt} from '@model/map/MapCourt';

@Component({
    selector: 'app-card-multiple-court-detail',
    template: `
        <div *ngIf="court">
            <div *ngFor="let c of court.additionalCourts; index as i">
                <hr *ngIf="i !== 0" style="margin: 12px 0;"/>
                <div class="flex-center">
                    <div>
                        <img loading="lazy" src={{court.additionalThumbs[i]}}>
                    </div>
                    <div class="ml-15">
                        <div class="card-title" [class.text-bold]="boldHerder">{{c.name}}</div>
                        <div class="card-text">
                            ceny / 1h: <span class="color-blue-500">{{c.pricing}}</span>
                        </div>
                        <div class="card-text">
                            rezerwacje:
                            <span *ngIf="c.reservation" class="text-success text-bold">on-line</span>
                            <span *ngIf="!c.reservation" class="text-bold">off-line</span>
                        </div>
                        <!--<span *ngIf="c.reservation">-->
                        <span *ngIf="c.marker === 'ACTIVE' || c.marker === 'RESERVATIONS_DISABLED'" class="badge bg-success">czynne</span>
                        <span *ngIf="c.marker === 'FLOODED'" class="badge bg-warning">zalane</span>
                        <span *ngIf="c.marker === 'NO_ACTIVE_STATIONS'" class="badge bg-gray-light">nieczynne</span>
                        <!--</span>-->
                        <span *ngIf="c.multisport" class="badge bg-blue-500">multisport</span>
                        <span *ngIf="c.medicover" class="badge bg-blue-700">medicover</span>
                        <a *ngIf="moreInfo" (click)="routeChange(c)" class="badge bg-black">zarezerwuj</a>
                    </div>
                </div>
            </div>
        </div>

    `,
    styles: [`
        .card-title {
            line-height: 24px;
            margin-bottom: 8px;
            font-size: 18px;
            font-weight: 300;
        }

        .badge {
            border-radius: 4px;
            width: min-content;
            padding: 0 4px;
            font-weight: 300;
            margin-top: 10px;
        }

        .bg-gray-light {
            color: #fff !important;
        }

        img {
            width: 110px;
            height: auto;
            border-radius: 5px;
        }
    `]
})
export class CardMultipleCourtDetailComponent {
    @Input() court: MapCourt;
    @Input() moreInfo = false;
    @Input() boldHerder = false;

    @Output() onRouteChange = new EventEmitter<MapCourt>();

    routeChange(marker: MapCourt) {
        this.onRouteChange.emit(marker);
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {AvatarModule} from 'ngx-avatar';
import {UserModule} from '../routes/user/user.module';

import { SharedModule } from '@shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        AvatarModule,
        UserModule,
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
    ]
})
export class LayoutModule { }

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReservationIndividual} from '@model/reservation/UserReservation';
import * as moment from 'moment';
import swalConfirm from '@shared/swal/confirm';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {swalError} from '@shared/swal/swalError';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {generatePaymentLink} from '@shared/utils/generatePaymentLink';
import {generatePrzelewy24PaymantLink} from '@shared/utils/generatePrzelewy24PaymantLink';

@Component({
    selector: 'app-dashboard-reservations-single',
    template: `
        <div class="no-data-title" *ngIf="!reservations.length">
            <div class="header">Nie masz żadnej rezerwacji.</div>
            Kliknij na mapę kortów, wybierz odpowiadający Ci kort i zarezerwuj termin.
        </div>
        <div class="reservations-single" [class.history]="history">
            <div class="" *ngFor="let reservation of reservations; index as i"
                 [class.border-bottom-light]="i !== 0"
            >
                <div class="animated fadeIn pt-30" style="display: flex;">
                    <div class="flex-column-center w-50">

                        <div class="text-muted"><i style="width: 15px; text-align: center;"
                                                   class="fa fa-clock-o"></i> {{ reservation.starts_at | momentDate:'HH:mm' }}
                            - {{ reservation.ends_at | momentDate:'HH:mm' }}
                        </div>
                        <div class="text-muted"><i style="width: 15px; text-align: center;"
                                                   class="fa fa-calendar"></i> {{ reservation.starts_at | momentDayOfWeekName }} {{ reservation.starts_at | momentDate:'DD.MM' }}
                        </div>
                        <div class="text-muted"><i style="width: 15px; text-align: center;" class="fa fa-usd"></i> <span
                                class="pl-5 color-blue-500">{{ reservation.price.toFixed(2) }} zł</span></div>

                    </div>
                    <div class="flex-column-center w-50 pl-10" style="border-left: 1px solid rgb(173 173 173 / 50%);">

                        <p class="mb-0" style="font-size: 14px; font-weight: 600;">{{ reservation.court_name }}</p>
                        <div class="text-muted">{{ reservation.station_name }}
                            <em *ngIf="reservation.lightning" class="ion-android-bulb"></em></div>
                        <div>
                            <span *ngIf="reservation.coach" class="color-blue-500">trener: {{ reservation.coach.name }}</span>
                            <span *ngIf="reservation.student" class="color-blue-500">gracz: {{ reservation.student.name }}</span>
                            <span *ngIf="reservation.match" class="color-blue-500">przeciwnik: {{ reservation.match.opponent1.name }}</span>
                        </div>

                    </div>
                </div>

                <div *ngIf="reservation.message" class="text-muted">
                    <i style="width: 15px; text-align: center;" class="fa fa-commenting-o"></i>
                    {{ reservation.message }}
                </div>

                <div style="display: flex; justify-content: space-around; margin: 10px 0 20px;">
                    <!--cancel button-->
                    <div (click)="cancel(reservation)" class="text-center">
                        <button [disabled]="paymentAvailable(reservation)" style="border-radius: 50%"
                                class="btn btn-sm btn-danger"><i class="fa fa-remove"></i></button>
                        <div>Odwołaj</div>
                    </div>
                    <!--payment button when paid-->
                    <div *ngIf="!paymentAvailable(reservation) && (reservation.payment_status == 'CASH_PAID' || reservation.payment_status == 'ONLINE_PAID')"
                         class="text-center">
                        <button
                                style="border-radius: 50%" class="btn btn-sm btn-success">
                            <i class="fa fa-check" style="width: 11px; margin-left: -2px;"></i>
                        </button>
                        <div *ngIf="reservation.payment_status === 'CASH_PAID'">Zapłacono</div>
                        <div *ngIf="reservation.payment_status === 'ONLINE_PAID'">Zapłacono online</div>
                    </div>
                    <!--payment button when payment available-->
                    <div *ngIf="paymentAvailable(reservation)" (click)="payForReservation(reservation)" class="text-center">
                        <button style="border-radius: 50%" class="btn btn-sm color-white bg-amber-600">
                            <i class="fa fa-exclamation" style="width: 11px; margin-left: -1px;"></i>
                        </button>
                        <div>Zapłać</div>
                    </div>
                    <!--payment button when not paid-->
                    <div *ngIf="reservation.price > 0 && !paymentAvailable(reservation) && (reservation.payment_status == 'CASH_UNPAID' || reservation.payment_status == 'ONLINE_UNPAID')"
                         class="text-center">
                        <button style="border-radius: 50%" class="btn btn-sm bg-amber-600 color-white">
                            <i class="fa fa-exclamation" style="width: 11px; margin-left: -1px;"></i>
                        </button>
                        <div *ngIf="reservation.payment_status === 'CASH_UNPAID'">Nie zapłacono</div>
                        <div *ngIf="reservation.payment_status === 'ONLINE_UNPAID'">Nie zapłacono</div>
                    </div>
                    <!-- show medicover section -->
                    <div *ngIf="(reservation.medicover_cards > 0)"
                         (click)="activeReservationAction === i ? activeReservationAction = null : activeReservationAction = i"
                         class="text-center">
                        <button style="border-radius: 50%" class="btn btn-sm bg-blue-600">
                            <i *ngIf="activeReservationAction !== i" class="fa fa-chevron-down" style="width: 11px; margin-left: -2px;"></i>
                            <i *ngIf="activeReservationAction === i" class="fa fa-chevron-up" style="width: 11px; margin-left: -2px;"></i>
                        </button>
                        <div>Medicover</div>
                    </div>
                    <!-- show/hide actions button-->
                    <div *ngIf="(reservation.automation_actions && reservation.automation_actions.length)"
                         (click)="activeReservationAction === i ? activeReservationAction = null : activeReservationAction = i"
                         class="text-center">
                        <button style="border-radius: 50%" class="btn btn-sm bg-blue-600">
                            <i *ngIf="activeReservationAction !== i" class="fa fa-chevron-down" style="width: 11px; margin-left: -2px;"></i>
                            <i *ngIf="activeReservationAction === i" class="fa fa-chevron-up" style="width: 11px; margin-left: -2px;"></i>
                        </button>
                        <div>Akcje</div>
                    </div>
                    <!--coach avatar / student avatar-->
                    <div *ngIf="reservation.coach || reservation.student">
                        <div *ngIf="reservation.coach">
                            <app-avatar [userId]="reservation.coach.id" src="{{reservation.coach.picture_miniature}}"
                                        size="42"></app-avatar>
                        </div>
                        <div *ngIf="reservation.student">
                            <app-avatar [userId]="reservation.student.id" src="{{reservation.student.picture_miniature}}"
                                        size="42"></app-avatar>
                        </div>
                    </div>
                    <!--opponent avatar-->
                    <div *ngIf="reservation.match" class="text-center">
                        <app-avatar [userId]="reservation.match.opponent1.id" src="{{reservation.match.opponent1.picture_miniature}}"
                                    size="42"></app-avatar>
                    </div>
                </div>


                <div *ngIf="activeReservationAction === i && reservation.automation_actions && reservation.automation_actions.length"
                     style="display: flex; justify-content: space-around; margin: 10px 0;" class="actions">
                    <app-reservation-automation-actions-based-on-buttons
                            [automationAction]="reservation.automation_actions_with_id"
                            (actionExecuted)="onReservationUpdated.emit()"
                    ></app-reservation-automation-actions-based-on-buttons>
                </div>

                <div *ngIf="activeReservationAction === i && reservation.medicover_cards > 0"
                     style="display: flex; justify-content: space-around; margin: 10px 0;" class="actions">
                    <ng-container *ngIf="!checkIfLessThan15MinutesToReservation(reservation)">
                        <div class="text-muted text-center">Odbicie karty Medicover możliwe 15 minut przed rezerwacją.</div>
                    </ng-container>
                    <ng-container *ngIf="checkIfLessThan15MinutesToReservation(reservation)">
                        <button *ngIf="reservation.medicover_cards == 1" class="btn btn-sm bg-blue-600 color-white"
                                (click)="useMedicoverOneCard(reservation)">Medicover - zarejestruj wejście
                        </button>
                        
                        <div *ngIf="reservation.medicover_cards == 2" class="text-muted text-center">
                            <input type="text" class="form-control" placeholder="Numer telefonu" [(ngModel)]="secondMedicoverPhone" />
                            <button class="btn btn-sm bg-blue-600 color-white" [disabled]="!secondMedicoverPhone"
                                    (click)="useMedicoverOneCard(reservation)">Medicover - zarejestruj wejście
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./../dashboard.component.scss']
})

// Odbicie karty jest mozliwe tylko dla rezerwacji na ktorych jest automatyzacja. Sciezka wyglada następująco:
//
// Jesli user ma zaznaczonego medicovera to zamiast przyciskow do automatyzacji wyswietlamy mu komunikat. "Zarejestruj wejscie z kartą Medicover. Odbicie karty mozliwe 15 minut przed rezerwacja"
//
// Kiedy jest 15 minut pojawia sie przycisk "Zarejestruj"
//
// Jesli user w rezerwacji odbija 1 karte to wyswietlamy mu juz po kliknięciu w przycisk komunikat o sukcesie lub bledzie. Natomiast jesli odbija 2 karty to w modalu musi podac jeszcze nr tel dla 2 karty (dla 1 karty jest pobierany numer z profilu automatycznie)
export class DashboardReservationsSingleComponent {
    @Input() withButton = true;
    @Output() onReservationUpdated = new EventEmitter();
    activeReservationAction = null;
    @Input() history = false;

    public secondMedicoverPhone = null;

    constructor(private service: MyReservationsService) {
    }

    private _reservations: ReservationIndividual[] = [];

    get reservations() {
        return this._reservations;
    }

    @Input('reservations')
    set reservations(value: ReservationIndividual[]) {
        this._reservations = value;
        this._reservations.map(r => {
                r.automation_actions_with_id = {
                    reservationId: r.id,
                    actions: r.automation_actions
                };
                return r;
            }
        );
    }

    checkIfLessThan15MinutesToReservation(reservation: ReservationIndividual): boolean {
        const now = moment();
        const reservationStart = moment(reservation.starts_at);
        return reservationStart.diff(now, 'minutes').valueOf() < 15;
    }

    dateTimeFormatStartEnd(reservation: ReservationIndividual): string {
        const start = moment(reservation.starts_at).locale('pl');
        const end = moment(reservation.ends_at).locale('pl');

        return start.format('YYYY-MM-DD, HH:mm') + '-' + end.format('HH:mm');
    }

    payForReservation(reservation: ReservationIndividual) {
        let paymentLink = null;
        if (reservation.tpay_payment) {
            paymentLink = generatePaymentLink(reservation.tpay_payment ? reservation.tpay_payment : reservation.payment);
        }
        if (reservation.p24_payment) {
            paymentLink = generatePrzelewy24PaymantLink(reservation.p24_payment.przelewy24_token);
        }

        if (!paymentLink) {
            swalError({
                title: 'Błąd',
                text: 'Nie można zapłacić za rezerwację'
            });
            return;
        }
        // saveRedirectStateForOnlinePayment(reservation.code);
        window.location.href = paymentLink;
    }

    paymentAvailable(reservation: ReservationIndividual): boolean {
        if (reservation.payment_status === 'CASH_UNPAID' || reservation.payment_status === 'ONLINE_UNPAID') {
            return !!reservation.tpay_payment || !!reservation.p24_payment?.przelewy24_token;
        }
        // return !(!(reservation.tpay_payment || reservation.payment) || reservation.payment_status === 'CASH_PAID' || reservation.payment_status === 'ONLINE_PAID');
    }

    cancel(reservation: ReservationIndividual, repeatable = false) {
        const that = this;
        const additionalText = repeatable ? ' cykliczną' : '';
        const paymentText = reservation.payment_status === 'ONLINE_PAID' ? `<br>Poniesiona opłata zostanie zwrócona automatycznie na konto do 4 dni roboczych z potrąceniem kosztów serwisowych wynoszących 2%</p>` : '';

        swalConfirm(
            'Anulowanie rezerwacji',
            () => {
                that.cancelReservation(reservation, repeatable);
            },
            `<p>Czy na pewno chcesz odwołać rezerwację${additionalText}?${paymentText}</p>`
        );
    }

    private cancelReservation(reservation: ReservationIndividual, repeatable) {
        this.service.cancelReservation(reservation.id)
            .subscribe(() => {

                const deletedIndex = this.reservations.indexOf(reservation);
                this.reservations.splice(deletedIndex, 1);
                swalSuccess({title: 'Rezerwacja odwołana'});

            }, err => {
                let message = err.error.status;
                switch (err.error.status) {
                    case 'WRONG_CODE':
                        message = 'Wystąpił błąd';
                        break;
                    case 'ALREADY_CANCELED':
                        message = 'Ta rezerwacja jest już odwołana';
                        break;
                    case 'CANNOT_CANCEL':
                        message = 'Rezerwację można odwołać do ' + err.error.max_hours + ' godzin przed rozpoczęciem gry';
                        break;
                }
                swalError({
                    title: 'Niepowodzenie',
                    text: message
                });
            });
    }

    useMedicoverOneCard(reservation: ReservationIndividual) {
        this.service.useMedicoverOneCard(reservation.id, this.secondMedicoverPhone)
            .subscribe(() => {
                swalSuccess({title: 'Karta Medicover została zarejestrowana'});
                this.onReservationUpdated.emit();
            }, err => {
                swalError({
                    title: 'Niepowodzenie',
                    text: err.error.message
                });
            });

    }
}

import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'reservationDateTime'
})
export class ReservationDateTimePipe implements PipeTransform {
    transform(data: any, begin: string, end: string) {
        return moment(begin).format('YYYY-MM-DD HH:mm') + ' - ' + moment(end).format('HH:mm');
    }
}

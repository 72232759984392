export default function apiMessageTranslate(message?: string): string {
    if (!message) {
        return '';
    }
    if (message.indexOf('content type is not supported') > 0) {
        return 'Nieobsługiwany format pliku';
    }

    if (message.includes('Invalid set result configuration')) {
        return 'Niepoprawny format wyniku';
    }

    switch (message) {
        case 'You cannot set round end to passed date':
            return 'Nie możesz ustawić czasu przeszłego';
        case 'This match already has result':
            return 'Ten mecz już ma wprowadzony wynik';
        case 'You cannot set round end to this date. Check reservations in matches.':
            return 'Data zakończenia rundy powinna być późniejsza niż data zakończenia ostatniego meczu w tej rundzie.';
        case 'Incorrect old password':
            return 'Podano niepoprawne hasło';
        case 'You are not allowed to cancel tournament reservations':
            return 'W przypadku konieczności zmiany terminu meczu turniejowego skontaktuj się z jego organizatorem lub skorzystaj z funkcji „zgłoś konflikt” w miejscu gdzie dokonana została rezerwacja (zakładka „moje mecze”).';
        case 'Reservation is not finished yet':
            return 'Rezerwacja jeszcze się nie zakończyła';
        case 'Set result should be set set for both sides':
            return 'Wynik seta musi być ustawiony po obu stronach';
        case 'This challenge already has result':
            return 'Ten mecz już posiada wprowadzony wynik';
        case 'TOURNAMENT APPLICATION IS CLOSED':
            return 'Zapisy na ten turniej są już zamknięte';
        case 'OUT_OF_RESERVATION_MAKING_HOURS':
            return 'Kort ma wyłączoną możliwość rezerwacji w godzinach 16:30 - 22:00 oraz w weekendy';
        case 'RESERVATION_TOO_SHORT':
            return 'Minimalna długość rezerwacji wynosi 60 minut';
        default:
            return message;
    }
}

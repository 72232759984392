import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API_HOST} from '../../../consts';
import {CurrentAdDetails} from '@shared/utils/components/ad-display.component';

@Injectable()
export class AdsService {

    constructor(private http: HttpClient) {
    }

    getAd(): Observable<CurrentAdDetails> {
        return this.http.get<any>(`${API_HOST}/advertisements`);
    }

    clickOnAdd(): Observable<any> {
        return this.http.get(`${API_HOST}/advertisements/click`);
    }
}

import {Whoami} from '@core/authentication/whoami';
import {API_HOST} from '../../consts';

export const saveWhoami = (data: Whoami) => {
    localStorage.setItem(getKey(), JSON.stringify(data));
}

export const getWhoami = (): Whoami|null => {
    const whoami = localStorage.getItem(getKey());
    if (!whoami) {
        return null;
    }
    return JSON.parse(whoami);
}

export const clearWhoami = () => {
    localStorage.removeItem(getKey());
}

const getKey = () => {
    return `whoami-${API_HOST}`.replace('https://', '');
}


import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
// import {TranslateModule} from '@ngx-translate/core';

import {AccordionModule} from 'ngx-bootstrap/accordion';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDatepickerModule, DatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {CardCourtDetailComponent} from '../routes/court/map-view/card-court-detail/card-court-detail.component';
import {CardTournamentCourtComponent} from '../routes/court/map-view/card-tournament-court/card-tournament-court.component';
import {CardTournamentDetailComponent} from '../routes/court/map-view/card-tournament-detail/card-tournament-detail.component';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {FavoriteCourtsService} from '@services/shared/favorite-courts.service';
import {UnderscorePropertyNamingService} from '@services/shared/underscore-property-naming.service';
import {TournamentBracketHelper} from '@services/tournament/tournament-bracket.helper';
import {TournamentService} from '@services/tournament/tournament.service';
import {AvatarComponent} from './components/avatar/avatar.component';
import {HeartComponent} from './components/heart/heart.component';
import {LoaderComponent} from './components/loader/loader.component';

import {EasypiechartDirective} from './directives/easypiechart/easypiechart.directive';
import {FlotDirective} from './directives/flot/flot.directive';
import {KnobDirective} from './directives/knob/knob.directive';
import {RippleDirective} from './directives/ripple/ripple.directive';
import {SparklineDirective} from './directives/sparkline/sparkline.directive';
import {SvgreplaceDirective} from './directives/svgreplace/svgreplace.directive';
import {VectormapDirective} from './directives/vectormap/vectormap.directive';
import {MatchStatusPipe} from './pipes/matchStatusPipe';
import {MomentDatePipe} from './pipes/momentDatePipe';
import {NoDataPipe} from './pipes/noDataPipe';
import {PriceCostPipe} from './pipes/price-cost.pipe';
import {PriceHourPipe} from './pipes/price-hour.pipe';
import {ReservationColorPipe} from './pipes/reservation-color.pipe';
import {ReservationStatusPipe} from './pipes/reservation-status.pipe';
import {ReservationDateTimePipe} from './pipes/reservationDateTimePipe';
import {RtaImgPipe} from './pipes/rtaImgPipe';
import {ScorePipe} from './pipes/ScorePipe';
import {TariffCostPipe} from './pipes/tariff-cost.pipe';
import {TournamentStatusPipe} from './pipes/tournamentStatusPipe';
import {TournamentTypePipe} from './pipes/tournamentTypePipe';
import {WalkowerPipe} from './pipes/walkowerPipe';

import {SettingsService} from './settings/settings.service';
import {PlayerNameOrRoundNamePipe} from './pipes/playerNameOrRoundNamePipe';
import {RoundNameWithPositionPipe} from './pipes/roundNameWithPositionPipe';
import {FullRoundNamePipe} from '@shared/pipes/fullRoundNamePipe';
import {NotificationComponent} from '@shared/components/notification/notification.component';
import {NotificationService} from '@services/notification/notification.service';
import {NotificationRepository} from '@services/notification/notification.repository';
import {CourtTournamentsModalComponent} from '../routes/court/tabs/info/court-tournaments-modal/court-tournaments-modal.component';
import { TournamentStatusComponent } from './components/tournament-status/tournament-status.component';
import { TournamentTypeComponent } from './components/tournament-type/tournament-type.component';
import {ReservationSummaryComponent} from '../routes/court/tabs/reservation/components/reservation-summary/reservation-summary.component';
import {DisplayStationsPipe} from '../routes/court/tabs/reservation/display-stations-pipe';
import {DisplayHourPipe} from '../routes/court/tabs/reservation/display-hour.pipe';
import {ProvincePipe} from '@shared/pipes/provincePipe';
import { TournamentMatchScoreComponent } from './components/tournament-match-score/tournament-match-score.component';
import {TournamentPositionPipe} from '@shared/pipes/tournamentPositionPipe';
import {TournamentMyMatchDetailComponent} from '@shared/components/tournament-my-math-detail-item/tournament-my-match-detail.component';
import {ChallengeScoreSimplePipe} from '@shared/pipes/challengeScoreSimplePipe';
import {ChatService} from '@services/tournament/chat.service';
import {ClickStopPropagation} from '@shared/directives/StopPropagationDirective';
import {HttpClientJsonpModule} from '@angular/common/http';
import { SupportedCourtsListComponent } from './components/supported-courts-list/supported-courts-list.component';
import {CardMultipleCourtDetailComponent} from '../routes/court/map-view/card-multiple-court-detail/card-multiple-court-detail.component';
import { SelectComponent } from './components/forms/select.component';
import {TextareaComponent} from '@shared/components/forms/textarea.component';
import {ModalHeaderComponent} from '@shared/components/modal/modal-header.component';
import {ModalFooterComponent} from '@shared/components/modal/modal-footer.component';
import {InputComponent} from '@shared/components/forms/input.component';
import {ChallengeBonusPipe} from '@shared/pipes/challengeBonusPipe';
import {DetailComponent} from '../routes/court/tabs/info/detail/detail.component';
import {CourtDetailService} from '@services/court-detail.service';
import {GalleryComponent} from '../routes/court/tabs/info/gallery/gallery.component';
import {PricingComponent} from '../routes/court/tabs/info/pricing/pricing.component';
import {BallComponent} from '../routes/court/tabs/info/detail/ball.component';
import {ReservationAutomationActionsComponent} from '../routes/user/dashboard/components/common/reservation-automation-actions.component';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {
  ReservationAutomationActionsBasedOnButtonsComponent
} from '../routes/user/dashboard/components/common/reservation-automation-actions-based-on-buttons.component';
import {InfoComponent} from '../routes/court/tabs/info/info.component';
import {
  CardCourtDetailWithThumbComponent
} from '../routes/court/map-view/card-court-detail-with-thumb/card-court-detail-with-thumb.component';
import {MomentDayOfWeekName} from '@shared/pipes/momentDayOfWeekNamePipe';
import {SeriesIdNamePipe} from '@shared/pipes/seriesIdNamePipe';
import {SeriesIdDescriptionPipe} from '@shared/pipes/seriesIdDescriptionPipe';
import {GoogleMapsModule} from '@angular/google-maps';
import {MapDataService} from '@services/court/map-data.service';
import {MomentMonthNamePipe} from '@shared/pipes/momentMonthNamePipe';
import {AvatarWithNameComponent} from '@shared/components/avatar/avatar-with-name.component';
import {UserModule} from '../routes/user/user.module';
import {AdsService} from '@shared/utils/serivces/ads.service';
import {AdDisplayComponent} from '@shared/utils/components/ad-display.component';

const pipes = [
  PriceHourPipe,
  ReservationColorPipe,
  ReservationStatusPipe,
  RtaImgPipe,
  PriceCostPipe,
    MomentMonthNamePipe,
  ReservationDateTimePipe,
  TariffCostPipe,
  TournamentStatusPipe,
  TournamentTypePipe,
  MomentDatePipe,
  MatchStatusPipe,
  NoDataPipe,
  ScorePipe,
  WalkowerPipe,
  PlayerNameOrRoundNamePipe,
  RoundNameWithPositionPipe,
  FullRoundNamePipe,
  DisplayHourPipe,
  ProvincePipe,
  TournamentPositionPipe,
  ChallengeScoreSimplePipe,
  ChallengeBonusPipe,
  SeriesIdNamePipe,
  SeriesIdDescriptionPipe,
  MomentDayOfWeekName
];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  // TranslateModule,
  RouterModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CarouselModule,
  CollapseModule,
  DatepickerModule,
  BsDatepickerModule,
  BsDropdownModule,
  ModalModule,
  PaginationModule,
  ProgressbarModule,
  RatingModule,
  TabsModule,
  TimepickerModule,
  TooltipModule,
  TypeaheadModule,
  GoogleMapsModule
];

const directives = [
  EasypiechartDirective,
  FlotDirective,
  SparklineDirective,
  SvgreplaceDirective,
  VectormapDirective,
  KnobDirective,
  RippleDirective,
  ClickStopPropagation
];

const components = [
  HeartComponent,
  CardCourtDetailComponent,
  CardCourtDetailWithThumbComponent,
  CardMultipleCourtDetailComponent,
  CardTournamentDetailComponent,
  LoaderComponent,
  CardTournamentCourtComponent,
  AvatarComponent,
    AvatarWithNameComponent,
  NotificationComponent,
  CourtTournamentsModalComponent,
  ReservationSummaryComponent,
  TournamentMyMatchDetailComponent,
  TournamentStatusComponent,
  TournamentTypeComponent,
  TournamentTypeComponent,
  TournamentMatchScoreComponent,
  TournamentMyMatchDetailComponent,
  SupportedCourtsListComponent,
  TextareaComponent,
  SelectComponent,
  ModalHeaderComponent,
  ModalFooterComponent,
  InputComponent,
  DetailComponent,
  GalleryComponent,
  PricingComponent,
    BallComponent,
  ReservationAutomationActionsComponent,
  ReservationAutomationActionsBasedOnButtonsComponent,
  InfoComponent,
  AdDisplayComponent
];

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        // TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        NgxSmartModalModule.forChild(),
        TypeaheadModule.forRoot(),
        UiSwitchModule,
    ],
  providers: [
    SettingsService,
    FavoriteCourtsService,
    UnderscorePropertyNamingService,
    AuthenticationService,
    AuthRepository,
    MyReservationsService,
    TournamentService,
    ChatService,
    TournamentBracketHelper,
    NotificationService,
    NotificationRepository,
    CourtDetailService,
    MapDataService,
    AdsService
  ],
  declarations: [
    ...directives,
    ...components,
    ...pipes,
  ],
  exports: [
    ...modules,
    ...directives,
    ...components,
    ...pipes,
  ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}

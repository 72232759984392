import {Injectable} from '@angular/core';
import {Bracket} from '@model/tournament/bracket';
import {TournamentDetails} from '@model/tournament/tournament-details';
import {Game, GameProps, SideInfo} from '../../routes/tournament/tabs/components/bracket-preview/tournament-bracket/model';
import getRoundName from '@shared/utils/getRoundName';

@Injectable()
export class TournamentBracketHelper {

    public brackets: Bracket[] = [];
    private max_rounds = 2;
    private game: any;
    private tournament: TournamentDetails;
    private playersLength: number;

    getBracketForTournament(
      tournamentInfo: TournamentDetails,
      actualBrackets: Bracket[],
      playersLength: number): GameProps {

        this.brackets = actualBrackets;
        this.tournament = tournamentInfo;
        this.playersLength = playersLength;
        return this.generateGameTemplate();
    }

    generateGameTemplate(): GameProps {
        const doNothing = () => {
        };
        const size = this.playersLength;

        this.max_rounds = 0;
        size > 2 ? this.max_rounds = 1 : doNothing();
        size > 4 ? this.max_rounds = 2 : doNothing();
        size > 8 ? this.max_rounds = 3 : doNothing();
        size > 16 ? this.max_rounds = 4 : doNothing();
        size > 32 ? this.max_rounds = 5 : doNothing();
        size > 64 ? this.max_rounds = 6 : doNothing();
        size > 128 ? this.max_rounds = 7 : doNothing();

        switch (this.tournament.status) {
            case 'CREATED':
                alert('nieodpowiedni status turnieju');
                break;
            case 'APPLICATIONS_CLOSED':
            default:
                this.game = this.createGame();
                break;
        }

        return {
            game: this.game,
            winningPathLength: this.max_rounds + 1
        };
    }

    createGame(round = 0, positionInRound = 0) {
        const game = new Game();

        const bracket = this.brackets.find(b => b.round === round && b.position_in_round === positionInRound);
        if (!bracket) {
            return null;
        }

        // game.id = bracket.id;
        game.name = getRoundName(round);
        game.bracketLabel = `Pozycja ${positionInRound + 1}`;
        game.scheduled = bracket.reservation_starts_at;
        game.station_name = bracket.station_name ? bracket.station_name : '';
        game.in_progress = bracket.in_progess &&
            bracket.status === 'OPEN' &&
            !(bracket.result === 'WON_RIGHT'
                || bracket.result === 'WON_LEFT'
                || bracket.result === 'FAILED');

        game.sides = {
            home: this.getSideInfo(bracket, true),
            visitor: this.getSideInfo(bracket, false)
        };

        return {
            id: game.id,
            name: game.name,
            scheduled: game.scheduled,
            sides: game.sides,
            station_name: game.station_name,
            in_progress: game.in_progress,
            bracketLabel: game.bracketLabel,
            court: null
        };
    }

    getSideInfo(bracket: Bracket, home = true) {
        const sideInfo = new SideInfo();
        sideInfo.score = {
            score: null,
            firstSet: this.parseScore(bracket, 'first', home),
            secondSet: this.parseScore(bracket, 'second', home),
            thirdSet: this.parseScore(bracket, 'third', home)
        };

        let picture = null;
        let userId = null;
        if (home && bracket.player_left1_realname) {
            picture = bracket.player_left1_picture;
            userId = bracket.player_left1_id;
        }
        if (!home && bracket.player_right1_realname) {
            picture = bracket.player_right1_picture;
            userId = bracket.player_right1_id;
        }
        sideInfo.seed = {
            rank: bracket.round,
            picture,
            sourcePool: {},
            displayName: home ? bracket.player_left1_realname : bracket.player_right1_realname,
            sourceGame: this.createGame(bracket.round + 1, home ? bracket.position_in_round * 2 : bracket.position_in_round * 2 + 1),
            userId
        };

        if (home) {
            sideInfo.team = {
                id: bracket.player_left1_id,
                name: bracket.player_left1_realname,
                matchId: bracket.id
            };
        } else {
            sideInfo.team = {
                id: bracket.player_right1_id,
                name: bracket.player_right1_realname,
                matchId: bracket.id
            };
        }

        return {
            score: sideInfo.score,
            seed: sideInfo.seed,
            team: sideInfo.team
        };
    }

    parseScore(bracket: Bracket, set: string, home: boolean): string | number {
        let result = null;
        if (set === 'first') {
            result = home ? bracket.left_player_first_set_result : bracket.right_player_first_set_result;
            if (bracket.left_player_first_set_result === 0 && bracket.right_player_first_set_result === 0) {
                result = null;
            }
        }
        if (set === 'second') {
            result = home ? bracket.left_player_second_set_result : bracket.right_player_second_set_result;
            if (bracket.left_player_second_set_result === 0 && bracket.right_player_second_set_result === 0) {
                result = null;
            }
        }
        if (set === 'third') {
            result = home ? bracket.left_player_third_set_result : bracket.right_player_third_set_result;
            if (bracket.left_player_third_set_result === 0 && bracket.right_player_third_set_result === 0) {
                result = null;
            }
        }

        if (result == null) {
            return null;
        }

        return result === -1
          ? 'W'
          : result;
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'rtaImgPipe'
})
export class RtaImgPipe implements PipeTransform {
    transform(points: number) {

        if ([250, 500, 1000].includes(points)) {
            return `/assets/images/tournament/RTA${points}.png`;
        } else {
            return `/assets/images/tournament/RTA125.png`;
        }
    }
}


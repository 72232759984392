import {Component, Input} from '@angular/core';
import {Whoami} from '@core/authentication/whoami';

@Component({
  selector: 'app-user-panel-table',
  template: `
    <table *ngIf="user" class="table table-striped animated fadeIn">
      <tbody>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Telefon</span></td>
        <td>{{user.phone}}</td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Email</span></td>
        <td><span>{{user.email}}</span></td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Miejscowość</span></td>
        <td><span>{{user.city}}</span></td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Województwo</span></td>
        <td>{{user.province | provincePipe}}</td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Rok urodzenia</span></td>
        <td><span>{{user.birth_year}}</span></td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Dominująca ręka</span></td>
        <td><span>{{user.dominant_hand == 'RIGHT' ? 'prawa' : 'lewa'}}</span></td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Backhand</span></td>
        <td><span>{{user.backhand_type == 'SINGLE_HANDED' ? 'jednoręczny' : 'dwuręczny'}}</span></td>
      </tr>
      <tr>
        <td><em class="ion-ios-play-outline icon-fw mr"></em><span style="font-weight: 500">Płeć</span></td>
        <td>
          <span *ngIf="user.sex === 'MALE'">mężczyzna</span>
          <span *ngIf="user.sex === 'FEMALE'">kobieta</span>
          <span *ngIf="user.sex === 'OTHER'">inna</span>
        </td>
      </tr>
      <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Dodatkowe informacje
        </td>
        <td><span>{{user.additional_info}}</span></td>
      </tr>
      </tbody>
    </table>`
})
export class UserPanelTableComponent {
  @Input() user: Whoami = null;

}

import {LayoutComponent} from '../layout/layout.component';

import {LoginComponent} from './auth/login/login.component';
import {ResetComponent} from './auth/reset/reset.component';
import {SignupComponent} from './auth/signup/signup.component';
import {LockComponent} from './auth/lock/lock.component';
import {RecoverComponent} from './auth/recover/recover.component';

export const routes = [

  {
    path: '',
    component: LayoutComponent,
    children: [

      {path: '', redirectTo: 'court/map/start', pathMatch: 'full'},
      {path: 'court', loadChildren: () => import('./court/court.module').then(m => m.CourtModule)},
      {path: 'tournament', loadChildren: () => import('./tournament/tournament.module').then(m => m.TournamentModule)},
      {path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule)},
      {path: 'opponent', loadChildren: () => import('./opponent/opponent.module').then(m => m.OpponentModule)}

    ]
  },

  {path: 'login', component: LoginComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'lock', component: LockComponent},
  {path: 'recover', component: RecoverComponent},
  {path: 'reset/:token', component: ResetComponent},

  // Not found
  {path: '**', redirectTo: 'court/map/start'}

];

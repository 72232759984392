export const isHourBetweenOrSame = (toCompare: string, start: string, end: string): boolean => {
    const check = (new Date(toCompare)).getTime();
    const isBetween = check < (new Date(end)).getTime() && check >= (new Date(start)).getTime();

    if (isBetween) {
        return true;
    }

    return (new Date(toCompare)).getTime() === (new Date(start)).getTime(); // isSame
};

const test_isHourBetweenOrSame = () => {
    let isNotBetween = isHourBetweenOrSame("2022-03-16T08:00:00+01:00", "2022-03-17T08:00", "2022-03-17T09:00")
    let isBetween = isHourBetweenOrSame("2022-03-17T08:30:00+01:00", "2022-03-17T08:00", "2022-03-17T09:00")
    let isStartSame = isHourBetweenOrSame("2022-03-17T08:00:00+01:00", "2022-03-17T08:00", "2022-03-17T09:00")
    let isEndSame = isHourBetweenOrSame("2022-03-17T09:00:00+01:00", "2022-03-17T08:00", "2022-03-17T09:00")

    if (isNotBetween !== false) {
        console.log('isNotBetween should be false')
    }
    if (isBetween !== true) {
        console.log('isBetween should be true')
    }
    if (isStartSame !== true) {
        console.log('isStartSame should be true')
    }
    if (isEndSame !== false) {
        console.log('isEndSame should be false')
    }
};

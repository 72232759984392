import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PagetitleService} from '@core/pagetitle/pagetitle.service';
import {AppStateService} from '@core/state/app-state.service';
import {MapCourt} from '@model/map/MapCourt';

import {SettingsService} from '@shared/settings/settings.service';
import {Subscription} from 'rxjs/Subscription';
import {NotificationService} from '@services/notification/notification.service';
import {tap} from 'rxjs/operators';
import {FavoriteCourtsService} from '@services/shared/favorite-courts.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {TournamentService} from '@services/tournament/tournament.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss', './header.menu-links.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
    private input;
    public filterValue = null;
    @ViewChild('searchModal', {static: true}) public searchModal: ModalDirective;

    public courtList = [];
    public notificationCount = 0;

    public isMapRoute = false;

    private subscription$: Subscription;
    PFT = false;

    constructor(private element: ElementRef,
                public state: AppStateService,
                private route: ActivatedRoute,
                public settings: SettingsService,
                private notificationService: NotificationService,
                private courtDetailService: FavoriteCourtsService,
                public pt: PagetitleService,
                private tournamentService: TournamentService,
                private router: Router) {
    }

    ngOnInit() {
        // @ts-ignore
        this.isMapRoute = this.router.url.includes('map');
        this.checkIfPFTtournamentRoute();
        this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    // @ts-ignore
                    this.isMapRoute = event.url.includes('map');
                    this.checkIfPFTtournamentRoute();
                }
            }
        );

        this.subscription$ = this.notificationService.notificationChanged().subscribe(
            next => {
                this.notificationCount = next.filter(n => !n.is_read).length;
            }
        );
    }

    private checkIfPFTtournamentRoute(): void {
        if (this.router.url.includes('/tournament/pft-ranking/')
        || this.router.url.includes('/tournament/pft-timetable')) {
            this.PFT = true;
        }
        const isTournamentRoute = this.router.url.includes('/tournament/');
        if (!isTournamentRoute) {
            this.PFT = false;
        }

        const regex = /\/(\d+)\?/;
        const text = this.router.url;
        const match = text.match(regex);
        const tournamentId = match ? +match[1] : null;

        if (tournamentId) {
            this.tournamentService.getTournamentDetails(+tournamentId).subscribe(next => {
                this.PFT = next.series_id !== null;
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    toggleSidebar(state = null) {
        this.settings.app.sidebar.visible = state !== null ? state : !this.settings.app.sidebar.visible;
    }

    onModalShown() {
        if (this.state.courtList().length === 0) {
            this.courtDetailService.courtList().pipe(
                tap(courts => {
                    this.state.setCourtList(
                        courts.map(court => {
                            return {id: court.id, name: court.name};
                        })
                    );
                    this.courtList = this.state.courtList();
                })
            ).subscribe();
        }
        this.input = this.element.nativeElement.querySelector('.header-input-search');

        if (this.input) {
            this.input.focus();
        }
        this.courtList = this.state.courtList();
        if (this.filterValue) {
            this.courtList = this.state.courtList().filter(court => court.name.toLowerCase().includes(this.filterValue.toLowerCase()));
        }
    }

    inputChange(value) {
        if (!value) {
            return this.state.courtList();
        }

        this.courtList = this.state.courtList().filter(court => court.name.toLowerCase().includes(value.toLowerCase()));
    }

    go(court: MapCourt) {
        this.searchModal.hide();
        this.router.navigate([`/court/${court.id}`]);
    }

    gotoLogin() {
        this.router.navigate(['login']);
    }

    gotoUserPage() {
        this.router.navigate(['user/panel']);
    }

    gotoDashboard() {
        this.router.navigate(['user/dashboard']);
    }
}

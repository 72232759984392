import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-input',
  template: `
    <div class="mda-form-group float-label">
      <div class="mda-form-control">
        <input [(ngModel)]="model"
               class="form-control"
               (ngModelChange)="modelChanged($event)"
        />
        <div class="mda-form-control-line"></div>
        <label>{{label}}</label>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InputComponent {

  @Input() model: string;
  @Input() label: string;
  @Output() dataChanged = new EventEmitter<string>();

  modelChanged(data: string) {
    this.dataChanged.emit(data);
  }
}

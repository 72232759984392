import {PaymentTpay} from '@model/tournament/tournament-details';

export const generateTpayPaymentLink = (tpayDto: PaymentTpay, returnUrl = window.location.href) => {
  let link = 'https://secure.tpay.com/?';
  tpayDto.amount = +tpayDto.amount;
  const params = {
    'id': tpayDto.id,
    'amount': tpayDto.amount.toFixed(2).toString(),
    'description': tpayDto.description,
    'crc': tpayDto.crc,
    'email': tpayDto.client_email,
    'name': tpayDto.client_name,
    'result_url': tpayDto.result_url,
    'return_url': returnUrl,
    'md5sum': tpayDto.md5_code
  };
  link = link + encodeQueryData(params);

  return link;
};

const encodeQueryData = (data: Object) => {
  const ret = [];
  for (let d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
};

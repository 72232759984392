import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IconUrl} from '@model/map/IconUrl';
import {MapCourt} from '@model/map/MapCourt';
import {MapMarker, MarkerType} from '@model/map/MapMarker';
import {MapTournament} from '@model/map/MapTournament';
import {TournamentMapData} from '@model/tournament/map-tournament-data';
import {CourtDetailService} from '../court-detail.service';
import {API_HOST} from '../../consts';

import * as moment from 'moment';

export class GetCourtDataParams {
  constructor(
      public readonly datetime: string,
      // public readonly amountForFixedHour: number = 30
  ) {
  }
}

@Injectable()
export class MapDataService {
  constructor(private http: HttpClient,
              private service: CourtDetailService) {

  }

  public getCourtData(query: GetCourtDataParams = null): Observable<MapMarker[]> {
    let params = new HttpParams();
    if (query) {
      params = params.append('dateTime', query.datetime);
      // params = params.append('amountForFixedHour', query.amountForFixedHour);
    }
    return this.http.get<CourtData[]>(`${API_HOST}/court/all`, {params: params}).pipe(
      map((response: CourtData[]): MapMarker[] => {
        return response
        .map((value: CourtData) => {
          return new MapMarker(
            MarkerType.court,
            value.id,
            new MapCourt(
              value.id,
              value.name,
              value.marker,
              this.mapCost(+value.min_cost, +value.max_cost),
              value.reservationAvailable,
              value.multisport_available,
              value.medicover_available,
                value.amount_for_fixed_hour ? +value.amount_for_fixed_hour : null
            ),
            null,
            +value.latitude,
            +value.longitude,
            this.generateIcon(value),
            value.miniature,
            value.multisport_available
          );
        });
      }),
    );
  }

  public getTournamentData(): Observable<MapMarker[]> {
    return this.http.get<TournamentMapData[]>(`${API_HOST}/tournament/map`).pipe(
      map((response: TournamentMapData[]): MapMarker[] => {
        return response
        .map((value: TournamentMapData) => {
          return new MapMarker(
            MarkerType.tournament,
            value.id,
            null,
            new MapTournament(
              +value.id,
              value.name,
              value.status,
              +value.max_players,
              +value.reserve_players,
              +value.players,
              value.end_of_applications,
              value.court_name,
              value.type,
              value.start_date
            ),
            +value.latitude,
            +value.longitude,
            this.generateTournamentIcon(),
            this.generateTournamentThumb(value),
            false
          );
        })
          .sort((a: MapMarker, b: MapMarker) => moment(a.tournament.start_date) >= moment(b.tournament.start_date) ? -1 : 1);
      }),
      map(markers => {
        return this.mapTournamentMarkers(markers);
      })
    );
  }

  mapCost(min: number, max: number): string {
    if (min === 0 && min === 0) {
      return '-';
    }
    if (min === max) {
      return `${Number(min).toFixed(2).toString()}zł`;
    }
    return `${Number(min).toFixed(2).toString()}zł - ${Number(max).toFixed(2).toString()}zł`;
  }

  private generateTournamentThumb(item: TournamentMapData): string {
    if (item.type === 'CHALLENGE') {
      return '/assets/images/tournament/logo_challenge_v2.png';
    }
    const points = item.winner_points_award;
    if ([250, 500, 1000].includes(points)) {
      return `/assets/images/tournament/RTA${points}.png`;
    } else {
      return `/assets/images/tournament/RTA125.png`;
    }
  }

  private mapTournamentMarkers(markers: MapMarker[]): MapMarker[] {
    return markers.map(marker => {
      if (marker.tournament.status === 'CREATED' || this.courtHasAnyCratedTournament(markers, marker)) {
        marker.iconUrl = this.generateTournamentIcon(false);
      }
      if (marker.tournament.type === 'CHALLENGE' || this.courtHasAnyChallengeTournament(markers, marker)) {
        marker.iconUrl = this.generateTournamentIcon(false, true);
      }
      return marker;
    });
  }

  private courtHasAnyCratedTournament(markers: MapMarker[], marker: MapMarker): boolean {
    return markers
    .filter(m => m.lat === marker.lat && m.lng === marker.lng)
    .map(m => m.tournament.status)
    .filter(s => s === 'CREATED')
      .length > 0;
  }

  private courtHasAnyChallengeTournament(markers: MapMarker[], marker: MapMarker): boolean {
    return markers
    .filter(m => m.lat === marker.lat && m.lng === marker.lng)
    .map(m => m.tournament.type)
    .filter(t => t === 'CHALLENGE')
      .length > 0;
  }

  /**
   * zielony marker dla turniejów z zapisami
   * niebieski dla pozostałych
   * jeśli w jednej lokalizacji jest kilka turniejów to jeśli choć 1 turniej jest w trakcie zapisów to zielony marker
   */
  private generateTournamentIcon(blue = true, challenge = false): IconUrl {
    // if (challenge) {
    //   return {
    //     url: '/assets/images/svg/marker_challenge.png',
    //     scaledSize: {
    //       height: 47,
    //       width: 36
    //     }
    //   };
    // }
    return {
      url: blue ? '/assets/images/svg/marker_niebieski.png' : '/assets/images/svg/marker_aktywne.png',
      scaledSize: {
        height: 47,
        width: 36
      }
    };
  }

  private generateIcon(court: CourtData): IconUrl {
    return {
      url: this.generateIconUrl(court),
      scaledSize: {
        height: 47,
        width: 36
      }
    };
  }

  private generateIconUrl(court: CourtData): string {
    const baseUrl = '/assets/images/svg/';
    switch (court.marker) {
      case 'ACTIVE':
        return baseUrl + 'marker_aktywne.png';
      case 'FLOODED':
        return baseUrl + 'marker_zalane.png';
      case 'NO_ACTIVE_STATIONS':
        return baseUrl + 'marker_nieaktywny.png';
      case 'RESERVATIONS_DISABLED':
        return baseUrl + 'marker_bez_rezerwacji.png';
      default:
        return baseUrl + 'marker_bez_rezerwacji.png';
    }
  }
}

export interface CourtData {
  id: number;
  lat: string;
  latitude: string;
  lng: string;
  longitude: string;
  marker: string;
  name: string;
  reservationAvailable: boolean;
  courtStatus: string; // FLOODED, OPEN, CLOSED
  img: string;
  min_cost: string;
  max_cost: string;
  miniature: string;
  multisport_available: boolean;
  medicover_available: boolean;
  amount_for_fixed_hour: number;
}

import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentMonthNamePipe'
})
export class MomentMonthNamePipe implements PipeTransform {
    public static getName(date: string | Date, capitalize = false) {
        if (!date) {
            return '';
        }

        switch (moment(date).month()) {
            case 0:
                return 'Stycznia';
            case 1:
                return 'lutego';
            case 2:
                return 'marca';
            case 3:
                return 'kwietnia';
            case 4:
                return 'maja';
            case 5:
                return 'czerwca';
            case 6:
                return 'lipca';
            case 7:
                return 'sierpnia';
            case 8:
                return 'września';
            case 9:
                return 'października';
            case 10:
                return 'listopada';
            case 11:
                return 'grudnia';
        }
    }

    transform(date: string | Date, capitalize = false) {
        return MomentMonthNamePipe.getName(date, capitalize);
    }
}

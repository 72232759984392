<div *ngIf="userDto">
    <div class="container-overlap bg-green-500">
        <div class="media m0 pv">
            <div class="media-left">
                <app-avatar (onEdit)="editMode = 'avatar'" [withEditButton]="true" [src]="user.picture"
                            [size]="100"></app-avatar>
            </div>
            <div class="media-body media-middle">
                <h4 class="media-heading">{{userDto.real_name}}</h4><span class="text-muted">{{userDto.email}}</span>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <!-- Left column-->
            <div class="col-md-7 col-lg-8">
                <form name="user.profileForm" class="card">
                    <div class="text-right" style="padding: 10px 10px 0 0">
                        <button type="submit"
                                *ngIf="editMode === 'data' || editMode === 'password'|| editMode === 'avatar'"
                                (click)="editMode = null"
                                class="btn-raised btn btn-info btn-circle btn-lg"><em
                                class="ion-android-arrow-back"></em></button>
                        <button type="button" *ngIf="editMode === null" (click)="editMode = true"
                                class="btn-raised btn btn-primary btn-oval">EDYTUJ
                        </button>
                        <button *ngIf="editMode === 'data'" (click)="editProfile()"
                                class="btn-raised btn btn-success btn-circle btn-lg"><em
                                class="ion-checkmark-round"></em></button>
                        <button *ngIf="editMode === 'password'" (click)="changePassword()"
                                class="btn-raised btn btn-success btn-circle btn-lg"><em
                                class="ion-checkmark-round"></em></button>
                        <div *ngIf="editMode === true" style="padding-top: 8px;">
                            <button (click)="editMode = null" class="btn btn-link">Anuluj</button>
                            <button (click)="editMode = 'data'; mapEditMode = true;" class="btn btn-link">Edytuj dane
                            </button>
                            <button (click)="editMode = 'avatar'" class="btn btn-link">Edytuj avatar</button>
                            <button (click)="editMode = 'password'" class="btn btn-link">Zmień hasło</button>
                        </div>
                    </div>

                    <h5 class="card-heading pb0">Dane gracza</h5>
                    <div class="card-body">
                        <app-user-panel-table
                                *ngIf="editMode === null || editMode === true"
                                [user]="user"
                        ></app-user-panel-table>
                        <app-user-panel-edit
                                *ngIf="editMode === 'data'"
                                [user]="user"
                                [userDto]="userDto"
                        ></app-user-panel-edit>

                        <app-user-panel-password
                                *ngIf="editMode === 'password'"
                                [passwordDto]="passwordDto"
                        ></app-user-panel-password>

                        <app-user-panel-edit-avatar
                                *ngIf="editMode === 'avatar'"
                                [user]="user"
                                (avatarUpdated)="loadUserData()">
                        </app-user-panel-edit-avatar>
                    </div>

                    <table *ngIf="user && (mapEditMode || user.location)" class="table table-striped animated fadeIn">
                        <thead>
                        <tr>
                            <th class="map-column-width"></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr">
                            </em>Lokalizacja
                                <div class="text-muted small">Ustawienie lokalizacji pozwoli Ci wyświetlać przeciwników,
                                    którzy są najbliżej Ciebie. Nikt oprócz Ciebie nie będzie mógł jej zobaczyć.
                                </div>
                            </td>
                            <td>
                                <div *ngIf="apiLoaded | async" class="card map-wrapper mb-0">
                                    <div *ngIf="mapEditMode || user.location" class="animated fadeIn">
                                        <google-map
                                                class="map-container"
                                                height="300px"
                                                width="100%"
                                                [center]="initialCenter"
                                                [zoom]="zoom"
                                                [options]="mapOptions"
                                                (mapClick)="mapClicked($event)"
                                        >
                                            <map-marker
                                                #markerElem="mapMarker"
                                                *ngFor="let m of markers; index as i"
                                                (click)="clickedMarker(m.label, i)"
                                                [position]="m.position"
                                                [draggable]="mapEditMode"
                                                [animation]="m.animation"
                                            >
                                            </map-marker>
                                        </google-map>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </form>
            </div>
            <!-- Right column-->
            <div class="col-md-5 col-lg-4">
                <app-user-panel-voucher
                        *ngIf="isMobile"
                        [user]="user"
                        (loadUserData)="loadUserData()"
                ></app-user-panel-voucher>

                <div class="card">
                    <h5 class="card-heading" style="padding-bottom: 0;">Napisz do nas</h5>
                    <h6 class="text-muted" style="padding: 20px;">Jeśli chcesz zgłosić błąd w aplikacji lub masz pytania
                        dotyczące działania aplikacji tenis4U skorzystaj z poinższego formularza.
                        Odpowiemy z prędkością serwisu Johna Isnera :)</h6>

                    <div class="mda-form-control" style="margin: 0 20px 20px;">
            <textarea [(ngModel)]="message" class="form-control" rows="4" style="resize: both; overflow: auto;"
                      aria-multiline="true" tabindex="0" aria-invalid="false"></textarea>
                        <div class="mda-form-control-line"></div>
                        <label></label>
                        <span *ngIf="message.length == 0"
                              class="mda-form-msg right animated fadeIn">Napisz wiadomość</span></div>

                    <div class="text-right" style="margin: 20px;">

                        <button style="border-radius: 20px;" [disabled]="message.length == 0" (click)="sendMessage()"
                                class="btn btn-oval btn-primary bg-blue-500">WYŚLIJ
                        </button>
                    </div>
                </div>

                <app-user-panel-voucher
                        *ngIf="!isMobile"
                        [user]="user"
                        (loadUserData)="loadUserData()"
                ></app-user-panel-voucher>
            </div>


        </div>


    </div>
</div>


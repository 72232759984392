import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReservationIndividual} from '@model/reservation/UserReservation';
import * as moment from 'moment';
import swalConfirm from '@shared/swal/confirm';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {swalError} from '@shared/swal/swalError';
import {MyReservationsService} from '@services/reservation/my-reservations.service';

/** niebieski kafelek z informacjami o najbliższej/trwającej rezerwacji */
@Component({
    selector: 'app-dashboard-reservations-single-active',
    template: `
        <div class="reservations-single bg-info" *ngIf="reservation">
            <div class="animated fadeIn pt-30" style="display: flex;">
                <div class="flex-column-center w-50">

                    <div class="text-muted"><i style="width: 15px; text-align: center;"
                                               class="fa fa-clock-o"></i> {{ reservation.starts_at | momentDate:'HH:mm' }}
                        - {{ reservation.ends_at | momentDate:'HH:mm' }}</div>
                    <div class="text-muted"><i style="width: 15px; text-align: center;"
                                               class="fa fa-calendar"></i> {{ reservation.starts_at | momentDayOfWeekName }} {{ reservation.starts_at | momentDate:'DD.MM' }}
                    </div>
                    <div class="text-muted"><i style="width: 15px; text-align: center;" class="fa fa-usd"></i> <span
                            class="pl-5 color-blue-500">{{ reservation.price.toFixed(2) }} zł</span></div>

                </div>
                <div class="flex-column-center w-50 pl-10" style="border-left: 1px solid rgb(173 173 173 / 50%);">

                    <p class="mb-0" style="font-size: 14px; font-weight: 600;">{{ reservation.court_name }}</p>
                    <div class="text-muted">{{ reservation.station_name }}
                    </div>
                </div>

                <div style="display: flex; justify-content: space-around; margin: 10px 0 20px;">
                    <!--cancel button-->
                    <div (click)="cancel(reservation)" class="text-center">
                        <button [disabled]="paymentAvailable(reservation)" style="border-radius: 50%"
                                class="btn btn-sm btn-danger"><i class="fa fa-remove"></i></button>
                    </div>
                </div>

                <div *ngIf="reservation.automation_actions && reservation.automation_actions.length"
                     style="display: flex; justify-content: space-around; margin: 10px 0;" class="actions">
                    <app-reservation-automation-actions-based-on-buttons
                            [automationAction]="reservation.automation_actions_with_id"
                            (actionExecuted)="onReservationUpdated.emit()"
                    ></app-reservation-automation-actions-based-on-buttons>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./../dashboard.component.scss']
})
export class DashboardReservationsSingleActiveComponent {
    @Output() onReservationUpdated = new EventEmitter();

    constructor(private service: MyReservationsService) {
    }

    private _reservation: ReservationIndividual;

    get reservation() {
        return this._reservation;
    }

    @Input('reservation')
    set reservation(value: ReservationIndividual) {
        this._reservation = value;
        this._reservation.automation_actions_with_id = {
            reservationId: value.id,
            actions: value.automation_actions
        };
    }

    dateTimeFormatStartEnd(reservation: ReservationIndividual): string {
        const start = moment(reservation.starts_at).locale('pl');
        const end = moment(reservation.ends_at).locale('pl');

        return start.format('YYYY-MM-DD, HH:mm') + '-' + end.format('HH:mm');
    }

    cancel(reservation: ReservationIndividual, repeatable = false) {
        const that = this;
        const additionalText = repeatable ? ' cykliczną' : '';
        const paymentText = reservation.payment_status === 'ONLINE_PAID' ? `<br>Poniesiona opłata zostanie zwrócona automatycznie na konto do 4 dni roboczych z potrąceniem kosztów serwisowych wynoszących 2%</p>` : '';

        swalConfirm(
            'Anulowanie rezerwacji',
            () => {
                that.cancelReservation(reservation, repeatable);
            },
            `<p>Czy na pewno chcesz odwołać rezerwację${additionalText}?${paymentText}</p>`
        );
    }

    paymentAvailable(reservation: ReservationIndividual): boolean {
        return !(!(reservation.tpay_payment || reservation.payment) || reservation.payment_status === 'CASH_PAID' || reservation.payment_status === 'ONLINE_PAID');
    }

    private cancelReservation(reservation: ReservationIndividual, repeatable) {
        this.service.cancelReservation(reservation.id)
            .subscribe(() => {

                // const deletedIndex = this.reservations.indexOf(reservation);
                // this.reservations.splice(deletedIndex, 1);
                swalSuccess({title: 'Rezerwacja odwołana'});

            }, err => {
                let message = err.error.status;
                switch (err.error.status) {
                    case 'WRONG_CODE':
                        message = 'Wystąpił błąd';
                        break;
                    case 'ALREADY_CANCELED':
                        message = 'Ta rezerwacja jest już odwołana';
                        break;
                    case 'CANNOT_CANCEL':
                        message = 'Rezerwację można odwołać do ' + err.error.max_hours + ' godzin przed rozpoczęciem gry';
                        break;
                }
                swalError({
                    title: 'Niepowodzenie',
                    text: message
                });
            });
    }
}

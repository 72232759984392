import {Component, Input, OnInit} from '@angular/core';
import {LastMatch} from '@core/authentication/whoami';

@Component({
  selector: 'app-dashboard-tournament-score',
  template: `
    <div *ngIf="match" class="row match" style="margin: 0;">
        <table class="table text-center">
          <tr>
            <td style="width: 50px;">
              <div class="thumb32 pull-left" style="padding-left: 5px;">
                  <app-avatar [userId]="match.players.left1.id" src="{{match.players.left1.avatar}}" size="32"></app-avatar>
              </div>
            </td>
            <td style="text-align: left; margin-left: 5px; padding-top: 10px;">
              <p>{{match.players.left1.real_name}}
                <i *ngIf="match.result === 'WON_LEFT'" class="fa fa-trophy"></i></p>
            </td>
            <td style="width: 40px;"><span class="match-result">{{ l1 }}</span></td>
            <td style="width: 40px;"><span class="match-result">{{ l2 }}</span></td>
            <td style="width: 40px;"><span class="match-result">{{ l3 }}</span></td>
          </tr>
        </table>
    </div>
    <hr *ngIf="match" style="border-color: #eee; margin: 10px -20px;"/>
    <div *ngIf="match" class="row match" style="margin: 0;">
      <table class="table text-center">
        <tr>
          <td style="width: 50px;">
            <div class="thumb32 pull-left" style="padding-left: 5px;">
                <app-avatar [userId]="match.players.right1.id" src="{{match.players.right1.avatar}}" size="32"></app-avatar>
            </div>
          </td>
          <td style="text-align: left; margin-left: 5px; padding-top: 10px;">
            <p>{{match.players.right1.real_name}}
              <i *ngIf="match.result === 'WON_RIGHT'" class="fa fa-trophy"></i></p>
          </td>
          <td style="width: 40px;"><span class="match-result">{{ r1 }}</span></td>
          <td style="width: 40px;"><span class="match-result">{{ r2 }}</span></td>
          <td style="width: 40px;"><span class="match-result">{{ r3 }}</span></td>
        </tr>
      </table>
    </div>
  `,
  styleUrls: ['../dashboard.component.scss']
})
export class DashboardTournamentScoreComponent implements OnInit {
  private _match: LastMatch;
  public l1: string | number;
  public l2: string | number;
  public l3: string | number;
  public r1: string | number;
  public r2: string | number;
  public r3: string | number;

  get match() {
    return this._match;
  }

  @Input('match')
  set match(value: LastMatch) {
    this._match = value;
    if (value) {
      this.l1 = this.getResult(1);
      this.l2 = this.getResult(2);
      this.l3 = this.getResult(3);
      this.r1 = this.getResult(1, true);
      this.r2 = this.getResult(2, true);
      this.r3 = this.getResult(3, true);
    }
  }
  constructor() { }

  ngOnInit() {
  }

  getResult(set: number, inverse = false): string | number {
    let score1: number | string;
    let score2: number | string;

    switch (set) {
      case 1:
        score1 = this.match.left_player_first_set_result;
        score2 = this.match.right_player_first_set_result;
        break;
      case 2:
        score1 = this.match.left_player_second_set_result;
        score2 = this.match.right_player_second_set_result;
        break;
      case 3:
        score1 = this.match.left_player_third_set_result;
        score2 = this.match.right_player_third_set_result;
        break;
    }

    if (score1 === 0 && score2 === 0) {
      return '';
    }

    if (score1 === -1) {
      score1 = 'W';
    }
    if (score2 === -1) {
      score2 = 'W';
    }

    return inverse
      ? score2
      : score1;
  }

}

import {Pipe, PipeTransform} from '@angular/core';
import getRoundName from '@shared/utils/getRoundName';

@Pipe({
  name: 'fullRoundNamePipe'
})
export class FullRoundNamePipe implements PipeTransform {
  transform(round: number) {
    return getRoundName(round);
  }
}

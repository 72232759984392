import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceHour'
})
export class PriceHourPipe implements PipeTransform {

  transform(value: any, args?: any): any {
      const parts = value.split(':');
      return parts[0][0] === '0' ? parts[0][1] : parts[0];
  }

}

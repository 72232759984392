import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'challengeBonusPipe'
})
export class ChallengeBonusPipe implements PipeTransform {
  transform(multiplier: number) {
    return Number(multiplier * 100 - 100).toFixed(0).toString();
  }
}

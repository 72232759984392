import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ReservationDetails} from '@model/reservation/reservation-details';
import {PriceResult} from '@model/reservation/price-result';
import swalConfirm from '@shared/swal/confirm';
import {AppStateService} from '@core/state/app-state.service';
import * as moment from 'moment';
import {ReservationState} from '@model/reservation/reservation-state';
import {generatePaymentLink} from '@shared/utils/generatePaymentLink';
import Swal from 'sweetalert2';
import apiMessageTranslate from '@shared/utils/apiMessageTranslate';
import {ToastrService} from 'ngx-toastr';
import {MakeReservationRepository} from '@services/reservation/make-reservation.repository';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {UserMatch} from '@model/tournament/user-match';
import {TournamentReservation} from '@model/tournament/tournament-reservation';
import {swalError} from '@shared/swal/swalError';
import {TournamentService} from '@services/tournament/tournament.service';
import {generateId, saveRedirectStateForOnlinePayment} from '../../utils/reservationLocalStorageManager';
import {getWhoami} from '@shared/utils/whoami';
import {PaymentPrzelewy24} from '@model/reservation/paymentPrzelewy24';
import {NgxSmartModalService} from 'ngx-smart-modal';

declare var $: any;

@Component({
    selector: 'app-reservation-summary',
    templateUrl: './reservation-summary.component.html',
    styleUrls: ['./reservation-summary.component.scss']
})
export class ReservationSummaryComponent implements OnChanges {
    public MODAL_ID = 'agreementModal';

    selectedPaymentMethod: string;
    public reservationPrice = '';

    @Input() multisportDisabledWithTooShortReservationMessage = false;
    @Input() newReservation = false;
    @Input() match: UserMatch = null;
    @Input() hidePaymentInformation = false;
    @Input() lightingEnabled = false;
    @Input() lightingPrice = '';
    @Input() reservation: ReservationDetails = null;
    @Input() paymentMethods: string;
    @Input() multisport_available: boolean;
    @Input() medicover_available: boolean;
    @Input() reservationTariffs: PriceResult[] = [];
    @Input() agreement: string = null;

    @Output() onMultisportChange = new EventEmitter<number>();
    @Output() onMedicoverChange = new EventEmitter<number>();
    @Output() onOpenCustomerDialog = new EventEmitter();
    @Output() onLightingChanged = new EventEmitter<boolean>();
    @Output() reservationCreated = new EventEmitter();
    @Output() onWidgetClose = new EventEmitter();
    @Output() matchReservationCreated = new EventEmitter<boolean>();
    withMessage = true;
    message = '';
    withVisaCardDiscount = false;
    userHasVoucher = false;
    agreementModel = false;
    showAgreementModal = false;

    constructor(private state: AppStateService,
                private toastr: ToastrService,
                private makeReservationRepository: MakeReservationRepository,
                private authService: AuthenticationService,
                private modalService: NgxSmartModalService,
                private tournamentsService: TournamentService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.userHasVoucher = getWhoami() && getWhoami().active_vouchers.length > 0;
        if (['CASH_AND_ONLINE', 'ONLINE'].includes(this.paymentMethods)) {
            this.selectedPaymentMethod = 'ONLINE';
        }
        if (this.reservation && this.selectedPaymentMethod === 'ONLINE') {
            this.withVisaCardDiscount = this.userHasVoucher;
        }
        console.log('rules', this.agreement);
    }

    multisportChange() {
        this.onMultisportChange.emit(+$('input[name="multisport-radio"]:checked').val());
    }

    medicoverChange() {
        this.onMedicoverChange.emit(+$('input[name="medicover-radio"]:checked').val());
    }

    lightingChanged() {
        this.onLightingChanged.emit($('input[name="i-radio-lighting"]:checked').val() === 'true' || false);
    }

    public openCustomerDialog2() {
        if (this.state.userExists()) {
            if (+this.reservation.courtId === 68 && (this.reservation.begin.hour === 7 || this.reservation.begin.hour === 8)) {

                swalConfirm(
                    'Uwaga',
                    () => {
                        this.prepareReservationWithMultisportAlert();
                    },
                    `Informujemy, że rezerwacja kortu na godzinę 7 oraz 8 dostępna tylko
i wyłącznie dla graczy posiadających opłacony karnet (w godzinach 7-9 kasa nieczynna).`,
                    'Kontynuuj',
                    'Anuluj'
                );
                return;
            }

            if (this.reservationOnFlooded()) {
                swalConfirm(
                    'Uwaga',
                    () => {
                        this.prepareReservationWithMultisportAlert();
                    },
                    `Korty są obecnie zalane, czy na pewno chcesz zarezerwować kort?`,
                    'Potwierdzam',
                    'Anuluj'
                );
                return;
            } else {
                this.prepareReservationWithMultisportAlert();
            }

        } else {
            this.authService.toastrMsg('Dostęp tylko dla zalogowanych użytkowników', '', true);
        }
    }

    prepareReservationWithMultisportAlert() {
        const that = this;
        if (this.reservationWithMultisport()) {
            swalConfirm(
                'Uwaga',
                () => {
                    this.prepareReservation();
                },
                `Kartę Multisport należy pokazać przed rezerwacją. Jednej karty można użyć tylko raz dziennie na tenisa, a kolejne użycia będą odrzucane.`,
                'Kontynuuj',
                'Anuluj'
            );
            return;
        } else {
            this.prepareReservation();
        }
    }

    private reservationOnFlooded(): boolean {
        return this.reservation.stationFlooded;
    }

    private reservationWithMultisport(): boolean {
        return this.reservation.multisport > 0;
    }

    prepareReservation() {
        this.reservation.message = this.message;
        this.reservationPrice = $('input[name="i-radio"]:checked').val();
        const selectedTariffId = $('input[name="i-radio"]:checked').attr('id');
        this.reservation.tariff_id = this.reservationTariffs[+selectedTariffId]
            ? this.reservationTariffs[+selectedTariffId].id
            : null;
        this.reservation.payment_method = this.getPaymentMethod();
        const that = this;
        const reservationDay = moment(this.reservation.day);
        const dateString = reservationDay.format('YYYY-MM-DD');
        const beginMin = this.reservation.begin.minute === 0 ? '00' : String(this.reservation.begin.minute);
        const endMin = this.reservation.end.minute === 0 ? '00' : String(this.reservation.end.minute);

        const message = that.message ? `<p>Wiadomość: ${that.message} </p>` : '';

        swalConfirm(
            'Potwierdź rezerwację',
            () => {
                that.match
                    ? that.makeTournamentReservation()
                    : that.makeReservation();
            },
            `
                      <p>Data: ` + dateString + `</p>
                      <p>Kort: ` + that.reservation.stationName + `</p>
                      <p>Godzina rozpoczęcia: ` + that.reservation.begin.hour + `:` + beginMin + `</p>
                      <p>Godzina zakończenia: ` + that.reservation.end.hour + `:` + endMin + `</p>
                      ${message}
                      ${that.hidePaymentInformation ? '' : `
                        <p>Cena: ` + that.reservationPrice + ` zł</p>
                        <p>Metoda płatności: ${that.reservation.payment_method === 'CASH' ? 'W klubie' : 'Online'}</p>
                      `}
                  `,
            'Potwierdzam',
            'Anuluj'
        );
    }

    public makeReservation() {
        const paymentId = generateId();
        this.reservation.paymentId = paymentId;
        this.makeReservationRepository.makeReservation(this.reservation).subscribe(
            (success: ReservationState) => {
                this.reservationCreated.emit();

                const paymentUrl = generatePaymentLink(success.tpay_payment || success.payment || success.przelewy24);
                const paymentBtn = `<a href="${paymentUrl}"><button class="btn btn-success close-btn">Zapłać</button></a>`;
                const cancelBtn = `<button class="btn btn-primary close-btn">Ok</button>`;
                const data = {title: '', html: ''};

                if (success.tpay_payment || success.payment || success.przelewy24) {
                    data.title = 'Wstępna rezerwacja';
                    data.html = `Płatność online jest wymagana. Brak płatności powoduje usunęcie rezerwacji w ciągu 20 minut.<br><br>${paymentBtn}`;
                    saveRedirectStateForOnlinePayment(success.code, paymentId);
                } else {
                    data.title = 'Rezerwacja przyjęta';
                    data.html = `Kod rezerwacji: <b>${success.code}</b><br><br>${cancelBtn}`;
                }
                Swal.fire({
                    title: data.title,
                    html: data.html,
                    showConfirmButton: false
                }).then((result) => {
                    if (result.value) {
                        Swal.close();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.close();
                    }
                });

                $('.close-btn').on('click', function () {
                    Swal.close();
                });
            },
            (error: ReservationState) => {
                const text = error.code;
                this.toastr.error(apiMessageTranslate(text), 'Błąd rezerwacji');
            }
        );
    }

    getPaymentMethod() {
        switch (this.paymentMethods) {
            case 'CASH':
                return 'CASH';
            case 'ONLINE':
                return 'ONLINE';
            case 'CASH_AND_ONLINE':
                return this.selectedPaymentMethod;
        }
    }

    public makeTournamentReservation() {
        const paymentId = generateId();
        const reservation = new TournamentReservation(this.reservation, this.match.match_id, paymentId);
        const that = this;

        this.tournamentsService.createReservationForUser(reservation).subscribe(
            (success: ReservationState) => {
                success.przelewy24 = this.makeReservationRepository.resolvePaymentsPrzelewy24(success);

                const customRedirectUrl = `${window.location.origin}/#${localStorage.getItem('redirectTournament')}`;
                const paymentUrl = generatePaymentLink(success.tpay_payment || success.payment || success.przelewy24, customRedirectUrl);

                const paymentBtn = `<a href="${paymentUrl}"><button class="btn btn-success close-btn">Zapłać</button></a>`;
                const cancelBtn = `<button class="btn btn-primary close-btn">Ok</button>`;
                const data = {
                    title: '',
                    html: ''
                };

                if (paymentUrl) {
                    data.title = 'Wstępna rezerwacja';
                    data.html = `Płatność online jest wymagana. Brak płatności powoduje usunęcie rezerwacji w ciągu 20 minut.<br><br>${paymentBtn}`;
                    saveRedirectStateForOnlinePayment(success.code, paymentId);
                } else {
                    data.title = 'Rezerwacja przyjęta';
                    data.html = `Kod rezerwacji: <b>${success.code}</b><br><br>${cancelBtn}`;
                }
                Swal.fire({
                    title: data.title,
                    html: data.html,
                    showConfirmButton: false
                }).then((result) => {
                    if (result.value) {
                        Swal.close();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.close();
                    }
                });

                $('.close-btn').on('click', function () {
                    Swal.close();
                });
                that.matchReservationCreated.emit(true);
            },
            err => {
                swalError({
                    title: 'Błąd rezerwacji!',
                    text: apiMessageTranslate(err.error.message)
                });
            }
        );
    }

    onPaymentMethodChange() {
        this.withVisaCardDiscount = this.selectedPaymentMethod === 'ONLINE' && this.userHasVoucher;
    }

    openAgreementModal() {
        this.showAgreementModal = true;
        this.modalService.getModal(this.MODAL_ID).open();
    }

    hideModal() {
        this.modalService.getModal(this.MODAL_ID).close();
    }
}

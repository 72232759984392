import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-modal-footer',
    template: `
        <div class="modal-footer">
            <button class="btn btn-fill btn-white"
                    (click)="onModalClose.emit()"
            >{{ closeLabel }}
            </button>
            <button *ngIf="withSuccessButton" class="btn btn-fill btn-success"
                    (click)="onSuccess.emit()"
            >{{ successLabel }}
            </button>
        </div>
    `,
    styleUrls: []
})
export class ModalFooterComponent {

    @Input() withSuccessButton = true;
    @Input() successLabel: string;
    @Input() closeLabel = 'Zamknij';

    @Output() onSuccess = new EventEmitter();
    @Output() onModalClose = new EventEmitter();
}

import {SupportingCourt} from '@model/tournament/supporting-court';
import {Sponsor} from '@model/tournament/challenge/sponsor';

export class TournamentDetails {
    already_joined: boolean;
    balls_available: boolean;
    awards: string;
    court_id: number;
    court_name: string;
    court_miniature: string;
    end_of_applications: string;
    ends_date: string;
    fee: string;
    id: number;
    max_players: number;
    reserve_players: number;
    players: number;
    name: string;
    rules: string;
    start_date: string;
    status: string; // ['CREATED', 'APPLICATIONS_CLOSED', 'GENERATED', 'STARTED', 'FINISHED', 'DELETED']
    winner_points_award: number;
    league_rounds: number;
    groups: number;
    type: string; // CUP, LEAGUE, CONSOLATION_CUP, CHALLENGE
    series_id: number | null; // null, 1-10
    schedule_enabled: boolean;
    supporting_courts: SupportingCourt[];
    entry_fee: number;
    application: null | ApplicationStatus;
    sponsors: Sponsor[];
    challenge_free_rejections: number;
    challenge_range: number;

    pft() {
        return this.series_id !== null;
    }
}

export interface ApplicationStatus {
    payment: PaymentStatus;
    reserve_list: boolean;
    vacation_ends_at: string;

    chat_id: string;
    has_new_chat_messages: boolean;
    last_red_chat_message: string;

    can_buy_tennis_balls: boolean;
    tennis_balls_bought: number;
}

export class PaymentStatus {
    constructor(
        public status: string, // [PAID, UNPAID]
        public tpay_payment: PaymentTpay,
        public link?: string,
    ) {
    }
}

export class PaymentTpay {
    constructor(
        public amount: number | string,
        public client_email: string,
        public client_name: string,
        public crc: string,
        public description: string,
        public id: string,
        public md5_code: string,
        public result_url: string,
    ) {
    }
}

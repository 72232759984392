<div class="header-container">
    <nav>
        <ul>
            <li *ngIf="!PFT" [ngClass]="{'active': settings.app.sidebar.visible}" class="visible-xs visible-sm hidden-md">
                <a (click)="toggleSidebar()" [ngClass]="settings.app.header.menulink" class="menu-link">
                    <span><em></em></span>
                </a>
            </li>
            <li *ngIf="PFT" [ngClass]="{'active': settings.app.sidebar.visible}">
                <a (click)="toggleSidebar()" [ngClass]="settings.app.header.menulink" class="menu-link">
                    <span><em></em></span>
                </a>
            </li>
        </ul>
        <h2 [innerHTML]="pt.getTitle()" [class.isNotMapRoute]="!isMapRoute" class="header-title"></h2>
        <ul class="pull-right">
            <li>
                <span class="subtitle">
                    Pobierz aplikację mobilną
                </span>
            </li>
            <li>
                <a target="_blank" href="https://itunes.apple.com/us/app/baza-kort%C3%B3w-tenisowych/id1222721113?l=pl&ls=1&mt=8" class="ripple">
                    <em class="ion-social-apple"></em>
                </a>
            </li>
            <li>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=pl.tenis4u.android.free" class="ripple">
                    <em class="ion-social-android"></em>
                </a>
            </li>
            <li *ngIf="state.userExists()" dropdown class="dropdown" multiple>
                <a dropdownToggle class="dropdown-toggle has-badge ripple">
                    <em class="ion-android-notifications"></em>
                    <span *ngIf="notificationCount > 0" class="badge bg-danger">{{notificationCount}}</span>
                </a>
                <ul *dropdownMenu class="dropdown-menu notification-dropdown dropdown-menu-right md-dropdown-menu" style="max-width: 360px;">
                    <app-user-notifications></app-user-notifications>
                </ul>
            </li>
            <li *ngIf="state.userExists()" dropdown class="dropdown">
                <a dropdownToggle class="dropdown-toggle has-badge ripple">
                    <em class="ion-person"></em>
                </a>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right md-dropdown-menu">
                    <li class="ripple ripple-block">
                        <a (click)="gotoUserPage()"><em class="ion-home icon-fw"></em>Profil</a>
                    </li>
                    <li class="ripple ripple-block">
                        <a (click)="gotoLogin()"><em class="ion-log-out icon-fw"></em>Wyloguj</a>
                    </li>
                </ul>
            </li>
            <li>
                <a (click)="searchModal.show()" class="ripple">
                    <em class="ion-ios-search-strong"></em>
                </a>
            </li>
        </ul>
    </nav>
</div>
<!--<header-search #headerSearch></header-search>-->
<div bsModal #searchModal="bs-modal" (onShown)="onModalShown()" class="modal fade modal-top">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body" >
                <div class="pull-left">
                    <button type="button" (click)="searchModal.hide()" class="btn btn-flat"><em class="ion-arrow-left-c icon-24"></em></button>
                </div>
                <div class="pull-right">
                    <button type="button" class="btn btn-flat"><em class="ion-search icon-24"></em></button>
                </div>
                <form action="" class="oh">
                    <div class="mda-form-control pt0">
                        <input [(ngModel)]="filterValue" (ngModelChange)="inputChange($event)" [ngModelOptions]="{standalone: true}" type="text" placeholder="Wyszukaj kort..." class="form-control header-input-search" />
                        <div class="mda-form-control-line"></div>
                    </div>
                </form>
                <div class="mda-list mda-list-bordered">
                    <div (click)="go(court)" class="mda-list-item animated fadeIn"
                         *ngFor="let court of courtList">
                        <div class="mda-list-item-icon">
                            <ngx-avatar name={{court.name}} round="true" size="42"></ngx-avatar>
                        </div>
                        <div class="mda-list-item-text mda-2-line">
                            <h3>{{court.name}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Notification} from '@model/notification/notification';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable()
export class NotificationService {

  private notifications$ = new BehaviorSubject([]);

  notify(notifications: Notification[]) {
    this.notifications$.next(notifications);
  }

  notificationChanged(): Observable<Notification[]> {
    return this.notifications$.asObservable();
  }
}

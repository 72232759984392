import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MapMarker} from '@model/map/MapMarker';

@Component({
    selector: 'app-card-tournament-court',
    templateUrl: './card-tournament-court.component.html',
    styleUrls: ['./card-tournament-court.component.scss']
})
export class CardTournamentCourtComponent {
    @Input() marker: MapMarker;
    @Output() onRouteChange = new EventEmitter<MapMarker>();

    routeChange(marker: MapMarker) {
        this.onRouteChange.emit(marker);
    }
}

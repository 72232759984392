
<div>
    <tabset [justified]="true">
        <tab [heading]="'AKTUALNE'" (selectTab)="changeTab('active')" [active]="tab === 'active'">
            <div class="panel panel-repeatable">
                <div class="panel-body">
                    <h6 *ngIf="!active.length">Lista jest pusta</h6>
                    <div class="mda-list mda-list-bordered"  *ngIf="active.length > 0">
                        <div class="mda-list-item animated fadeIn" (click)="goto(t.id)"
                             *ngFor="let t of active">
                            <div class="mda-list-item-icon">
                                <img loading="lazy" class="rta-img" src="{{t.winner_points_award | rtaImgPipe}}">
                            </div>
                            <div class="mda-list-item-text mda-2-line" style="padding: 13px 0;">
                                <small *ngIf="t.reserve_list" class="color-blue-500">Lista rezerwowa</small>
                                <p class="list-group-item-text">{{t.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
        <tab [heading]="'HISTORIA'" (selectTab)="changeTab('history')" [active]="tab === 'history'">
            <div class="panel panel-repeatable">
                <div class="panel-body">
                    <h6 *ngIf="!finished.length">Lista jest pusta</h6>
                    <div class="mda-list mda-list-bordered"  *ngIf="finished.length > 0">
                        <div class="mda-list-item animated fadeIn" (click)="goto(t.id)"
                             *ngFor="let t of finished">
                            <div class="mda-list-item-icon">
                                <img loading="lazy" class="rta-img" src="{{t.winner_points_award | rtaImgPipe}}">
                            </div>
                            <div class="mda-list-item-text mda-2-line" style="padding: 13px 0;">
                                <small *ngIf="t.reserve_list" class="color-blue-500">Lista rezerwowa</small>
                                <p class="list-group-item-text">{{t.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
</div>


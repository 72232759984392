import {Component, Input} from '@angular/core';
import * as moment from 'moment';
import {Pricing} from '@model/court/pricing/Pricing';
import {StationPricing} from '@model/court/pricing/StationPricing';
import {Tariff} from '@model/court/pricing/Tariff';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent {
    activeStation: StationPricing = null;

    _pricing: StationPricing[];
    get pricing(): StationPricing[] {
        return this._pricing;
    }

    @Input('pricing')
    set pricing(value: StationPricing[]) {
        this._pricing = value;
        if (!value) {
            return;
        }
        if (value.length > 0) {
            this.setActiveStation(value[0]);
        }
        this._pricing = value.filter(t => this.hasActiveTariffs(t));
    }

    hasActiveTariffs(pricing: StationPricing): boolean {
        let restult = false;
        pricing.tariffs.forEach(t => {
            if (t.enabled) {
                restult = true;
            }
        });
        return restult;
    }

    setActiveStation(station: StationPricing) {
        this.activeStation = station;
    }

    stationIsActive(station: StationPricing) {
        return this.activeStation && this.activeStation.id === station.id;
    }

    prices(prices: Pricing[], weekDay = false): Pricing[] {
        return prices.filter(p => p.is_week_day === weekDay).sort(this.byDate);
    }

    byDate(first, second) {
        return first.start.split(':')[0] - second.start.split(':')[0];
    }

    bySequenceNumber(first: Tariff, second: Tariff) {
        return first.sequence_number >= second.sequence_number ? 1 : -1;
    }

    enabledOnly(tariff: Tariff) {
        return tariff.enabled;
    }

    dateOutOfBounds(tariff: Tariff): boolean {
        const now = moment();
        return moment(tariff.starts_at) > now || moment(tariff.ends_at) < now;
    }
}

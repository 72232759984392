import { Injectable } from "@angular/core";
@Injectable()
export class UnderscorePropertyNamingService {

    encode(data: any): any {
        if (data instanceof Array) {
            let result = [];
            for (var key in data) {
                result.push(this.encode(data[key]));
            }
            return result;
        }

        if (!(data instanceof Object)) {
            return data;
        }

        let result = {};
        var objectData: Object = data;
        for (var property in objectData) {
            var newName: string = property.replace(/([A-Z])/g, "_$1").toLowerCase()
                .replace(/[_]{2,}/g, '_');
            if (objectData[property] instanceof Array) {
                result[newName] = [];
                for (var propertyKey in objectData[property]) {
                    result[newName].push(this.encode(objectData[property][propertyKey]));
                }
            } else if (objectData[property] instanceof Object) {
                result[newName] = this.encode(objectData[property]);
            } else {
                result[newName] = objectData[property];
            }
        }

        return result;
    }

    decode(data: any): any {
        if (data instanceof Array) {
            let result = [];
            for (var key in data) {
                result.push(this.decode(data[key]));
            }
            return result;
        }

        if (!(data instanceof Object)) {
            return data;
        }

        let result = {};
        var objectData: Object = data;
        for (var property in objectData) {
            var newName: string = property.replace(/(_)([a-z])/g, function (data) {
                return data.charAt(1).toUpperCase();
            });
            if (objectData[property] instanceof Array) {
                result[newName] = [];
                for (var propertyKey in objectData[property]) {
                    result[newName].push(this.decode(objectData[property][propertyKey]));
                }
            } else if (objectData[property] instanceof Object) {
                result[newName] = this.decode(objectData[property]);
            } else {
                result[newName] = objectData[property];
            }
        }

        return result;
    }
}

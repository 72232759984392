export type SideType = 'home' | 'visitor';


export const Side = {
  HOME: 'home' as SideType,
  VISITOR: 'visitor' as SideType
};

export type ID = any;

export class SideInfo {
  score?: {
    score: number; // TODO: deprecated
    firstSet: number | string;
    secondSet: number | string;
    thirdSet: number | string;
  };

  seed?: {
    displayName: string;
    rank: number;
    sourceGame: any; // Game
    sourcePool: object;
    picture: string;
    userId: number;
  };

  team?: {
    id: ID;
    name: string;
    matchId: number;
  };
}

export class Game {
  id: ID;
  // the game name
  name: string;
  // optional: the label for the game within the bracket, e.g. Gold Finals, Silver Semi-Finals
  bracketLabel?: string;
  // the unix timestamp of the game-will be transformed to a human-readable time using momentjs
  scheduled: string;

  station_name: string;
  in_progress: boolean;

  court?: {
    name: string;
    venue: {
      name: string
    }
  };

  sides: {
    [side in SideType]: SideInfo
  };
}


export interface GameProps {
    game: Game;
    winningPathLength: number;
}

import {Component, Input, OnInit} from '@angular/core';
import {Opponent} from '@model/opponent/opponent';

@Component({
  selector: 'app-dashboard-ranking',
  template: `
    <div class="row ranking-wrapper">
      <div class="col-xs-offset-1 col-xs-5">
        <em class="ion ion-person"></em>
        <div class="ranking">
          <em *ngIf="historyType === 'down'" class="history down ion ion-arrow-graph-down-right"></em>
          <em *ngIf="historyType === 'up'" class="history up ion ion-arrow-graph-up-right"></em>
          <span
              [class.down]="historyType === 'down'"
              [class.up]="historyType === 'up'"
              class="history"
          >{{ diff }}</span>
          <div>{{ ranking.single_ranking || 0 }}</div>
        </div>
      </div>
      <div class="col-xs-offset-1 col-xs-5">
        <em class="ion ion-person-stalker"></em>
        <div class="ranking">
<!--          <em *ngIf="multiHistoryType === 'down'" class="history down ion ion-arrow-graph-down-right"></em>-->
<!--          <em *ngIf="multiHistoryType === 'up'" class="history up ion ion-arrow-graph-up-right"></em>-->
          <span
              [class.down]="multiHistoryType === 'down'"
              [class.up]="multiHistoryType === 'up'"
              class="history"
          >{{ multiDiff }}</span>
          <div>{{ '-' }}</div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./../dashboard.component.scss']
})
export class DashboardRankingComponent implements OnInit {

  public historyType = 'up';
  public diff: number;
  public multiHistoryType = 'up';
  public multiDiff = '-';

  constructor() {
  }

  private _ranking: Opponent;

  get ranking() {
    return this._ranking;
  }

  @Input('ranking')
  set ranking(value: Opponent) {
    this._ranking = value;
    if (value) {
      this.diff = value.single_ranking - value.single_ranking_history.month1;
      this.historyType = this.diff < 0
        ? 'up'
        : 'down';

      this.diff = this.diff * -1;
    }
  }

  ngOnInit() {
  }

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'matchStatusPipe'
})
export class MatchStatusPipe implements PipeTransform {
    transform(status: string) {
        switch (status) {
            case 'NEW':
                return 'Nowy';
            case 'OPEN':
                return 'Zaplanowany';
            case 'CLOSED':
                return 'Zakończony';
            default:
                return '-';
        }
    }
}

<div class="card-heading">
    <div class="card-title">
        <button type="button" class="close" (click)="onWidgetClose.emit()" aria-hidden="true">&times;
        </button>
        Podsumowanie
    </div>
</div>
<ul class="list-group m0">
    <li class="list-group-item">
        <span>Data:</span>
        <span class="pull-right">{{ reservation.day | momentDate:'DD.MM.YYYY' }}</span>
    </li>
    <li class="list-group-item">
        <span>Kort:</span>
        <span class="pull-right">{{ reservation.stationName }}</span>
    </li>
    <li class="list-group-item">
        <span>Godziny:</span>
        <span class="pull-right">{{ reservation.begin|hourPipe }} - {{ reservation.end|hourPipe }}</span>
    </li>
    <li *ngIf="!hidePaymentInformation" class="list-group-item">
        <div>
            <span>Płatność:</span>
            <div class="text-right" style="margin-top: -25px;">
                <label class="radio-inline c-radio" *ngIf="paymentMethods === 'ONLINE'">
                    <input type="radio" name="i-radio-payment" value="ONLINE"
                           [(ngModel)]="selectedPaymentMethod" (change)="onPaymentMethodChange()"/><span
                        class="ion-record"></span> Online
                </label>
                <label class="radio-inline c-radio" *ngIf="paymentMethods === 'CASH_AND_ONLINE'">
                    <input type="radio" name="i-radio-payment" value="ONLINE"
                           [(ngModel)]="selectedPaymentMethod" (change)="onPaymentMethodChange()"/><span
                        class="ion-record"></span> Online
                </label>
                <label class="radio-inline c-radio" *ngIf="paymentMethods === 'CASH_AND_ONLINE'">
                    <input type="radio" name="i-radio-payment" value="CASH"
                           [(ngModel)]="selectedPaymentMethod" (change)="onPaymentMethodChange()"/><span
                        class="ion-record"></span> W klubie
                </label>
                <label class="radio-inline c-radio" *ngIf="paymentMethods === 'CASH'">
                    <input type="radio" name="i-radio-payment" value="CASH"
                           [(ngModel)]="selectedPaymentMethod" (change)="onPaymentMethodChange()"/><span
                        class="ion-record"></span> W klubie
                </label>
            </div>
        </div>
    </li>
    <li *ngIf="lightingEnabled && lightingPrice" class="list-group-item">
        <div>
            <span>Oświetlenie ({{ lightingPrice }}):</span>
            <div class="text-right" style="margin-top: -25px;">
                <label class="radio-inline c-radio">
                    <input (change)="lightingChanged()" type="radio" name="i-radio-lighting" value="false"
                           checked="checked"/><span class="ion-record"></span> Nie
                </label>
                <label class="radio-inline c-radio">
                    <input (change)="lightingChanged()" type="radio" name="i-radio-lighting" value="true"
                    /><span class="ion-record"></span> Tak
                </label>
            </div>
        </div>
    </li>
    <li class="list-group-item" *ngIf="multisport_available">
        <div>
            <span>Multisport:</span>
            <div class="text-right" style="margin-top: -25px;">
                <div class="mb-sm">
                    <label class="radio-inline c-radio">
                        <input (change)="multisportChange()" id="multisport0" checked type="radio"
                               name="multisport-radio"
                               value="0"/>
                        <span class="ion-record"></span> nie mam karty multisport
                    </label>
                </div>
                <div class="mb-sm">
                    <label class="radio-inline c-radio">
                        <input [disabled]="multisportDisabledWithTooShortReservationMessage"
                               (change)="multisportChange()" id="multisport1" type="radio" name="multisport-radio"
                               value="1"/>
                        <span class="ion-record"></span> mam 1 kartę multisport
                    </label>
                </div>
                <div class="mb-sm">
                    <label class="radio-inline c-radio">
                        <input [disabled]="multisportDisabledWithTooShortReservationMessage"
                               (change)="multisportChange()" id="multisport2" type="radio" name="multisport-radio"
                               value="2"/>
                        <span class="ion-record"></span> mam 2 karty multisport
                    </label>
                </div>
            </div>
            <div *ngIf="multisportDisabledWithTooShortReservationMessage"
                 class="alert alert-warning animate fadeIn"
                 style="font-size: 14px; margin-bottom: 0; padding: 5px;">
                Dla kart multisporta minimalny czas rezerwacji wynosi 60 minut
            </div>
        </div>
    </li>
    <li class="list-group-item" *ngIf="medicover_available">
        <div>
            <span>Medicover:</span>
            <div class="text-right" style="margin-top: -25px;">
                <div class="mb-sm">
                    <label class="radio-inline c-radio">
                        <input (change)="medicoverChange()" id="medicover0" checked type="radio"
                               name="medicover-radio"
                               value="0"/>
                        <span class="ion-record"></span> nie mam karty medicover
                    </label>
                </div>
                <div class="mb-sm">
                    <label class="radio-inline c-radio">
                        <input (change)="medicoverChange()" id="medicover1" type="radio" name="medicover-radio"
                               value="1"/>
                        <span class="ion-record"></span> mam 1 kartę medicover
                    </label>
                </div>
                <div class="mb-sm">
                    <label class="radio-inline c-radio">
                        <input (change)="medicoverChange()" id="medicover2" type="radio" name="medicover-radio"
                               value="2"/>
                        <span class="ion-record"></span> mam 2 karty medicover
                    </label>
                </div>
            </div>
        </div>
    </li>
    <li *ngIf="!hidePaymentInformation" class="list-group-item">
        <div
                *ngIf="reservationTariffs.length > 0 && reservationTariffs[0].id === null">
            <span>Cena:</span>
            <div class="text-right" style="margin-top: -25px;">
                <label class="radio-inline c-radio">
                    <input id="inlineradio1" type="radio" name="i-radio" value="0"
                           checked="checked"/><span class="ion-record"></span> 0zł
                </label>
                <span>{{ reservationTariffs[0].name ? reservationTariffs[0].name : "Cennik standardowy" }}</span>
            </div>
        </div>
        <div *ngIf="reservationTariffs.length > 0 && reservationTariffs[0].id !== null" style="display: flex;">
            <span>Cena:</span>
            <div class="text-right" style="display: flex; flex-direction: column; width: 100%;">
                <div class="mb-sm" style="display: flex; flex-direction: column;" *ngFor="let tariff of reservationTariffs; index as i">
                    <label class="radio-inline c-radio">
                        <input id="{{i}}" [checked]="i===0" type="radio" name="i-radio" value="{{tariff.cost}}"/>
                        <span class="ion-record"></span> {{ tariff.cost | tariffCost }}
                    </label>
                    <span>{{ tariff.name }} <i placement="left" tooltip="{{tariff.description}}"
                                               class="text-info fa fa-info-circle"></i></span>
                </div>
            </div>
        </div>
    </li>
    <li *ngIf="withVisaCardDiscount" class="list-group-item animated fadeIn">
        <div class="alert alert-info mb-0" style="padding: 8px;">
            Dzięki karcie tenisowej Visa Banku BNP Paribas kwota przy płatności online zostanie pomniejszona o 10%
        </div>
    </li>
    <li *ngIf="withMessage" class="list-group-item ">
        <div class="text-right">
        <textarea class="form-control"
                  style="width: 100%;"
                  (resize)="false" rows="2"
                  [(ngModel)]="message"
                  placeholder="Wiadomość"
        ></textarea>
        </div>
    </li>
    <li *ngIf="agreement" class="list-group-item ">
        <div class="text-left">
            <label style="font-weight: 400; font-size: 13px; margin-top: 10px;" class="mda-checkbox text-justify">
                <input type="checkbox" [(ngModel)]="agreementModel"/><em class="bg-green-500"></em>
                <span class="color-red-500">*</span> Akceptuję warunki <a (click)="openAgreementModal(); agreementModel = !agreementModel">regulaminu</a>
            </label>
        </div>
    </li>
    <li class="list-group-item p0">
        <button *ngIf="agreement" [disabled]="!agreementModel" class="btn wd-wide bg-green-500" style="height: 42px;"
                (click)="openCustomerDialog2()">REZERWUJ
        </button>
        <button *ngIf="!agreement" class="btn wd-wide bg-green-500" style="height: 42px;"
                (click)="openCustomerDialog2()">REZERWUJ
        </button>
    </li>
</ul>

<ngx-smart-modal
        [identifier]="MODAL_ID"
        [closable]="false"
        (onDismiss)="hideModal()"
>
    <app-modal-header [header]="'Regulamin'" (onClose)="hideModal()"></app-modal-header>

    <div class="agreement">{{ agreement }}</div>

    <app-modal-footer [withSuccessButton]="false" (onModalClose)="hideModal()"></app-modal-footer>
</ngx-smart-modal>

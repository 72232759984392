import {SidebarMenuLink} from '@core/menu/menu.service';

const Warnings = {
    name: 'Ostrzeżenia',
    link: '/user/warnings',
    iconclass: 'ion-alert-circled',
    // imgpath: 'assets/img/icons/aperture.svg',
    order: 0,
    warningsLabel: {
        count: 0,
        classname: 'badge bg-success'
    },
    label: {
        count: 0,
        classname: 'badge bg-danger'
    },
    sidebar: true
};

const MapCourt = {
    name: 'Mapa kortów',
    link: '/court/map/start',
    iconclass: 'ion-android-globe',
    order: 2,
    sidebar: true
};

const MapTournament = {
    name: 'Mapa turniejów',
    link: '/court/map/tournaments',
    iconclass: 'ion-android-globe',
    order: 3,
    sidebar: true
};

const CourtInfo = {
    name: 'Informacje',
    link: '/court-detail/info',
    iconclass: 'ion-radio-waves',
    order: 4,
    sidebar: true
};

const CourtGallery = {
    name: 'Galeria',
    link: '/court-detail/gallery',
    iconclass: 'ion-ios-albums-outline',
    order: 5,
    sidebar: true
};

const CourtPricing = {
    name: 'Cennik',
    link: '/court-detail/pricing',
    iconclass: 'ion-cash',
    order: 6,
    sidebar: true
};

const CourtTournaments = {
    name: 'Ligi i turnieje',
    link: '/court-detail/tournaments',
    iconclass: 'ion-navicon',
    order: 7,
    sidebar: true
};

const UserDashboard = {
    name: 'Dashboard',
    link: '/user/dashboard',
    iconclass: 'ion-ios-home',
    order: 1,
    sidebar: true
};

export const PanelExternalLink: SidebarMenuLink = {
    name: 'Obsługa kortów',
    href: 'http://panel.tenis4u.pl',
    iconclass: 'ion-android-options',
    order: 7,
    moderatorOnly: true
};

export const menu = [
    Warnings,
    UserDashboard,
    MapCourt,
    MapTournament,
];

export const courtPageMenu = [
    Warnings,
    CourtInfo,
    CourtPricing,
    CourtGallery,
    CourtTournaments,
    UserDashboard,
    MapCourt,
    MapTournament,
];

export default function getRoundName(round: number): string {
    switch (round) {
        case 0:
            return 'Finał';
        case 1:
            return 'Półfinał';
        case 2:
            return 'Ćwierćfinał';
        case 3:
            return 'Runda 16';
        case 4:
            return 'Runda 32';
        case 5:
            return 'Runda 64';
        case 6:
            return 'Runda 128';
        case 7:
            return 'Runda 256';
        case 8:
            return 'Runda 512';
    }
    return `Runda ${round}`;
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {uniqueBuildHash} from './consts';
import {
    clearRedirectStateForOnlinePayment,
    loadRedirectStateForOnlinePayment
} from './routes/court/tabs/reservation/utils/reservationLocalStorageManager';
import {ActivatedRoute, Router} from '@angular/router';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {AppStateService} from '@core/state/app-state.service';
import swalSuccess from '@shared/utils/swal/swalSuccess';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private myReservationsService: MyReservationsService,
        private appState: AppStateService
    ) {
        console.log(uniqueBuildHash);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            const myParam = params['p24success'];
            if (myParam) {
                const paymentId = params['paymentId'];
                if (paymentId) {
                    this.manageRedirectIfRequested(paymentId);
                }
            }
        });
    }

    private manageRedirectIfRequested(paymentId: string) {
        const withRedirect = loadRedirectStateForOnlinePayment(paymentId);
        if (withRedirect) {
                Swal.fire({
                    title: 'Płatność zakończona sukcesem',
                    html: 'Kod rezerwacji: ' + withRedirect.reservationCode,
                    showConfirmButton: true
                }).then((result) => {
                    clearRedirectStateForOnlinePayment(paymentId);
                    this.removeQueryParams();
                });
        }
    }

    removeQueryParams() {
        const params = {...this.route.snapshot.queryParams};
        delete params['p24success'];
        delete params['paymentId'];
        this.router.navigate([], {queryParams: params});
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'noDataPipe'
})
export class NoDataPipe implements PipeTransform {
    transform(data: string | null) {
        return data ? data : '-';
    }
}

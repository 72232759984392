import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reservationStatus'
})
export class ReservationStatusPipe implements PipeTransform {
    transform(status: string, toReceive: number) {
        switch (status) {
            case 'USED':
                return 'Wykorzystana';
            case 'FUTURE':
                return 'Do wykorzystania';
            case 'TO_RECEIVE':
                return 'Do odebrania ' + toReceive + ' MIN';
            case 'RECEIVED':
                return 'Odebrana';
            case 'CONFIRMED':
                return 'Potwierdzona';
            case 'UNCONFIRMED':
                return 'Niepotwierdzona';
            case 'DELETED':
                return 'Usunięta';
        }

        return status;
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tournamentTypePipe'
})
export class TournamentTypePipe implements PipeTransform {
    transform(type: string) {
        switch (type) {
            case 'SINGLE_CUP':
            case 'CUP':
                return 'Pucharowy';
            case 'CONSOLATION_CUP':
                return 'Pucharowy + pocieczenia';
            case 'PLAYOFF_CUP':
                return 'Grupowy + pucharowy';
            case 'LEAGUE':
                return 'Liga';
            case 'CHALLENGE':
                return 'Challenge'
            default:
                return '-';
        }
    }
}

import {PaymentDotpay} from '@model/reservation/paymentDotpay';

export const generateDotpayPaymentLink = (payment: PaymentDotpay) => {
  return `${payment.environment}/
?id=${payment.shop_id}
&kwota=${payment.price}
&opis=${encodeURIComponent(payment.description)}
&control=${payment.control}
&url=${encodeURIComponent(document.location.href)}`;
};

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MapCourt} from '@model/map/MapCourt';

@Component({
    selector: 'app-card-court-detail-with-thumb',
    template: `
        <div *ngIf="court" class="flex-center">
            <div>
                <img loading="lazy" *ngIf="court" src={{thumb}}>
            </div>
            <div class="ml-15">
                <app-card-court-detail
                        [court]="court"
                        [moreInfo]="true"
                        [boldHerder]="true"
                        (onRouteChange)="routeChange($event)"
                ></app-card-court-detail>
            </div>
        </div>
    `,
    styles: [`
        img {
            width: 110px;
            height: auto;
            border-radius: 5px;
        }
    `]
})
export class CardCourtDetailWithThumbComponent {
    @Input() court: MapCourt;
    @Input() thumb: string;
    @Input() moreInfo = false;
    @Input() boldHerder = false;

    @Output() onRouteChange = new EventEmitter<MapCourt>();

    routeChange(marker: MapCourt) {
        this.onRouteChange.emit(marker);
    }
}

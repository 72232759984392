import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-select',
  template: `
    <div class="mda-form-group">
      <div class="mda-form-control">
        <select
            name="user-select"
            [(ngModel)]="model"
            (ngModelChange)="modelChanged($event)"
            class="form-control"
        >
          <option *ngFor="let item of items"
                  value="{{item[propertyValue]}}">{{item[propertyDisplay]}}</option>
        </select>
        <div class="mda-form-control-line"></div>
        <label>{{label}}</label>
      </div>
    </div>
  `,
  styleUrls: []
})
export class SelectComponent {

  @Input() model: any;
  @Input() items: any[];
  @Input() label: string;
  @Input() propertyValue: string;
  @Input() propertyDisplay: string;
  @Output() dataChanged = new EventEmitter<string | number>();

  modelChanged(data: any) {
    this.dataChanged.emit(data);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {CourtDetail} from '@model/court/CourtDetail';
import {StationPricing} from '@model/court/pricing/StationPricing';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

    @Input() courtInfo: CourtDetail;
    @Input() pricing: StationPricing[];
    @Input() images: string[];

    constructor() {
    }

    ngOnInit() {
    }

}

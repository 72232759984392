<div class="container-full">
    <div class="page-container bg-blue-grey-900">
        <div class="container container-xs">
            <img loading="lazy" width="150px" src="/assets/images/tenis4U-white-logo-S.png" class="mv-lg block-center img-responsive"/>

            <div *ngIf="passwordChanged" class="card bg-success">
                <div class="card-heading"><em class="ion-checkmark-round pull-right icon-lg"></em>
                    <h4 class="mt0">Hasło zostało zmienione</h4>
                </div>
                <div class="card-body">
                    <p>Możesz się zalogować</p>
                </div>
                <div class="card-footer text-right">
                    <a [routerLink]="'/login'" class="btn btn-success btn-flat text-white">Logowanie <em class="ion-ios-arrow-thin-right icon-fw"></em></a>
                </div>
            </div>

            <form *ngIf="!passwordChanged" [formGroup]="regForm" novalidate class="card b0 form-validate" (submit)="submitForm($event, regForm)" novalidate="">
                <div class="card-offset pb0">
                    <div *ngIf="regForm.valid" class="card-offset-item text-right">
                        <button type="submit" class="btn btn-success btn-circle btn-lg"><em class="ion-checkmark-round"></em></button>
                    </div>
                </div>
                <div class="card-heading">
                    <div class="card-title text-center">Resetowanie hasła</div>
                </div>
                <div class="card-body">
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="password" name="accountPassword" formControlName="password" [formControl]="regForm.controls['password']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Hasło</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['password'].hasError('minlength') && (regForm.controls['password'].dirty || regForm.controls['password'].touched)">Minimalna długość hasła to 6 znaków</span>
                        <span class="text-danger" *ngIf="regForm.controls['password'].hasError('required') && (regForm.controls['password'].dirty || regForm.controls['password'].touched)">Pole wymagane</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-locked-outline icon-lg"></em></span>
                    </div>
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="password" name="accountPassword2" formControlName="confirmPassword" [formControl]="regForm.controls['confirmPassword']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Powtórz hasło</label>
                        </div>
                        <span class="text-danger" *ngIf="regForm.controls['confirmPassword'].errors?.equalTo">Podane hasła nie są identyczne</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-locked-outline icon-lg"></em></span>
                    </div>
                </div>
                <button type="submit" [disabled]="!regForm.valid || regForm.pristine" class="btn btn-primary btn-flat">Resetuj hasło</button>
            </form>
            <div class="text-center text-sm"><a [routerLink]="'/login'" class="text-inherit">Logowanie</a></div>

        </div>
    </div>
</div>

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'seriesIdNamePipe'
})
export class SeriesIdNamePipe implements PipeTransform {

    public static getName(seriesId: number): string {
        switch (seriesId) {
            case 1:
                return 'Ekstraklasa';
            case 2:
                return 'I Liga Mężczyzn';
            case 3:
                return 'II Liga Mężczyzn';
            case 4:
                return 'Kategoria 40+';
            case 5:
                return 'Kategoria 50+';
            case 6:
                return 'Ekstraklasa Kobiet';
            case 7:
                return 'Open Amator Kobiet';
            case 8:
                return 'Debel Kobiet';
            case 9:
                return 'Debel Mężczyzn';
            case 10:
                return 'Mikst';
        }
    }

    transform(seriesId: number | null) {
        return SeriesIdNamePipe.getName(seriesId);
    }
}

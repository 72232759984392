import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { LockComponent } from './lock/lock.component';
import { RecoverComponent } from './recover/recover.component';
import { ResetComponent } from './reset/reset.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        LoginComponent,
        SignupComponent,
        LockComponent,
        RecoverComponent,
        ResetComponent
    ],
    exports: [
        RouterModule,
        LoginComponent,
        SignupComponent,
        LockComponent,
        RecoverComponent,
        ResetComponent
    ]
})
export class AuthModule { }

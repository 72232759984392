import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'provincePipe'
})
export class ProvincePipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case 'PL_DS': return 'dolnośląskie';
      case 'PL_KP': return 'kujawsko-pomorskie';
      case 'PL_LU': return 'lubelskie';
      case 'PL_LB': return 'lubuskie';
      case 'PL_LD': return 'łódzkie';
      case 'PL_MA': return 'małopolskie';
      case 'PL_MZ': return 'mazowieckie';
      case 'PL_OP': return 'opolskie';
      case 'PL_PK': return 'podkarpackie';
      case 'PL_PD': return 'podlaskie';
      case 'PL_PM': return 'pomorskie';
      case 'PL_SL': return 'śląskie';
      case 'PL_SK': return 'świętokrzyskie';
      case 'PL_WN': return 'warmińsko-mazurskie';
      case 'PL_WP': return 'wielkopolskie';
      case 'PL_ZP': return 'zachodniopomorskie';
      default: return value;
    }
  }
}

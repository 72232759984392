import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceCost'
})
export class PriceCostPipe implements PipeTransform {

  transform(cost: any, args?: any): any {
      return cost === 0
          ? '-'
          : `${Number(cost).toFixed(2).toString()}zł`;
  }

}

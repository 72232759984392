import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AutomationAction} from '@model/reservation/UserReservation';
import {ReservationAutomationAction} from '../dashboard-reservations-rep.component';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {ToastrService} from 'ngx-toastr';

interface AutomationState {
    enabled: boolean;
    nextMethod: string;
    automation: AutomationAction;
}

@Component({
    selector: 'app-reservation-automation-actions',
    template: `
      <div class="all-toggle-wrapper">
        
      <div *ngIf="light" class="toggle-wrapper">
        <div class="toggle">
          ON
          <ui-switch
              [(ngModel)]="light.enabled"
              (ngModelChange)="automation(light.automation, light.nextMethod)"
              [checked]="light.enabled"
              [disabled]="!light.automation.available"
              [switchColor]="'#00d285'"
              [defaultBgColor]="'#fff'"
              [color]="'#fff'"
              [size]="'small'"
          ></ui-switch>
          OFF
        </div>
        Oświetlenie
      </div>
      <div *ngIf="door" class="toggle-wrapper">
        <div class="toggle">
          ON
          <ui-switch
              [(ngModel)]="door.enabled"
              (ngModelChange)="automation(door.automation, door.nextMethod)"
              [checked]="door.enabled"
              [disabled]="!door.automation.available"
              [switchColor]="'#00d285'"
              [defaultBgColor]="'#fff'"
              [color]="'#fff'"
              [size]="'small'"
          ></ui-switch>
          OFF
        </div>
        Drzwi
      </div>
      <div *ngIf="sprinkler"  class="toggle-wrapper">
        <div class="toggle">
          ON
          <ui-switch
              [(ngModel)]="sprinkler.enabled"
              (ngModelChange)="automation(sprinkler.automation, sprinkler.nextMethod)"
              [checked]="sprinkler.enabled"
              [disabled]="!sprinkler.automation.available"
              [switchColor]="'#00d285'"
              [defaultBgColor]="'#fff'"
              [color]="'#fff'"
              [size]="'small'"
          ></ui-switch>
          OFF
        </div>
        Zraszacz
      </div>
      </div>
          <button class="btn " style="margin-right: 5px;"
                  [class.btn-success]="a.available"
                  [class.disabled]="!a.available"
                  *ngFor="let a of automationAction.actions"
                  (click)="automation(automationAction.reservationId, a, 'OFF')"
          >
            <span *ngIf="a.type === 'DOOR'">Otwórz</span>
            <span *ngIf="a.type === 'LIGHT'">Włącz oświetlenie</span>
            <span *ngIf="a.type === 'SPRINKLER'">Włącz zraszacz</span>
          </button>
    `,
    styles: [`
        .all-toggle-wrapper {
            display: flex;
        }
        .toggle {
            display: flex;
            align-items: center;
            margin-bottom: -3px;
        }
        .toggle-wrapper {
            display: flex;
            margin-right: 15px;
            text-align: center;
            flex-direction: column;
            font-size: 12px;
        }
    `],
    styleUrls: []
})
export class ReservationAutomationActionsComponent implements OnChanges {
    @Input() automationAction: ReservationAutomationAction;
    @Output() actionExecuted = new EventEmitter();

    light: AutomationState;
    door: AutomationState;
    sprinkler: AutomationState;

    constructor(private service: MyReservationsService,
                private toastr: ToastrService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.automationAction && this.automationAction.actions.length) {
            this.automationAction.actions.forEach(action => {
                if (action.type === 'LIGHT') {
                    this.light = {
                        enabled: !!action.methods.find(m => m.type === 'ON'),
                        automation: action,
                        nextMethod: action.methods.length > 0 ? action.methods[0].type : null
                    };
                }
                if (action.type === 'DOOR') {
                    this.door = {
                        enabled: !!action.methods.find(m => m.type === 'ON'),
                        automation: action,
                        nextMethod: action.methods.length > 0 ? action.methods[0].type : null
                    };
                }
                if (action.type === 'SPRINKLER') {
                    this.sprinkler = {
                        enabled: !!action.methods.find(m => m.type === 'ON'),
                        automation: action,
                        nextMethod: action.methods.length > 0 ? action.methods[0].type : null
                    };
                }
            });
        }
    }

    automation(automationAction: AutomationAction, method: string|null) {
        if (!automationAction.available || !method) {
            return;
        }
        this.service.pushAutomation(this.automationAction.reservationId, automationAction.id, method).subscribe(
            () => {
                this.toastr.success('Ok');
                this.actionExecuted.emit();
            }, () => this.toastr.error('Niepowodzenie')
        );
    }
}

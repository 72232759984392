import {Component, Input} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AppStateService} from '@core/state/app-state.service';
import {FavoriteCourtsService} from '@services/shared/favorite-courts.service';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {Whoami} from '@core/authentication/whoami';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {getWhoami, saveWhoami} from '@shared/utils/whoami';

@Component({
    selector: 'app-heart',
    templateUrl: './heart.component.html',
    styleUrls: ['./heart.component.scss']
})
export class HeartComponent {
    isFav = false;
    favLoading = false;

    @Input() rightPadding: number;

    _courtId: number;
    get courtId(): number {
        return this._courtId;
    }

    @Input('courtId')
    set courtId(value: number) {
        this._courtId = value;
        this.isFav = this.isFavCourt(value);
    }

    constructor(private favService: FavoriteCourtsService,
                private state: AppStateService,
                private authService: AuthenticationService,
                private toastr: ToastrService) {
    }

    fav() {
        if (!this.state.userExists()) {
            this.authService.toastrMsg('Dostęp tylko dla zalogowanych użytkowników', '', true);
            // this.toastr.info('Dostęp tylko dla zalogowanych użytkowników');
            return;
        }
        this.favLoading = true;
        this.isFav = this.isFavCourt(this.courtId);
        this.isFav ? this.unlike() : this.like();
    }

    isFavCourt(id: number): boolean {
        if (!this.state.userExists()) {
            return;
        }
        const court = getWhoami().favorite_courts.find(item => item.id === id);
        return !!court;
    }


    like() {
        this.favService.like(this.courtId.toString()).subscribe(
            success => {
                this.updateLocalStorage();
                this.toastr.success('Kort dodany do ulubionych');
            }, error => {
                this.favLoading = false;
                if (error.status === 401) {
                    this.toastr.info('Dostęp tylko dla zalogowanych użytkowników');
                } else {
                    this.toastr.info('Spróbuj ponownie później', 'Błąd podczas dodawania kortu do ulubionych');
                }
            }
        );
    }

    unlike() {
        this.favService.unlike(this.courtId.toString()).subscribe(
            success => {
                this.updateLocalStorage();
                this.toastr.success('Kort usunięty z ulubionych');
            }, error => {
                this.favLoading = false;
                if (error.status === 401) {
                    this.authService.toastrMsg('Dostęp tylko dla zalogowanych użytkowników', '', true);
                } else {
                    this.toastr.info('Spróbuj ponownie później', 'Błąd podczas usuwania kortu z ulubionych');
                }
            }
        );
    }

    updateLocalStorage() {
        this.favService.getFavorites()
            .subscribe(success => {
                saveWhoami(success);
                this.isFav = !this.isFav;
                this.favLoading = false;
                this.state.setUserExists(success);

            }, err => {
                this.favLoading = false;
            });
    }
}


import {map, first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {CourtDetail} from '../../model/court/CourtDetail';
import {MapCourt} from '../../model/map/MapCourt';
import {API_HOST} from '../../consts';
import {Whoami} from '@core/authentication/whoami';

@Injectable()
export class FavoriteCourtsService {

  constructor(private http: HttpClient) {
  }

  getFavorites(): Observable<Whoami> {
    return this.http.get<Whoami>(`${API_HOST}/whoami`);
  }

  like(courtId: string): Observable<any> {
    return this.http.post<any>(`${API_HOST}/court/like/${courtId}`, {});
  }

  unlike(courtId: string): Observable<any> {
    return this.http.post<any>(`${API_HOST}/court/unlike/${courtId}`, {});
  }

  courtList(): Observable<MapCourt[]> {
    return this.http.get<CourtDetail[]>(`${API_HOST}/court/all`).pipe(
    first(),
    map((courts: CourtDetail[]): MapCourt[] => {
        return courts.map(court => {
          return new MapCourt(
            court.id,
            court.name,
            court.marker,
            this.mapCost(+court.min_cost, +court.max_cost),
            court.reservation_enabled,
            court.multisport_available,
            court.medicover_available,
          );
        });
      }
    ));
  }

  mapCost(min: number, max: number): string {
    return min === 0 && max === 0
      ? '-'
      : `${Number(min).toFixed(2).toString()}zł - ${Number(max).toFixed(2).toString()}zł`;
  }
}

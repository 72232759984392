import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-tournament-type',
  template: `
    <span *ngIf="type === 'SINGLE_CUP' || type === 'CUP'">Pucharowy</span>
    <span *ngIf="type === 'PLAYOFF_CUP'">Grupowy + pucharowy</span>
    <span *ngIf="type === 'CONSOLATION_CUP'">Pucharowy + pocieszenia</span>
    <span *ngIf="type === 'LEAGUE'">Liga</span>
    <span *ngIf="type === 'CHALLENGE'">Challenge</span>
  `
})
export class TournamentTypeComponent {
  @Input() type: string;
}

export class MapCourt {
    constructor(
        public id: number,
        public name: string,
        public marker: string,
        public pricing: string,
        public reservation: boolean,
        public multisport: boolean,
        public medicover: boolean,
        public amountForFixedHour: number = null,
    ) {
    }

    public multiple = false;
    public additionalCourts: MapCourt[] = [];
    additionalThumbs: string[];
}


import {Component, OnInit, ViewChild} from '@angular/core';
import {AppStateService} from '@core/state/app-state.service';
import {UserBasicDataDto} from '@model/user/UserBasicDataDto';
import {UserPanelService} from '@services/user/user-panel.service';
import apiMessageTranslate from '@shared/utils/apiMessageTranslate';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {swalError} from '@shared/swal/swalError';
import {Whoami} from '@core/authentication/whoami';
import {ToastrService} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {GoogleMap} from '@angular/google-maps';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {saveWhoami} from '@shared/utils/whoami';
import {isMobile} from '@shared/utils/isMobile';

declare var google: any;

@Component({
    selector: 'app-user-panel',
    templateUrl: './user-panel.component.html',
    styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {
    public mapIsLoading = true;
    public mapEditMode = false;
    apiLoaded: Observable<boolean>;
    initialCenter: google.maps.LatLngLiteral = {
        lat: 52.314454,
        lng: 19.410445
    };
    zoom = 6;
    mapOptions: google.maps.MapOptions = {
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        draggable: true,
        disableDefaultUI: false,
    };
    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    user: Whoami = null;
    editMode = null; // password, data, null, true
    userDto: UserBasicDataDto;

    passwordDto = {
        oldPassword: '',
        newPassword: '',
        newPassRepeat: ''
    };
    message = '';
    markers: Marker[] = [];

    isMobile = false;


    constructor(private userService: UserPanelService,
                private toastr: ToastrService,
                private httpClient: HttpClient,
                private state: AppStateService) {
        this.isMobile = isMobile();
    }

    ngOnInit() {
        this.loadUserData();
    }

    loadUserData() {
        // this.editMode = null;
        this.userService.whoAmI().subscribe(
            whoami => {
                this.user = whoami;
                this.state.setUserExists(this.user);
                this.userDto = new UserBasicDataDto(whoami);
                this.initMap();
                saveWhoami(whoami);
            },
            err => this.swalErr('Błąd pobierania danych')
        );
    }

    editProfile() {
        this.userService.modifyBasicData(this.userDto).subscribe(
            next => {
                this.loadUserData();
                this.editMode = null;
                this.mapEditMode = false;
                swalSuccess({
                    title: 'Dane zaktualizowane'
                });
            }, err => this.swalErr('Błąd podczas aktualizacji danych')
        );
    }

    changePassword() {
        if (this.passwordValid()) {
            this.userService.changePassword(this.passwordDto.oldPassword, this.passwordDto.newPassword).subscribe(
                next => swalSuccess({title: 'Hasło zaktualizowane'}),
                err => this.swalErr('Hasło nie zostało zmienione', apiMessageTranslate(err.json().message))
            );
        }
    }

    passwordValid(): boolean {
        if (this.passwordDto.newPassword !== this.passwordDto.newPassRepeat) {
            this.swalErr('Podane hasła nie są identyczne');
            return false;
        }
        if (this.passwordDto.newPassword.length < 6) {
            this.swalErr('Hasło musi się składać z minimum 6 znaków');
            return false;
        }

        return true;
    }

    swalErr(title: string, text: string = '') {
        swalError({
            title: title,
            text: text
        });
    }

    sendMessage() {
        if (this.message.length > 0) {
            this.userService.sendComplaint(this.message).subscribe(
                next => {
                    this.message = '';
                    this.toastr.success('Wiadomość wysłana');
                },
                err => this.toastr.error('Błąd wysyłania wiadomości')
            );
        }
    }

    initMap() {
        if (this.user.location) {
            this.initialCenter = {
                lat: this.user.location.latitude,
                lng: this.user.location.longitude
            };
            this.zoom = 10;
            this.markers = [{
                position: this.initialCenter,
                draggable: true,
                animation: 'DROP'
            }];
        }
        if (this.mapIsLoading) {
            this.apiLoaded = this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBM0-y-N25OUwhaVE1FX1z4394l73c5rzE', 'callback')
                .pipe(
                    map(() => {
                        this.mapIsLoading = false;
                        return true;
                    }),
                    catchError(() => of(false)),
                );
        }
    }

    clickedMarker(label: string, index: number) {
        console.log(`clicked the marker: ${label || index}`);
    }

    mapClicked($event: MouseEvent | any) {
        // debugger;
        if (!this.mapEditMode) {
            return;
        }
        const position = {
            lat: $event.latLng.lat(),
            lng: $event.latLng.lng(),
        };
        this.markers = [{
            position: position,
            draggable: true,
            animation: 'DROP'
        }];
        this.userDto.location = {
            latitude: position.lat,
            longitude: position.lng
        };
    }


}

// just an interface for type safety.
interface Marker {
    position: {
        lat: number;
        lng: number;
    };
    label?: string;
    draggable: boolean;
    animation: 'DROP' | 'BOUNCE' | '';
}

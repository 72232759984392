import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentDayOfWeekName'
})
export class MomentDayOfWeekName implements PipeTransform {

    public static getName(date: string|Date, capitalize = false) {
        if (!date) {
            return '';
        }

        switch (moment(date).day()) {
            case 0:
                return 'niedziela';
            case 1:
                return 'poniedziałek';
            case 2:
                return 'wtorek';
            case 3:
                return 'środa';
            case 4:
                return 'czwartek';
            case 5:
                return 'piątek';
            case 6:
                return 'sobota';
        }
    }

    transform(date: string|Date, capitalize = false) {
        return MomentDayOfWeekName.getName(date, capitalize);
    }
}

export const getRoundNameWithPosition = (round: number, position: number): string => {
    let result = '';
    switch (round) {
        case 0:
            result = 'finał';
            break;
        case 1:
            result = 'półfinał';
            break;
        case 2:
            result = 'ćwierćfinał';
            break;
        case 3:
            result = 'runda 16';
            break;
        case 4:
            result = 'runda 32';
            break;
        case 5:
            result = 'runda 64';
            break;
        case 6:
            result = 'runda 128';
            break;
        case 7:
            result = 'runda 256';
            break;
        case 8:
            result = 'runda 512';
            break;
        default:
            result = `runda ${round}`;
            break;
    }
    return `${result} - pozycja ${position + 1}`;
};

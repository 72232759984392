import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AppStateService} from '@core/state/app-state.service';
import {FavoriteCourt} from '@model/user/FavoriteCourt';
import {FavoriteCourtsService} from '@services/shared/favorite-courts.service';

@Component({
    selector: 'app-favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
    favCourts: FavoriteCourt[] = [];

    @Output() onCourtClick = new EventEmitter();

    constructor(private favCourtsService: FavoriteCourtsService,
                private state: AppStateService,
                private router: Router) {
    }

    ngOnInit() {
        this.getFavorites();
        this.state.userChanged().subscribe(() => this.getFavorites());
    }

    getFavorites() {
        this.favCourtsService.getFavorites()
            .subscribe(
                success => this.favCourts = success.favorite_courts,
                error => this.errorMessage(error)
            );
    }

    goto(court: FavoriteCourt) {
        this.onCourtClick.emit(true);
        this.router.navigate(['court/' + court.id]);
    }

    errorMessage(error: any) {

        // swal({
        //     title: 'Błąd podczas pobierania ulubionych kortów',
        //     text: '',
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-success',
        //     buttonsStyling: true
        // })
    }

}

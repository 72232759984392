<div class="row">
    <div class="col-md-4">
        <app-detail [courtData]="courtInfo"></app-detail>
    </div>
    <div class="col-md-4">
        <app-custom-pricing [courtId]="courtInfo.id" *ngIf="courtInfo.id === 29"></app-custom-pricing>
        <app-pricing *ngIf="courtInfo.id !== 29" [pricing]="pricing"></app-pricing>
    </div>
    <div class="col-md-4">
        <div class="card gallery-card">
            <app-gallery [images]="images"></app-gallery>
        </div>
    </div>
</div>

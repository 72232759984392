import {Component, Input, OnInit} from '@angular/core';
import {FavoriteCourt} from '@model/user/FavoriteCourt';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard-fav-courts',
  template: `
    <div class="no-data-title" *ngIf="!data.length">
      <div class="header">Nie masz ulubionych kortów.</div>
      Kliknij w ikonę <i class="fa fa-heart-o text-danger"></i> będąc w widoku kortu, aby dodać go do ulubionych.
    </div>
    <div class="row fav-court" *ngFor="let row of spliced">
        <div *ngFor="let court of row" class="col-xs-6 item animated fadeIn">
          <img loading="lazy" (click)="goto(court)" src="{{court.miniature}}" width="100%">
          <!--        <img loading="lazy" (click)="goto(court)" src="https://api.tenis4u.pl/courtGallery/show/miniature/d9/l8/l2/l9/1790d627-441d-424a-a6c7-a1b95ef0bd98.jpg" width="100%">-->
          
          <div (click)="goto(court)" class="name">{{court.name}}</div>
        </div>
    </div>
  `,
  styleUrls: ['./../dashboard.component.scss']
})
export class DashboardFavCourtsComponent {
  public spliced: any[];

  constructor(private router: Router) {
  }

  _data: FavoriteCourt[] = [];

  get data() {
    return this._data;
  }

  @Input('data')
  set data(value) {
    this._data = value;
    this.spliced = [];
    if (value) {
      this.spliced = value.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
    }
  }

  goto(court: FavoriteCourt) {
    this.router.navigate([`/court/${court.id}`]);
  }
}


import {first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {CourtDetail} from '../model/court/CourtDetail';
import {StationPricing} from '../model/court/pricing/StationPricing';
import {API_HOST} from '../consts';

@Injectable()
export class CourtDetailService {

    constructor(private http: HttpClient) {
    }

    getCourtBasicData(courtId: number): Observable<CourtDetail> {
        return this.http.get<CourtDetail>(`${API_HOST}/court/${courtId}`).pipe(first());
    }

    tariffByCourt(id: number): Observable<StationPricing[]> {
        return this.http.get<StationPricing[]>(`${API_HOST}/price/tariff/byCourt/${id}`).pipe(first());
    }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API_HOST} from '../../consts';
import {ChatMessage} from '@model/tournament/challenge/chat-message';
import * as moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

@Injectable()
export class ChatService {

    constructor(private http: HttpClient) {
    }

    getMessages(roomId: string, start: string, end: string): Observable<ChatMessage[]> {
        return this.http.get<ChatMessage[]>(`${API_HOST}/chat/${roomId}?start=${start}&end=${end}`);
    }

    getAllMessages(roomId: string, tournamentCreationDate: string): Observable<ChatMessage[]> {
        const start = moment().subtract(2, 'months').format(DATE_FORMAT);
        const end = moment().format(DATE_FORMAT);
        return this.getMessages(roomId, start, end);
    }

    sendMessage(roomId: string, content: string): Observable<any> {
        return this.http.put<any>(`${API_HOST}/chat/${roomId}`, {content});
    }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "./authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private auth: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.userExists()) {
            return true;
        } else {
            this.auth.toastrMsg('Dostęp tylko dla zalogowanych użytkowników', '', true);
            this.router.navigate(['/court/map/start']);
            return false;
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

export class TournamentScoreRow {

  constructor(
    public s1,
    public s2,
    public s3,
    public bothZero = false,
    public avatar: string,
    public winner = false,
    public name: string,
    public id: number,
    public grantedPoints: number
  ) {
    if (s3 === -1) {
      this.s3 = 'W';
    }
    if (s2 === -1) {
      this.s2 = 'W';
    }
    if (s1 === -1) {
      this.s1 = 'W';
    }
  }
}

@Component({
  selector: 'app-tournament-match-score',
  template: `
    <div *ngIf="score" class="row match animated fadeIn" style="margin: 0;">
      <table class="table text-center">
        <tr>
          <td style="width: 50px;">
            <div class="thumb32 pull-left" style="padding-left: 5px;">
              <app-avatar [userId]="score.id" [src]="score.avatar" size="32"></app-avatar>
            </div>
          </td>
          <td style="text-align: left; margin-left: 5px; padding-top: 10px;">
            <p class="cursor-pointer" (click)="gotoOpponent(score.id)">{{score.name}}
<!--              <i *ngIf="score.winner" class="fa fa-trophy"></i>-->
            </p>
          </td>
          <td style="width: 40px;"><span class="match-result">{{ score.s1 }}</span></td>
          <td style="width: 40px;"><span class="match-result">{{ score.s2 }}</span></td>
          <td style="width: 40px;"><span class="match-result">{{ score.bothZero ? '' : score.s3 }}</span></td>
        </tr>
      </table>
    </div>
  `,
  styleUrls: ['./tournament-match-score.component.scss']
})
export class TournamentMatchScoreComponent {
  @Input() score: TournamentScoreRow;
  constructor(private router: Router) { }

  gotoOpponent(id: number) {
    this.router.navigate([`/opponent/${id}`])
  }
}

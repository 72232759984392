import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {ToastrModule} from 'ngx-toastr';
import {AppInterceptorService} from './app-interceptor.service';

import {AppComponent} from './app.component';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthGuard} from '@core/authentication/auth.guard';
import {AuthenticationService} from '@core/authentication/authentication.service';

import {CoreModule} from '@core/core.module';
import {LayoutModule} from './layout/layout.module';
import {RoutesModule} from './routes/routes.module';
import {SharedModule} from '@shared/shared.module';
import {AppLoadModule} from '@core/app-load/app.load.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {UiSwitchModule} from 'ngx-toggle-switch';

import {ErrorHandler, Injectable} from '@angular/core';
// import * as Sentry from '@sentry/browser';
// import * as Sentry from "@sentry/angular";
import {API_HOST} from './consts';
import {BrowserTracing} from '@sentry/tracing';

// Sentry.init({
//     dsn: "https://0552ee7dff8f48a79ded416f7f85a332@o1244611.ingest.sentry.io/6401193",
//     environment: API_HOST.includes('test') ? 'test' : 'prod',
//     tracesSampleRate: 1.0
// });
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     handleError(error) {
//         Sentry.captureException(error.originalError || error);
//     }
// }

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppLoadModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        LayoutModule,
        NgxSmartModalModule.forRoot(),
        SharedModule.forRoot(),
        RoutesModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            enableHtml: true
        }),
        SlickCarouselModule,
        UiSwitchModule
    ],
    providers: [
        AuthenticationService,
        AuthRepository,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptorService,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        // {
        //     provide: ErrorHandler,
        //     useClass: SentryErrorHandler
        // },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: () => () => {},
        //     deps: [Sentry.TraceService],
        //     multi: true,
        // },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

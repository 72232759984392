import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import {ToastrService} from 'ngx-toastr';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {LoginUser} from '@model/auth/LoginUser';
import {RegisterUser} from '@model/auth/RegisterUser';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginUser = new LoginUser();
    rUser = new RegisterUser();
    passwordRepeat: string;
    sms: string;
    error = null;
    smsText = 'Kod SMS jest wymagany do aktywowania konta';
    modal = null;
    btnLoading = false;

    recoverPassEmail = '';
    loginForm: FormGroup;

    constructor(fb: FormBuilder,
                private toastr: ToastrService,
                private authenticationService: AuthenticationService,
                private authRepository: AuthRepository) {

        this.loginForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'password': [null, Validators.required]
        });
    }

    submitForm($ev, form: FormGroup) {
        $ev.preventDefault();
        const value = form.value;
        for (let c in form.controls) {
            form.controls[c].markAsTouched();
        }
        this.authenticationService.login(value.email, value.password);
    }

    ngOnInit() {
        this.authenticationService.logout(false);
    }

    login(): void {
        setTimeout(() => {
            this.btnLoading = false;
        }, 1500);
        this.authenticationService.login(this.loginUser.email, this.loginUser.password);
    }

    register(): void {
        if (this.validateRegister()) {
            this.resetSmsText();
            this.authRepository.register(this.rUser)
                .subscribe(success => {
                    setTimeout(() => {
                        this.showModal();
                    }, 300);
                }, error => {
                    this.setError(error.json().error);
                });
        }
    }

    setError(message: string) {
        message = this.translate(message);
        this.error = null;
        setTimeout(() => {
            this.error = message;
        }, 300);
    }

    validateRegister(): boolean {
        if (!this.rUser.name
            || !this.rUser.email
            || !this.rUser.password
            || !this.passwordRepeat
            || !this.rUser.phone_number) {
            this.error = 'Uzupełnij wszystkie pola';
            return false;
        }

        if (this.rUser.phone_number.toString().length !== 9) {
            this.error = 'Numer telefonu musi składać się 9 znaków';
            return false;
        }

        if (!this.validateEmail(this.rUser.email)) {
            this.error = 'Niepoprawny format adresu e-mail';
            return false;
        }

        if (this.rUser.password !== this.passwordRepeat) {
            this.error = 'Podane hasła nie są identyczne';
            return false;
        }

        return true;
    }

    private validateEmail(email): boolean {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email.toLowerCase());
    }
    private translate(msg: string): string {
        switch (msg) {
            case 'PHONE_ALREADY_TAKEN':
                return 'Podany numer jest zajęty';
            case 'EMAIL_ALREADY_TAKEN':
                return 'Podany adres e-mail jest zajęty';

            case 'CODE_INCORRECT':
                return 'Podany kod SMS jest nieprawidłowy';
            case 'USER_NOT_FOUND':
                return 'Nie znaleziono użytkownika';
        }

        return msg;
    }

    sendSms() {
        this.authRepository.sendSms(this.sms, this.rUser.phone_number)
            .subscribe(success => {
                this.toastr.success('Możesz się zalogować', 'Numer potwierdzony');
                this.modal = false;
            }, error => {
                this.smsText = this.translate(error.json().status);
            });
    }

    showModal() {
        this.modal = true;
    }

    hideModal() {
        this.modal = false;
    }

    resetSmsText() {
        this.smsText = 'Kod SMS jest wymagany do aktywowania konta';
    }
}

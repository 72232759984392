import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-modal-header',
    template: `
        <div class="modal-header">
            <button type="button" class="close" (click)="onClose.emit()" aria-hidden="true">&times;
            </button>
            <h4 class="modal-title">{{ header }}</h4>
        </div>
    `,
    styleUrls: []
})
export class ModalHeaderComponent {

    @Input() header: string;
    @Output() onClose = new EventEmitter();
}

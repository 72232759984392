<div [class.sidebar-right]="!isMobile">
  <ngx-smart-modal [identifier]="MODAL_ID" [closable]="false">
    <div class="modal-body" style="padding: 0;">
      <div (click)="hideSidebar()" class="pull-right cursor-pointer"
           style="padding: 15px;position: absolute;z-index: 100;right: 10px;"><em
          class="ion-close-round text-soft"></em></div>
      <div class="modal-header" *ngIf="selected">
        <div class="text-center">
          <div class="name">{{selected.court_name}}</div>

          <div class="text-muted" *ngIf="selected.price !== null">
            cena <span>{{selected.price.toFixed(2)}}</span> zł, zapłacono <span
              [class.text-success]="selected.amount_paid === selected.price"
              [class.color-amber-600]="selected.amount_paid < selected.price"
          >{{selected.amount_paid}} zł</span>
          </div>
          <div class="text-muted">{{selected.station_name}}</div>
          <div class="text-muted">{{selected.dayString}}</div>
          <div class="text-muted">{{selected.datesBetweenString}}</div>
        </div>
      </div>
      <div *ngIf="selected" class="modal-body" style="padding: 0;">
        <div class="reservations-rep">
          <div class="align-middle-center" *ngFor="let reservation of selected.reservations; index as i"
               [class.border-bottom-light]="i !== 0"
          >
            <div class="list-item">
              <div class="list-item-text name">{{dateFormat(reservation)}}</div>
              <div class="text-muted">{{reservation.station_name}}</div>
              <div class="text-muted">{{reservation.dayString}}</div>
              <span *ngIf="reservation.price !== 0">
                    <span class="badge badge-boxed bg-blue-500">{{reservation.price.toFixed(2)}} zł</span>
                    <span (click)="payForReservation(reservation)"
                          *ngIf="reservation.tpay_payment && reservation.payment_status !== 'CASH_PAID' && reservation.payment_status !== 'ONLINE_PAID'"
                          class="color-amber-600"
                          style="cursor: pointer; margin: 0 5px;"
                    >Zapłać</span>
                    <span *ngIf="reservation.payment_status === 'ONLINE_PAID'"
                          class="color-green-500" style="margin-left: 5px;"
                    >Zapłacono online</span>
                    <span *ngIf="reservation.payment_status === 'CASH_PAID'"
                          class="color-green-500"
                    >Zapłacono</span>
                </span>
<!--<div>rep status{{reservation.repeatable_status}}</div>-->
<!--<div>status{{reservation.status}}</div>-->
              <span *ngIf="reservation.status !== 'CONFIRMED' && !reservation.tpay_payment" class="badge badge-boxed text-uppercase"
                    [style.background]="reservation.repeatable_status | reservationColor">
                  {{reservation.repeatable_status | reservationStatus:reservation.minutes_to_receive}}
                </span>
            </div>
            <div class="list-item-icon">
              <button (click)="cancel(reservation, true)"
                      *ngIf="!(reservation.repeatable_status == 'TO_RECEIVE' && reservation.status == 'DELETED')"
                      class="btn btn-danger">
                <i class="fa fa-remove"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</div>

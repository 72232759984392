import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
    transform(date: string|Date, format = 'YYYY-MM-DD') {
        return !date
            ? '-'
            : moment(date).locale('pl').format(format);
    }
}

<table *ngIf="user" class="table table-striped animated fadeIn">
    <thead>
        <tr>
            <th style="width: 250px;"></th>
            <th></th>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Imię i nazwisko</td>
        <td>
            <input class="form-control" [(ngModel)]="userDto.real_name" placeholder="Imię i nazwisko">
        </td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Email</td>
        <td><input class="form-control" [(ngModel)]="userDto.email" placeholder="E-mail"></td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Miejscowość</td>
        <td><input class="form-control" [(ngModel)]="userDto.city" placeholder="Miejscowość"></td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Województwo</td>
        <td>
            <select class="form-control" [(ngModel)]="userDto.province">
                <option value="PL_DS" [selected]="userDto.province === 'PL_DS'">dolnośląskie</option>
                <option value="PL_KP" [selected]="userDto.province === 'PL_KP'">kujawsko-pomorskie</option>
                <option value="PL_LU" [selected]="userDto.province === 'PL_LU'">lubelskie</option>
                <option value="PL_LB" [selected]="userDto.province === 'PL_LB'">lubuskie</option>
                <option value="PL_LD" [selected]="userDto.province === 'PL_LD'">łódzkie</option>
                <option value="PL_MA" [selected]="userDto.province === 'PL_MA'">małopolskie</option>
                <option value="PL_MZ" [selected]="userDto.province === 'PL_MZ'">mazowieckie</option>
                <option value="PL_OP" [selected]="userDto.province === 'PL_OP'">opolskie</option>
                <option value="PL_PK" [selected]="userDto.province === 'PL_PK'">podkarpackie</option>
                <option value="PL_PD" [selected]="userDto.province === 'PL_PD'">podlaskie</option>
                <option value="PL_PM" [selected]="userDto.province === 'PL_PM'">pomorskie</option>
                <option value="PL_SL" [selected]="userDto.province === 'PL_SL'">śląskie</option>
                <option value="PL_SK" [selected]="userDto.province === 'PL_SK'">świętokrzyskie</option>
                <option value="PL_WN" [selected]="userDto.province === 'PL_WN'">warmińsko-mazurskie</option>
                <option value="PL_WP" [selected]="userDto.province === 'PL_WP'">wielkopolskie</option>
                <option value="PL_ZP" [selected]="userDto.province === 'PL_ZP'">zachodniopomorskie
                </option>
            </select>
        </td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Rok urodzenia</td>
        <td><input class="form-control" type="number" [(ngModel)]="userDto.birth_year" placeholder="Rok urodzenia"></td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Dominująca ręka</td>
        <td>
            <label class="radio-inline c-radio">
                <input name="dominant-hand" type="radio" class="form-control" [(ngModel)]="userDto.dominant_hand" value="LEFT"><span class="ion-record"></span> Lewa
            </label>
            <label class="radio-inline c-radio">
                <input name="dominant-hand" type="radio" checked class="form-control" [(ngModel)]="userDto.dominant_hand" value="RIGHT"><span class="ion-record"></span> Prawa
            </label>
        </td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Backhand</td>
        <td>
            <label class="radio-inline c-radio">
                <input name="backhand-type" type="radio" class="form-control" [(ngModel)]="userDto.backhand_type" value="SINGLE_HANDED"><span class="ion-record"></span> Jednoręczny
            </label>
            <label class="radio-inline c-radio">
                <input name="backhand-type" type="radio" checked class="form-control" [(ngModel)]="userDto.backhand_type" value="DOUBLE_HANDED"><span class="ion-record"></span> Dwuręczny
            </label>
        </td>
    </tr>
    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>Płeć</td>
        <td>
            <label class="radio-inline c-radio">
                <input name="sex" type="radio" class="form-control" [(ngModel)]="userDto.sex" value="MALE"><span class="ion-record"></span> Mężczyzna
            </label>
            <label class="radio-inline c-radio">
                <input name="sex" type="radio" checked class="form-control" [(ngModel)]="userDto.sex" value="FEMALE"><span class="ion-record"></span> Kobieta
            </label>
            <label class="radio-inline c-radio">
                <input name="sex" type="radio" checked class="form-control" [(ngModel)]="userDto.sex" value="OTHER"><span class="ion-record"></span> Inna
            </label>
        </td>
    </tr>

    <tr>
        <td style="font-weight: 500;"><em class="ion-ios-play-outline icon-fw mr"></em>
            Dodatkowe informacje
            <div class="text-muted small">Np. jeśli bierzesz udział w challengu napisz na jakich kortach możesz grać jakie terminu są dla Ciebie optymalne.</div>
        </td>
        <td><textarea class="form-control" type="text" [(ngModel)]="userDto.additional_info" placeholder=""></textarea></td>
    </tr>

    </tbody>
</table>

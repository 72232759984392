import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'reservationColor'
})
export class ReservationColorPipe implements PipeTransform {
    transform(status: string) {
        switch (status) {
            case 'USED':
                return '#7dd242';
            case 'FUTURE':
                return '#d2cf2f';
            case 'TO_RECEIVE':
                return '#FFB300';
            case 'RECEIVED':
                return '#00d285';
            case 'CONFIRMED':
                return '#af30dc';
            case 'UNCONFIRMED':
                return '#33cbd2';
            case 'DELETED':
                return '#dc3e45';
        }

        return status;
    }
}

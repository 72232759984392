import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'walkowerPipe'
})
export class WalkowerPipe implements PipeTransform {
    transform(value: number) {
        return value === -1 ? 'W' : value;
    }
}

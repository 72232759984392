import {throwError as observableThrowError} from 'rxjs';

import {catchError, first, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Token} from '../../model/auth/token';
import {AppStateService} from '../state/app-state.service';
import {AuthRepository} from './auth-repository.service';
import {API_HOST} from '../../consts';
import {of} from 'rxjs/internal/observable/of';
import {Whoami} from '@core/authentication/whoami';
import {clearWhoami, getWhoami, saveWhoami} from '@shared/utils/whoami';
import {clearToken, getToken, saveToken} from '@shared/utils/token';


declare var $: any;

@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient,
                private router: Router,
                private toastr: ToastrService,
                private authRepository: AuthRepository,
                private state: AppStateService) {

        if (getToken()) {
            this.http.get<Whoami>(this.whoamiurl).pipe(
                first())
                .subscribe(
                    whoami => this.state.setUserExists(whoami),
                    err => this.logout(false)
                );
        }
    }
    private returnUrl = '/court/map/start';
    private whoamiurl = `${API_HOST}/whoami`;

    static setLocalToken(token: Token) {
        clearToken();
        saveToken(token);
        const expTime = token.expires_in * 1000 + Date.now();
        localStorage.setItem('expires_at', expTime.toString());
        localStorage.removeItem('tokenExpired');
    }

    login(username: string, password: string) {
        this.authRepository.login(username, password).pipe(
            switchMap(token => {
                AuthenticationService.setLocalToken(token);
                return this.authRepository.whoAmI();
            }),
            map(whoami => {
                saveWhoami(whoami);
                this.state.setUserExists(whoami);
                this.redirectAfterLogin();
                return of();
            }),
            catchError(error => {
                this.toastrMsg('Logowanie nieudane', error.error_description);
                return observableThrowError(error);
            })
        ).subscribe();
    }

    toastrMsg(title: string, text: string = '', withLoginBtn = false) {
        if (withLoginBtn && this.router.url !== '/login') {
            localStorage.setItem('redirect', this.router.url);
        }
        const loginBtn = '<br><a class="btn btn-success close-btn" href="/#/login">Zaloguj</a>';
        text = withLoginBtn ? text + loginBtn : text;
        this.toastr.error(text, title);
    }

    logout(redirect = true) {
        clearToken();
        clearWhoami();
        localStorage.removeItem('expires_at');
        this.state.setUserExists(null);
        if (redirect) {
            this.router.navigate([this.resolverRedirectUrl()]);
        }
    }

    authenticated(): boolean {
        // Check if current date is greater than expiration
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return Date.now() < expiresAt;
    }

    userExists(): boolean {
        return !!getToken()
            && !!getWhoami()
            && !!localStorage.getItem('expires_at');
    }

    private resolverRedirectUrl(): string {
        const redirect = localStorage.getItem('redirect');

        if (!redirect) {
            return this.returnUrl;
        }
        localStorage.removeItem('redirect');

        switch (redirect) {
            case '/favorite':
            case '/my-reservation':
            case '/user':
                return this.userExists() ? redirect : this.returnUrl;
            default:
                return redirect;
        }
    }

    private redirectAfterLogin() {
        const url = this.resolverRedirectUrl();
        const redirectUrl = url.split('?')[0];

        this.router.navigate([redirectUrl]);
    }
}

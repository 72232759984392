import {Component, Input, OnInit} from '@angular/core';

declare var $: any;

@Component({
    selector: `app-loader`,
    template: `
      <div *ngIf="visible" class="loader-container" [class.full-screen]="fullScreen">
        <div class="loader"></div>
      </div>`,
    styles: [`
        
        .loader-container.full-screen {
            position: fixed;
            top: 0;
            left: 0;
        }
        
        .loader-container {
            z-index: 1100;
            border: none;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            background: rgb(255, 255, 255);
            opacity: 0.6;
            position: relative;
            cursor: wait;
        }
        .loader {
            padding-top: 8px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

    `]
})
export class LoaderComponent {
    @Input() visible = false;
    @Input() fullScreen = true;
}

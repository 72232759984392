<div class="wrapper">
    <h6 *ngIf="user && user.warns.length === 0">Użytkownik nie posiada ostrzeżeń</h6>
    <div *ngIf="user && user.warns.length">
        <progressbar class="progress" value="{{progress()}}" type="{{type()}}">{{message()}}</progressbar>
        <p class="text-center">3 ostrzeżenia skutkują blokadą konta.</p>

        <div class="card animated fadeIn shadow-z1" style="cursor: pointer;"
             *ngFor="let warn of user.warns">
            <div class="card-body">
                <div class="row">
                    <div class="card-content">
                        <div class="card-title">{{warn.court_name}}</div>
                        <p>{{warn | reservationDateTime: warn.reservation_starts_at:warn.reservation_ends_at}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
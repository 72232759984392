import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AvatarModule} from 'ngx-avatar';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {AppInterceptorService} from '../../app-interceptor.service';
import {AuthGuard} from '@core/authentication/auth.guard';
import {UserPanelService} from '@services/user/user-panel.service';
import {SharedModule} from '@shared/shared.module';
import {FavoriteComponent} from './favorite/favorite.component';
import {UserPanelEditComponent} from './panel/components/user-panel-edit';
import {UserPanelEditAvatar} from './panel/components/user-panel-edit-avatar';
import {UserPanelPasswordComponent} from './panel/components/user-panel-password';
import {UserPanelTableComponent} from './panel/components/user-panel-table';
import {UserPanelComponent} from './panel/user-panel.component';
import {ReservationsComponent} from './reservations/reservations.component';
import {TournamentsComponent} from './tournaments/tournaments.component';
import {WarningsComponent} from './warnings/warnings.component';
import {UserNotificationsComponent} from './panel/components/user-notifications/user-notifications.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardFavCourtsComponent} from './dashboard/components/dashboard-fav-courts.component';
import {DashboardMyTournamentsComponent} from './dashboard/components/dashboard-my-tournaments.component';
import {DashboardReservationsSingleComponent} from './dashboard/components/dashboard-reservations-single.component';
import {DashboardNextReservationComponent} from './dashboard/components/dashboard-next-reservation.component';
import {DashboardRankingComponent} from './dashboard/components/dashboard-ranking.component';
import {DashboardTournamentScoreComponent} from './dashboard/components/dashboard-tournament-score.component';
import {DashboardReservationsRepComponent} from './dashboard/components/dashboard-reservations-rep.component';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {CardTitleComponent} from './dashboard/components/common/card-title.component';
import {DashboardChallengePendingComponent} from './dashboard/components/dashboard-challenge-pending.component';
import {DashboardReservationsSingleActiveComponent} from './dashboard/components/dashboard-reservations-single-active.component';
import {UserPanelVoucherComponent} from './panel/components/user-panel-voucher.component';

const routes: Routes = [
  {
    path: 'panel', component: UserPanelComponent, canActivate: [AuthGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    // AvatarModule,
    NgxDropzoneModule,
    AvatarModule,
    NgxSmartModalModule
  ],
  declarations: [
    ReservationsComponent,
    FavoriteComponent,
    TournamentsComponent,
    WarningsComponent,
    UserPanelComponent,
    UserPanelTableComponent,
    UserPanelEditComponent,
    UserPanelPasswordComponent,
    UserPanelEditAvatar,
    UserNotificationsComponent,
    UserPanelVoucherComponent,
    DashboardComponent,
    DashboardFavCourtsComponent,
    DashboardMyTournamentsComponent,
    DashboardReservationsSingleComponent,
    DashboardReservationsSingleActiveComponent,
    DashboardNextReservationComponent,
    DashboardRankingComponent,
    DashboardTournamentScoreComponent,
    DashboardReservationsRepComponent,
    CardTitleComponent,
    DashboardChallengePendingComponent,
  ],
  providers: [
    UserPanelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
  ],
    exports: [
        ReservationsComponent,
        FavoriteComponent,
        TournamentsComponent,
        WarningsComponent,
        UserNotificationsComponent,
        CardTitleComponent,
        DashboardRankingComponent,
        DashboardNextReservationComponent,
        DashboardReservationsRepComponent,
        DashboardReservationsSingleComponent,
        DashboardReservationsSingleActiveComponent,
    ]
})
export class UserModule {
}

import {API_HOST} from '../../consts';
import {Token} from '@model/auth/token';

export const saveToken = (data: Token) => {
    localStorage.setItem(getKey(), JSON.stringify(data));
}

export const getToken = (): Token|null => {
    const token = localStorage.getItem(getKey());
    if (!token) {
        return null;
    }
    return JSON.parse(token);
}

export const clearToken = () => {
    localStorage.removeItem(getKey());
}

const getKey = () => {
    return `token-${API_HOST}`.replace('https://', '');
}


import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppStateService} from '@core/state/app-state.service';
import {UserPanelService} from '@services/user/user-panel.service';
import apiMessageTranslate from '../../../../shared/utils/apiMessageTranslate';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {swalError} from '@shared/swal/swalError';
import {Whoami} from '@core/authentication/whoami';

@Component({
    selector: 'app-user-panel-edit-avatar',
    templateUrl: 'user-panel-edit-avatar.html',
    styles: [`
        img {
            max-width: 100%;
            max-height: 300px;
            display: block;
            margin: 0 auto;
            margin-bottom: 15px;
        }

        ngx-dropzone,
        .custom-dropzone {
            margin: 20px;
        }

        .custom-dropzone {
            height: 250px;
            background: #333;
            color: #fff;
            border: 5px dashed rgb(235, 79, 79);
            border-radius: 5px;
            font-size: 20px;
        }

        .custom-dropzone.ngx-dz-hovered {
            border: 5px solid rgb(235, 79, 79);
        }

        ngx-dropzone-preview {
            margin: 10px auto !important;
        }
    `]
})
export class UserPanelEditAvatar {
    @Output() avatarUpdated = new EventEmitter<boolean>();
    @Input() user: Whoami;

    constructor(private service: UserPanelService,
                private state: AppStateService) {
    }

    public miniatureEdit = false;
    public showminiatureSave = false;
    private miniatureFormData = null;
    public files = [];

    removeMiniature(): void {
        this.service.removeMiniature().subscribe(() => {
            this.avatarUpdated.emit(true);
            this.miniatureEdit = false;
            this.showminiatureSave = false;
            swalSuccess({
                title: 'Domyślny avatar ustawiony'
            });
        }, err => {
            swalError({
                title: 'Niepowodzenie',
                text: apiMessageTranslate(err.error.message)
            });
        });
    }

    onSelect(event) {
        this.files = event.addedFiles;
        if (event.addedFiles.length) {
            const file = event.addedFiles[0];
            this.miniatureFormData = new FormData();
            this.miniatureFormData.append('file', file);
            this.showminiatureSave = true;
        }
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
    }

    changeMiniature() {
        this.service.updateMiniature(this.miniatureFormData).subscribe(() => {
            this.avatarUpdated.emit(true);
            this.showminiatureSave = false;
            this.miniatureEdit = false;
            this.files = [];
            swalSuccess({
                title: 'Avatar zaktualizowany'
            });
        }, err => {
            if (err.status === 0) {
                swalError({
                    title: 'Niepowodzenie',
                    text: 'Rozmiar zdjęcia jest za duży'
                });
                return;
            }

            swalError({
                title: 'Niepowodzenie',
                text: apiMessageTranslate(err.error.message)
            });
        });
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Notification} from '@model/notification/notification';
import {API_HOST} from '../../consts';

@Injectable()
export class NotificationRepository {

  constructor(private http: HttpClient) {
  }

  getNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${API_HOST}/notification/?limit=50`);
  }

  markAsRead(notificationId: string): Observable<any> {
    return this.http.post<any>(`${API_HOST}/notification/${notificationId}/isread`, {});
  }

  readAll(): Observable<any> {
    return this.http.post<any>(`${API_HOST}/notification/readAll`, {});
  }
}

import Swal from 'sweetalert2';

interface IProps {
  title: string;
  text?: string;
}

export const swalError = ({text, title}: IProps) => {
  Swal.fire(title, text, 'error');
};

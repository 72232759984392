import {Pipe, PipeTransform} from "@angular/core";
import {IPlayer} from '@model/tournament/schedule/IPlayer';
import {getRoundNameWithPosition} from '../utils/getRoundNameWithPosition';

@Pipe({
    name: 'playerNameOrRoundNamePipe',
    pure: true
})
export class PlayerNameOrRoundNamePipe implements PipeTransform {
    transform(player: IPlayer, isConsolationCup = true) {

        if (player.user) {
            return player.user.name;
        }

        if (player.candidate.from_match) {
            const {round, position, group} = player.candidate.from_match;
            const consolation = isConsolationCup && group === 1 ? ' (DP)' : '';
            return `${getRoundNameWithPosition(round, position)}${consolation}`;
        }

        if (player.candidate.position_in_group_stage !== null) {
            const round = 'awans z fazy grupowej';
            return `${round}`;
        }
    }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '@services/notification/notification.service';
import {AppStateService} from '@core/state/app-state.service';
import {NotificationRepository} from '@services/notification/notification.repository';
import {Notification} from '@model/notification/notification';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-notification',
  template: '',
})
export class NotificationComponent implements OnInit, OnDestroy {

  @Input() public mode = 'navbar';
  private notifications: Notification[] = [];
  private interval = null;
  private userSubscription: Subscription;

  constructor(private notificationSerivce: NotificationService,
              private notificationRepository: NotificationRepository,
              private appState: AppStateService) {
  }

  ngOnInit() {
    this.userSubscription = this.appState.userChanged().subscribe(
      next => {
        if (next !== null) {
          clearInterval(this.interval);
          this.startInterval();
        } else {
          clearInterval(this.interval);
        }
      }
    );

    if (this.appState.userExists()) {
      this.fetchData();
    }
  }

  startInterval() {
    this.interval = setInterval(() => {
      this.fetchData();
    }, 10000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.userSubscription.unsubscribe();
    this.notifications = [];
    this.notificationSerivce.notify([]);
  }

  private fetchData() {
    this.notificationRepository.getNotifications().subscribe(
      notifications => {
        this.notifications = notifications;
        this.notificationSerivce.notify(this.notifications);
      },
      () => this.notificationSerivce.notify([])
    );
  }
}

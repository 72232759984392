import {Component, OnInit} from '@angular/core';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AppStateService} from '@core/state/app-state.service';
import polishPlural from '../../../shared/utils/polishPlural';
import {Whoami} from '@core/authentication/whoami';

@Component({
    selector: 'app-warnings',
    templateUrl: './warnings.component.html',
    styleUrls: ['./warnings.component.scss']
})
export class WarningsComponent implements OnInit {

    public user: Whoami;

    constructor(private service: AuthRepository, private appState: AppStateService) {
    }

    ngOnInit() {
        this.getWhoami();
        this.appState.warningStreamUpdated().subscribe(() => this.getWhoami());
    }

    getWhoami() {
        if (this.appState.userExists()) {
            this.service.whoAmI()
                .subscribe(
                    success => this.user = success,
                    error => {
                    }
                );
        }
    }

    warningsPlural(): string {
        return polishPlural('ostrzeżenie', 'ostrzeżenia', 'ostrzeżeń', this.user.warns.length);
    }

    progress() {
        return 33.3 * this.user.warns.length;
    }

    type() {
        switch (this.user.warns.length) {
            case 0:
                return 'primary';
            case 1:
                return 'primary';
            case 2:
                return 'warning';
            case 3:
            default:
                return 'danger';
        }
    }

    message() {
        return this.user.warns.length === 3
            ? 'Konto zablokowane, ureguluj zaległości'
            : `Masz ${this.user.warns.length} ${this.warningsPlural()}`;
    }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-ball',
    template: `
        <span *ngIf="color">
        <svg enable-background="new 0 0 100 100" height="48px" id="Layer_1" version="1.1" viewBox="0 0 100 100" width="48px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" >
        <g>
            <g>
                <g>
                    <g/>
                </g>
                <g/>
            </g>
        </g>
            <g>
                <path transform="rotate(310, 40, 40)" [attr.fill]="color" d="M72.687,29.287C66.843,34.597,63.5,42.094,63.5,50c0,7.905,3.343,15.403,9.186,20.713   C77.833,65.329,81,58.037,81,50C81,41.963,77.833,34.671,72.687,29.287z"/>
                <path transform="rotate(310, 40, 40)" [attr.fill]="color" d="M38.5,50c0-7.906-3.343-15.403-9.187-20.713C24.167,34.671,21,41.963,21,50   c0,8.037,3.167,15.329,8.314,20.713C35.157,65.403,38.5,57.905,38.5,50z"/>
                <path transform="rotate(310, 40, 40)" [attr.fill]="color" d="M59.5,50c0-8.913,3.715-17.373,10.224-23.425C64.591,22.466,58.087,20,51,20s-13.591,2.466-18.724,6.575   C38.785,32.627,42.5,41.087,42.5,50c0,8.913-3.715,17.372-10.224,23.425C37.409,77.533,43.913,80,51,80s13.591-2.467,18.724-6.575   C63.215,67.372,59.5,58.913,59.5,50z"/>
            </g>
        </svg>
    </span>`
})
export class BallComponent {
    @Input() color: string;
}

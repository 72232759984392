import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {

    @Input()
    public images: string[];
    public zoomImage: string = null;

    constructor() {
        this.images = [];
    }

    public zoomToImage(image: string) {
        this.zoomImage = image;
    }

}

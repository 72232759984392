import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SupportingCourt} from '@model/tournament/supporting-court';

@Component({
  selector: 'app-supported-courts-list',
  template: `
    <table *ngIf="courts.length" class="table">
      <tbody>
      <tr *ngFor="let court of courts;">
        <td style="line-height: 32px;">
          <app-avatar class="pull-left" [src]="court.miniature" [size]="32"></app-avatar>
        </td>
        <td>
          <a (click)="courtClicked.emit(court.id)">{{court.name}} <i class="fa fa-external-link"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
  `,
  styles: [`
      .table {
          width: 80%;
          margin: 0 auto;
      }

      .table > tbody > tr > td {
          padding: 0;
          border-top: 0;
      }
  `]
})
export class SupportedCourtsListComponent implements OnInit {

  @Input() courts: SupportingCourt[];
  @Output() courtClicked = new EventEmitter<number>();

  ngOnInit() {
  }

}

<div *ngIf="tournament">
    <div class="card-title" [class.text-bold]="boldHerder">{{tournament.name}}</div>
    <div class="card-text">typ turnieju: <app-tournament-type [type]="tournament.type"></app-tournament-type></div>
    <div class="card-text">liczba graczy: {{tournament.players}}/{{tournament.max_players}}
        <span *ngIf="tournament.reserve_players"> (rezerwowych: {{tournament.reserve_players}})</span>
    </div>
    <div class="card-text">termin zapisów: {{tournament.end_of_applications | momentDate:'DD-MM-YYYY HH:mm'}}</div>
    <div class="card-text">data rozpoczęcia: {{tournament.start_date | momentDate:'DD-MM-YYYY'}}</div>
    <app-tournament-status [status]="tournament.status"></app-tournament-status>
    <a *ngIf="moreInfo" (click)="routeChange(tournament)" class="badge bg-black">więcej informacji</a>
</div>

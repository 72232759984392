import {Location, Whoami} from '@core/authentication/whoami';

export class UserBasicDataDto {
    city: string;
    email: string;
    province: string;
    real_name: string;
    backhand_type: string;
    birth_year: number;
    dominant_hand: string;
    sex: string;
    additional_info: string;
    location: Location;

    constructor(user: Whoami = null) {
        if (!user) {
            return;
        }
        this.city = user.city;
        this.email = user.email;
        this.province = user.province;
        this.real_name = user.real_name;
        this.sex = user.sex;
        this.backhand_type = user.backhand_type;
        this.birth_year = user.birth_year;
        this.dominant_hand = user.dominant_hand;
        this.additional_info = user.additional_info;
        this.location = user.location;
    }
}

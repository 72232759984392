import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Whoami} from '@core/authentication/whoami';
import {ReservationRepeatable} from '@model/reservation/UserReservation';
import {saveWhoami} from '@shared/utils/whoami';

@Injectable()
export class AppStateService {
    private user$ = new Subject();
    private makeReservationListener$ = new Subject();
    private warningsStream$ = new Subject();
    private mapViewListener$ = new Subject();
    private reservationModalStream$ = new Subject();

    private streamForCourtTournaments$ = new Subject<number>();

    private _courtList: { id: number, name: string }[] = [];
    private _whoami: Whoami = null;

    userExists(): boolean {
        return !!this._whoami;
    }
    setUserExists(whoami: Whoami) {
        saveWhoami(whoami);
        this.user$.next(whoami);
        this._whoami = whoami;
    }
    user(): Whoami {
        return this._whoami;
    }
    userChanged(): Observable<any> {
        return this.user$.asObservable();
    }


    courtList(): { id: number; name: string }[] {
        return this._courtList;
    }
    setCourtList(value: { id: number; name: string }[]) {
        this._courtList = value;
    }


    reservationUpdate() {
        this.makeReservationListener$.next(true);
    }
    reservationUpdateStream(): Observable<any> {
        return this.makeReservationListener$.asObservable();
    }


    setMapViewDefault() {
        this.mapViewListener$.next(true);
    }
    mapViewToDefault() {
        return this.mapViewListener$.asObservable();
    }

    updateWarningListStream() {
        this.warningsStream$.next(true);
    }
    warningStreamUpdated() {
        return this.warningsStream$.asObservable();
    }

    openCourtTournaments(event: number) {
        this.streamForCourtTournaments$.next(event);
    }

    onOpenCourtTournaments() {
        return this.streamForCourtTournaments$.asObservable();
    }

  closeReservationModal() {
    this.reservationModalStream$.next();
  }

  onCloseReservationModal() {
       return  this.reservationModalStream$.asObservable();
  }
}

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AutomationAction} from '@model/reservation/UserReservation';
import {ReservationAutomationAction} from '../dashboard-reservations-rep.component';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';

declare var $: any;

interface AutomationState {
    enabled: boolean;
    nextMethod: string;
    automation: AutomationAction;
}

@Component({
    selector: 'app-reservation-automation-actions-based-on-buttons',
    template: `
        <div class="wrapper">
            <button class="btn btn-sm" style="margin: 0 5px 5px 0;"
                    [class.btn-success]="a.available"
                    [disabled]="!a.available"
                    *ngFor="let a of automationAction.actions"
                    (click)="openWindow(a)"
            >
                <span>{{ a.name }}</span>
            </button>
        </div>
    `
})
export class ReservationAutomationActionsBasedOnButtonsComponent implements OnChanges {
    @Input() automationAction: ReservationAutomationAction;
    @Output() actionExecuted = new EventEmitter();
    private selectedAction: AutomationAction;

    constructor(private service: MyReservationsService,
                private toastr: ToastrService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    openWindow(action: AutomationAction) {
        const that = this;
        this.selectedAction = action;
        const onButton = action.methods.find(m => m.type === 'ON') ? `<button style="margin: 0 5px 5px 0;" class="btn btn-success on-btn">Włącz</button>` : '';
        const offButton = action.methods.find(m => m.type === 'OFF') ? `<button style="margin: 0 5px 5px 0;" class="btn btn-secondary off-btn">Wyłącz</button>` : '';
        const data = {title: action.name, html: ''};

        data.html = `${onButton} ${offButton}`;
        Swal.fire({
            title: data.title,
            html: data.html,
            showConfirmButton: false,
            showCloseButton: true,
            closeButtonHtml: '&times;'
        }).then((result) => {
            if (result.value) {
                Swal.close();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.close();
            }
        });

        $('.on-btn').on('click', function () {
            that.automation(that.selectedAction, 'ON');
            Swal.close();
        });
        $('.off-btn').on('click', function () {
            that.automation(that.selectedAction, 'OFF');
            Swal.close();
        });
    }

    automation(automationAction: AutomationAction, method: string | null) {
        if (!automationAction.available || !method) {
            return;
        }
        this.service.pushAutomation(this.automationAction.reservationId, automationAction.id, method).subscribe(
            () => {
                this.toastr.success('Ok');
                this.actionExecuted.emit();
            }, () => this.toastr.error('Niepowodzenie')
        );
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'seriesIdDescriptionPipe'
})
export class SeriesIdDescriptionPipe implements PipeTransform {
  transform(seriesId: number | null) {
    switch (seriesId) {
      case 1: return 'Kategoria przeznaczona dla graczy z przeszłością zawodniczą bądź dla czynnych, a także dla zawodników bez przeszłości zawodniczej,';
      case 2: return 'Kategoria przeznaczona tylko dla tenisistów bez przeszłości zawodniczej, oraz dla trenerów i instruktorów również bez przeszłości zawodniczej.';
      case 3: return 'Kategoria przeznaczona dla tenisistów początkujących bez przeszłości zawodniczych, ( udziału w tej kategorii nie mogą brać trenerzy oraz instruktorzy).';
      case 4: return 'Kategoria przeznaczona dla osób powyżej 40 roku życia bez przeszłości zawodniczej, oraz trenerów i instruktorów bez przeszłości zawodniczej.';
      case 5: return 'Kategoria przeznaczona dla wszystkich powyżej 50 roku życia.';
      case 6: return 'Kategoria przeznaczona dla graczy z przeszłością zawodniczą bądź dla czynnych, a także dla zawodników bez przeszłości zawodniczej.';
      case 7: return 'Kategoria przeznaczona dla osób bez przeszłości zawodniczej, ( zawodniczki które grały do 14 roku życia włącznie mają prawo zagrać w tej kategorii), trenerki i instruktorki udziału brać nie mogą';
      case 8: return 'Kategoria w której przynajmniej jedna zawodniczka z pary jest amatorem.';
      case 9: return 'Kategoria w której przynajmniej jeden zawodnik z pary jest amatorem.';
      case 10: return 'Kategoria w której przynajmniej jeden zawodnik z pary jest amatorem.';
    }
  }
}

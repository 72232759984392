import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {
    AutomationAction,
    ReservationIndividual,
    ReservationRepeatable,
    UserReservation,
    UserReservationRepeatable
} from '@model/reservation/UserReservation';
import * as moment from 'moment';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {AppStateService} from '@core/state/app-state.service';
import {ToastrService} from 'ngx-toastr';
import {isHourBetweenOrSame} from '@shared/utils/isHourBetweenOrSame';

export interface ReservationAutomationAction {
    reservationId: string;
    actions: AutomationAction[];
}

@Component({
    selector: 'app-dashboard-reservations-rep',
    template: `
        <div class="no-data-title" *ngIf="!data.length">
            Brak rezerwacji do wyświetlenia.
        </div>
        <div class="reservations-rep" *ngIf="data.length" [class.history]="history">
            <div class="pb-20" *ngFor="let reservation of data; index as i" [class.border-bottom-light]="i !== 0">
                <div class="animated fadeIn pt-20" style="display: flex; align-items: center;justify-content: space-between;">

                    <div>
                        <p class="mb-0" style="font-size: 14px; font-weight: 600;">{{ reservation.court_name }}</p>
                        <div>{{ reservation.dayString }} <em *ngIf="reservation.lighting" 
                                style="font-size: 1.1em" class="ion-android-bulb"></em></div>
                        <div class="text-muted">{{ reservation.datesBetweenString }}</div>
                        
                        <div *ngIf="reservation.price !== null" class="color-blue-500">cena: {{ reservation.price.toFixed(2) }} zł</div>
                        <div *ngIf="reservation.price !== null" class="text-success"><span
                                [class.text-success]="reservation.amount_paid === reservation.price"
                                [class.color-amber-600]="reservation.amount_paid < reservation.price"
                        >zapłacono: {{ reservation.amount_paid.toFixed(2) }} zł</span></div>
                        
                        <div *ngIf="reservation.coach" class="color-blue-500">trener: {{ reservation.coach.name }}</div>
                    </div>

                    <div *ngIf="reservation.coach">
                        <app-avatar [userId]="reservation.coach.id" src="{{reservation.coach.picture_miniature}}"
                                    size="42"></app-avatar>
                    </div>

                    <div *ngIf="automationAction && reservation.reservations[0].automation_actions.length"
                         (click)="activeReservationAction === i ? activeReservationAction = null : activeReservationAction = i"
                         class="text-center">
                        <button style="border-radius: 50%" class="btn btn-sm bg-blue-600">
                            <i *ngIf="activeReservationAction !== i" class="fa fa-chevron-down" style="width: 11px; margin-left: -2px;"></i>
                            <i *ngIf="activeReservationAction === i" class="fa fa-chevron-up" style="width: 11px; margin-left: -2px;"></i>
                        </button>
                        <div>Akcje</div>
                    </div>

                    <button class="btn btn-sm" (click)="openModal(reservation)" style="border-radius: 50%;">
                        <i class="fa fa-arrow-right"></i>
                    </button>

                </div>
                <div *ngIf="activeReservationAction === i && reservation.automation_actions_with_id && reservation.automation_actions_with_id.actions.length" class="actions">
                    <app-reservation-automation-actions-based-on-buttons
                            [automationAction]="reservation.automation_actions_with_id"
                            (actionExecuted)="onRefreshReservations.emit()"
                    ></app-reservation-automation-actions-based-on-buttons>
                </div>
            </div>
        </div>
        
        <!--modal-->
        <app-reservations [selected]="selected" (onModalClose)="selected = null" [mobile]="isMobile"></app-reservations>
        <!--modal-->
    `,
    styleUrls: ['./../dashboard.component.scss']
})
export class DashboardReservationsRepComponent implements OnDestroy {
    public data: ReservationRepeatable[] = [];
    selected: ReservationRepeatable;
    public activeReservationAction = null;

    constructor(private service: MyReservationsService,
                private toastr: ToastrService) {
    }

    _reservations: ReservationRepeatable[] = [];

    get reservations() {
        return this._reservations;
    }

    public automationAction: ReservationAutomationAction = null;
    private interval = null;

    @Input() isMobile: boolean;
    @Input() history = false;
    @Output() onRefreshReservations = new EventEmitter();

    @Input('reservations')
    set reservations(value: ReservationRepeatable[]) {
        this._reservations = value;
        if (value) {
            // console.log('repReservations', value);
            this.prepareReservations(value);
            if (this.selected && value.find(r => r.id === this.selected.id)) {
            }
        }

        // clearInterval(this.interval);
        // this.startInterval();
    }

    ngOnDestroy(): void {
        // clearInterval(this.interval);
    }

    repeatableDateFormat(reservation: UserReservationRepeatable): string {
        const start = moment(reservation.starts_at).locale('pl');
        const end = moment(reservation.ends_at).locale('pl');

        return start.format('dddd, HH:mm') + '-' + end.format('HH:mm');
    }

    compareFn(first, second) {
        return <any>new Date(first.starts_at) - <any>new Date(second.starts_at);
    }

    repeatableDateModal(reservations: UserReservationRepeatable[]): string {
        const sorted = reservations.sort(this.compareFn);

        const start = moment(sorted[0].starts_at).locale('pl');
        const end = moment(sorted[sorted.length - 1].ends_at).locale('pl');

        return start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY');
    }

    repeatableDatesBetweenShort(reservations: UserReservationRepeatable[]): string {
        const sorted = reservations.sort(this.compareFn);

        const start = moment(sorted[0].starts_at);
        const end = moment(sorted[sorted.length - 1].ends_at);

        return start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY');
    }

    mapRepReservations(reservations: UserReservationRepeatable[]): UserReservationRepeatable[] {
        if (reservations.length > 0) {
            this.automationAction = {
                reservationId: reservations[0].id,
                actions: reservations[0].automation_actions
            };
        }
        return reservations
            .map(item => {
                item.dayString = this.repeatableDateFormat(item);
                item.datesBetweenString = this.repeatableDateModal(reservations);
                return item;
            });
    }

    private findActiveRepReservationForAutomationAction(reservations: UserReservationRepeatable[]): void {
        reservations.forEach(r => {
            const startMinus10minutes: string = moment(r.starts_at).subtract(10, 'minutes').format();

            if (isHourBetweenOrSame(moment().format(), startMinus10minutes, r.ends_at)) {
                this.automationAction = {
                    reservationId: r.id,
                    actions: r.automation_actions
                };
            }
        });
    }

    openModal(reservation: ReservationRepeatable) {
        this.selected = null;
        this.selected = reservation;
    }

    private prepareReservations(reservations: ReservationRepeatable[]): void {
        const sorted = reservations.sort(this.compareFn);

        this.data = [];
        sorted.forEach(r => {
            if (!r.reservations.length) {
                return;
            }
            this.data.push({
                court_name: r.reservations[0].court_name,
                id: r.id,
                amount_paid: r.amount_paid,
                price: r.price,
                status: r.status,
                dayString: this.repeatableDateFormat(r.reservations[0]),
                datesBetweenString: this.repeatableDatesBetweenShort(r.reservations),
                station_name: r.station_name,
                reservations: this.mapRepReservations(r.reservations).sort(this.compareFn),
                coach: r.coach,
                lighting: r.reservations[0].lightning,
                automation_actions_with_id: {
                    reservationId: r.reservations[0].id,
                    actions: r.reservations[0].automation_actions
                }
            });
            // this.findActiveRepReservationForAutomationAction(r.reservations);
        });
    }

    //
    // automation(reservationId: string, automationAction: AutomationAction) {
    //     if (!automationAction.available) {
    //         return;
    //     }
    //     this.service.pushAutomation(reservationId, automationAction.id).subscribe(
    //         next => {
    //             this.toastr.success('Ok');
    //         }, () => this.toastr.error('Niepowodzenie')
    //     );
    // }

    // private startInterval() {
        // this.interval = setInterval(() => {
        //     this.data.forEach(group => {
        //         this.findActiveRepReservationForAutomationAction(group.reservations);
        //     });
        // }, 60000);
    // }
}

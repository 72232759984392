import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReservationIndividual, ReservationRepeatable, UserReservation, UserReservationRepeatable} from '@model/reservation/UserReservation';
import {swalSuccess} from '@shared/swal/swalSuccess';
import {swalError} from '@shared/swal/swalError';
import swalConfirm from '@shared/swal/confirm';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import * as moment from 'moment';
import {AppStateService} from '@core/state/app-state.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {generatePaymentLink} from '@shared/utils/generatePaymentLink';
import {PaymentTpay} from '@model/tournament/tournament-details';

@Component({
    selector: 'app-reservations',
    templateUrl: './reservations.component.html',
    styleUrls: ['../dashboard/dashboard.component.scss']
})
export class ReservationsComponent {
    MODAL_ID = 'reservationsModal';
    public isMobile = false;
    private modalCloseSubscription$ = null;

    @Input('mobile')
    set mobile(val) {
        this.MODAL_ID = val ? 'reservationsModalMobile' : 'reservationsModal';
        this.isMobile = val;
    }

    private _selected: ReservationRepeatable;

    get selected() {
        return this._selected;
    }

    @Input('selected')
    set selected(selected: ReservationRepeatable) {
        this._selected = null;
        this._selected = selected;
        if (!selected) {
            try {
                this.hideSidebar();
            } catch (exception) {

            }
        }
        if (selected) {
            this.showSidebar();
        }
    }

    @Output() onModalClose = new EventEmitter();

    constructor(private service: MyReservationsService,
                private toastr: ToastrService,
                private modalService: NgxSmartModalService,
                private appState: AppStateService) {
    }

    showSidebar() {
        this.modalService.getModal(this.MODAL_ID).open();
        this.subscribeToCloseModal();
    }

    subscribeToCloseModal() {
        if (this.modalCloseSubscription$) {
            this.modalCloseSubscription$.unsubscribe();
        }
        this.modalCloseSubscription$ = this.modalService.getModal(this.MODAL_ID).onAnyCloseEvent.subscribe(() => {
            this.hideSidebar();
        });
    }

    hideSidebar() {
        this.onModalClose.emit();
        this.modalService.getModal(this.MODAL_ID).close();
    }

    cancel(reservation: UserReservationRepeatable, repeatable = false) {
        const that = this;
        const additionalText = repeatable ? ' cykliczną' : '';
        const paymentText = reservation.payment_status === 'ONLINE_PAID' ? `<br>Poniesiona opłata zostanie zwrócona automatycznie na konto do 4 dni roboczych z potrąceniem kosztów serwisowych wynoszących 2%</p>` : '';
        // const paymentText = '';

        swalConfirm(
            'Anulowanie rezerwacji',
            () => {
                that.cancelReservation(reservation, repeatable);
            },
            `<p>Czy na pewno chcesz odwołać rezerwację${additionalText}?${paymentText}</p>`
        );
    }

    dateFormat(reservation: UserReservationRepeatable): string {
        const start = moment(reservation.starts_at).locale('pl');
        return start.format('D MMMM YYYY');
    }

    private cancelReservation(reservation: UserReservationRepeatable, repeatable) {
        this.service.cancelReservation(reservation.id)
            .subscribe(() => {
                this.toastr.success('Rezerwacja odwołana');
                this.appState.reservationUpdate();
                this.appState.closeReservationModal();
                this.selected = null;
            }, err => {
                let message = err.error.status;
                switch (err.error.status) {
                    case 'WRONG_CODE':
                        message = 'Wystąpił błąd';
                        break;
                    case 'ALREADY_CANCELED':
                        message = 'Ta rezerwacja jest już odwołana';
                        break;
                    case 'CANNOT_CANCEL':
                        message = 'Rezerwację można odwołać do ' + err.error.max_hours + ' godzin przed rozpoczęciem gry';
                        break;
                }
                swalError({
                    title: 'Niepowodzenie',
                    text: message
                });
            });
    }

    payForReservation(reservation: UserReservationRepeatable) {
        const payment = new PaymentTpay(
            reservation.tpay_payment.amount,
            reservation.tpay_payment.client_email,
            reservation.tpay_payment.client_name,
            reservation.tpay_payment.crc,
            reservation.tpay_payment.description,
            reservation.tpay_payment.id,
            reservation.tpay_payment.md5_code,
            reservation.tpay_payment.result_url,
        );
        const tpayUrl = generatePaymentLink(payment, window.location.href);

        window.location.href = tpayUrl;
    }
}

import {Component, Input} from '@angular/core';
import {CourtDetail} from '@model/court/CourtDetail';
import {AppStateService} from '@core/state/app-state.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent {

    @Input() courtData: CourtDetail;
    @Input() withoutBorder = false;
    @Input() withoutLogoSection = false;

    private colors = {
        active: '#00d285',
        inactive: '#b7b7b8',
        flooded: '#ffe02d'
    };

    constructor(private appState: AppStateService) {
    }

    dynamicSort(property) {
        return function (a, b) {
            const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * -1;
        };
    }

    stationTypes(): string[] {
        return this.courtData.stations
            .map(item => item.type_name)
            .filter((x, i, a) => a.indexOf(x) === i)
            .sort(this.dynamicSort('enabled'));
    }

    stationBalls(type: string): string[] {
        const stations = this.courtData.stations.filter(item => item.type_name === type).sort(this.dynamicSort('enabled'));
        const colors = [];

        for (let i = 0; i < stations.length; i++) {
            const item = stations[i];
            let color = '';
            if (item.enabled) {
                color = this.colors.active;
            }
            if (!item.enabled) {
                color = this.colors.inactive;
            }
            if (item.flooded) {
                color = this.colors.flooded;
            }
            colors.push(color);
        }

        return colors;
    }

    openCourtTournamentsModal() {
        this.appState.openCourtTournaments(this.courtData.id);
    }
}

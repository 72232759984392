import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '@core/authentication/authentication.service';
import {AppStateService} from '@core/state/app-state.service';
import {FavoriteCourtsService} from '@services/shared/favorite-courts.service';

import {SettingsService} from '@shared/settings/settings.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss', './layout-variants.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {

    mapView = false;

    constructor(private httpService: HttpClient,
                private state: AppStateService,
                private auth: AuthenticationService,
                private settings: SettingsService,
                private courtDetailService: FavoriteCourtsService,
                private route: Router) {
    }

    ngOnInit() {
        this.mapView = this.route.url.includes('map');
        this.route.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.mapView = event.url.includes('map');
                }
            }
        );
    }

    layout() {
        return [

            this.settings.app.sidebar.visible ? 'sidebar-visible' : '',
            this.settings.app.sidebar.offcanvas ? 'sidebar-offcanvas' : '',
            this.settings.app.sidebar.offcanvasVisible ? 'offcanvas-visible' : ''

        ].join(' ');
    }

    closeSidebar() {
        this.settings.app.sidebar.visible = false;
    }
}

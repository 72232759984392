<div class="animated fadeIn" style="text-align: center">
    <div class="form-group">
        <label for="miniatureInput">Avatar</label>
        <img loading="lazy" *ngIf="!miniatureEdit && !!user" src="{{user.picture}}"/>

        <ngx-dropzone *ngIf="miniatureEdit" id="miniatureInput" (change)="onSelect($event)" [multiple]="false">
            <ngx-dropzone-label>Dodaj zdjęcie metodą Drag & Drop lub wciśnij aby wybrać
                <div>
                    <small class="text-muted">Maksymalny rozmiar zdjęcia to 10MB. Preferowany format to PNG</small>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>

        <button (click)="miniatureEdit = true" *ngIf="!showminiatureSave && !miniatureEdit"
                class="btn btn-sm btn-primary">zmień
        </button>
        <button (click)="miniatureEdit = false; showminiatureSave = false" *ngIf="miniatureEdit === true"
                class="btn btn-sm btn-primary">anuluj
        </button>
        <button (click)="changeMiniature()" *ngIf="showminiatureSave" class="btn btn-sm btn-success">wyślij
        </button>
        <button (click)="removeMiniature()" *ngIf="!miniatureEdit" class="btn btn-sm btn-danger">ustaw
            domyślny
        </button>
    </div>
</div>

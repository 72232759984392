import {Pipe, PipeTransform} from '@angular/core';
import {Hour} from '@model/reservation/hour';

@Pipe({
    name: 'hourPipe'
})
export class DisplayHourPipe implements PipeTransform {
    transform(value: Hour, args: any[] = []) {
        const minute: String = value.minute > 9 ? (value.minute).toString() : '0' + value.minute;
        return value.hour + ':' + minute;
    }
}


import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserBasicDataDto} from '@model/user/UserBasicDataDto';
import {API_HOST} from '../../consts';
import {Whoami} from '@core/authentication/whoami';
import {Opponent} from '@model/opponent/opponent';

@Injectable()
export class UserPanelService {

    constructor(private http: HttpClient) {
    }

    whoAmI(): Observable<Whoami> {
        return this.http.get<Whoami>(`${API_HOST}/whoami`);
    }

    stats(userId: number): Observable<Opponent> {
        return this.http.get<Opponent>(`${API_HOST}/opponent/${userId}/stats`);
    }

    changePassword(old_password: string, new_password: string): Observable<any> {
        return this.http.post(`${API_HOST}/user/password`, {old_password, new_password});
    }

    modifyBasicData(dto: UserBasicDataDto): Observable<any> {
        return this.http.put(`${API_HOST}/user/basicData`, dto);
    }

    removeMiniature(): Observable<any> {
        return this.http.delete(`${API_HOST}/user/picture`);
    }

    updateMiniature(body): Observable<any> {
        return this.http.put(`${API_HOST}/user/picture`, body);
    }

    sendComplaint(body): Observable<any> {
        return this.http.post(`${API_HOST}/complaint`, {complaint: body});
    }

    setVisaCardNumber(code: string): Observable<any> {
        return this.http.post(`${API_HOST}/user/voucher`, {code});
    }
}

import {PaymentPrzelewy24} from '@model/reservation/paymentPrzelewy24';
import {API_HOST} from '../../consts';

export const generatePrzelewy24PaymantLink = (paymentToken: string) => {

  const isProdEnv = API_HOST.includes('api.tenis4u.pl');
  const url = isProdEnv
      ? `https://secure.przelewy24.pl/trnRequest/${paymentToken}`
      : `https://secure.przelewy24.pl/trnRequest/${paymentToken}`;
      // : `https://sandbox.przelewy24.pl/trnRequest/${paymentToken}`;

  const redirectUrl = `${url}`;

  console.log('payment redirect url', redirectUrl);
  return redirectUrl;
};

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-card-title',
  template: `
    <div class="title">
      {{title}}
      <div *ngIf="!withoutButton" class="float-right dropdown">
        <button class="btn btn-secondary btn-flat btn-flat-icon ripple" type="button"
                data-toggle="dropdown" aria-expanded="false">
          <em class="ion-android-more-vertical"></em>
          <span class="md-ripple" style="width: 0; height: 0; margin-top: -16px; margin-left: -16px;"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" role="menu" style="">
          <a [class.bg-blue-grey-50]="active" (click)="changeState(true)" class="dropdown-item">Aktualne</a>
          <a [class.bg-blue-grey-50]="!active" (click)="changeState(false)" class="dropdown-item">{{historyTitle}}</a>
        </div>
      </div>
    </div>
  `,
  styles: [`
      .dropdown-item {
          padding: 0 14px;
          line-height: 48px;
          display: block;

          width: 100%;
          clear: both;
          font-weight: 400;
          color: #212529;
          text-align: inherit;
          white-space: nowrap;
          border: 0;
          font-size: 14px;
      }
      
      .dropdown-item:hover {
          cursor: pointer;
          text-decoration: none;
          background-color: #eceff1;
      }
  `],
  styleUrls: ['../../dashboard.component.scss']
})
export class CardTitleComponent {

  active = true;
  @Input() title: string;
  @Input() withoutButton = false;
  @Input() historyTitle = 'Przeszłe';
  @Output() stateChanged = new EventEmitter<boolean>();

  changeState(isActive = false) {
    this.active = isActive;
    this.stateChanged.emit(isActive);
  }
}

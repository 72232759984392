export class MapTournament {

    constructor(id: number, name: string, status: string, max_players: number, reserve_players: number, players: number, end_of_applications: string, court_name: string, type: string, start_date: string) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.max_players = max_players;
        this.reserve_players = reserve_players;
        this.players = players;
        this.end_of_applications = end_of_applications;
        this.court_name = court_name;
        this.type = type;
        this.start_date = start_date;
    }

    id: number;
    name: string;
    status: string; // CREATED, STARTED, FINISHED
    max_players: number;
    reserve_players: number;
    players: number;
    end_of_applications: string;
    court_name: string;
    court_tournaments: number;
    type: string;
    start_date: string;
}


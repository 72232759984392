import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TournamentService} from '@services/tournament/tournament.service';
import {UserBasicDto} from '@model/UserBasicDto';

export interface CourtTournament {
  active: CourtTournamentActive[];
  finished: CourtTournamentFinished[];
}

interface CourtTournamentFinished {
  ends_date: string;
  id: number;
  name: string;
  type: string; //['SINGLE_CUP', 'CONSOLATION_CUP', 'PLAYOFF_CUP', 'LEAGUE']
  winner: UserBasicDto;
}

interface CourtTournamentActive {
  end_of_applications: string;
  ends_date: string;
  id: number;
  max_players: number;
  name: string;
  players: number;
  reserve_players: number;
  start_date: string;
  status: string; //['CREATED', 'APPLICATIONS_CLOSED', 'GENERATED', 'STARTED', 'FINISHED', 'DELETED'],
  type: string; //['SINGLE_CUP', 'CONSOLATION_CUP', 'PLAYOFF_CUP', 'LEAGUE']
}

@Component({
  selector: 'app-court-tournaments-modal',
  templateUrl: './court-tournaments-modal.component.html',
  styleUrls: ['./court-tournaments-modal.component.scss']
})
export class CourtTournamentsModalComponent implements OnInit {
  public data: CourtTournament;

  public tab = 'active';
  @Output() onTournamentClick = new EventEmitter();

  constructor(private route: ActivatedRoute,
              private tournamentService: TournamentService,
              private router: Router) {
  }

  private _courtId: number;

  get courtId(): number {
    return this._courtId;
  }

  @Input('courtId')
  set courtId(value: number) {
    if (value) {
      this._courtId = value;
      this.loadCourtTournaments();
    }
  }

  ngOnInit(): void {
    this.loadCourtTournaments();
  }

  goto(id: number) {
    this.router.navigate([`tournament/${id}`]);
    this.onTournamentClick.emit(true);
  }

  private loadCourtTournaments() {
    this.tournamentService.getCourtTournaments(this.courtId).subscribe(next => {
      this.data = next;
      this.data.finished.sort((a, b) => new Date(a.ends_date) >= new Date(b.ends_date) ? -1 : 1);
    });
  }
}

import {PaymentTpay} from '@model/tournament/tournament-details';
import {PaymentDotpay} from '@model/reservation/paymentDotpay';
import {generateTpayPaymentLink} from '@shared/utils/generateTpayPaymentLink';
import {generateDotpayPaymentLink} from '@shared/utils/generateDotpayPaymantLink';
import {generatePrzelewy24PaymantLink} from '@shared/utils/generatePrzelewy24PaymantLink';
import {PaymentPrzelewy24} from '@model/reservation/paymentPrzelewy24';

export const generatePaymentLink = (dto: PaymentTpay | PaymentDotpay | PaymentPrzelewy24, customReturnUrl?: string): string | null => {
    if (!dto) {
        return null;
    }
    if (dto instanceof PaymentTpay) {
        return generateTpayPaymentLink(<PaymentTpay>dto, customReturnUrl);
    }
    if (dto instanceof PaymentDotpay) {
        return generateDotpayPaymentLink(<PaymentDotpay>dto);
    }
    if (dto instanceof PaymentPrzelewy24) {
        return generatePrzelewy24PaymantLink(dto.przelewy24_token);
    }

    return null;
};

import {IconUrl} from './IconUrl';
import {MapCourt} from './MapCourt';
import {MapTournament} from './MapTournament';
import LatLngLiteral = google.maps.LatLngLiteral;

declare var google: any;

export enum MarkerType {
  court,
  tournament
}

export class MapMarker {
  type: MarkerType;
  id: number;
  court?: MapCourt;
  tournament?: MapTournament;
  lat: number;
  lng: number;
  iconUrl: google.maps.Icon | IconUrl;
  thumb: string;
  multisport: boolean;
  position: LatLngLiteral;


  constructor(
    type: MarkerType,
    id: number,
    court: MapCourt = null,
    tournament: MapTournament = null,
    lat: number,
    lng: number,
    iconUrl: IconUrl,
    thumb: string,
    multisport: boolean
  ) {
    this.type = type;
    this.id = id;
    this.court = court;
    this.tournament = tournament;
    this.lat = lat;
    this.lng = lng;
    this.iconUrl = {
      url: iconUrl.url,
      scaledSize: new google.maps.Size(iconUrl.scaledSize.width, iconUrl.scaledSize.height)
    };
    this.thumb = thumb;
    this.multisport = multisport;
    this.position = {lat, lng};
  }
}


<div class="card" style="margin-bottom: 0; border-bottom: 0">
  <div *ngIf="data" class="card-body" style="padding: 8px;">

    <div class="panel panel-default" style="margin-top: 30px;">
      <div class="panel-heading">
        <div class="panel-title">Aktualne</div>
      </div>
      <div class="panel-body" style="padding: 0;">
        <h6 class="empty-list" *ngIf="data.active.length === 0">Lista jest pusta</h6>
        <div class="mda-list mda-list-bordered" *ngIf="data.active.length > 0">
          <div class="mda-list-item animated fadeIn"
               *ngFor="let active of data.active"
               (click)="goto(active.id)">
            <div class="mda-list-item-text mda-2-line" style="padding: 13px 0;">
              <div class="mda-list-item-text">
                <div class="card-title" style="font-size: 16px; margin-bottom: 3px;">{{active.name}}</div>
                <div class="color-grey-500 text-uppercase"
                     style="margin-bottom: 10px; font-size: 12px;">{{active.type | tournamentTypePipe}}</div>
                <div>Start: {{active.start_date}}</div>
                <div>Liczba graczy: {{active.players}} <span
                    *ngIf="active.reserve_players > 0">(rez.: {{active.reserve_players}})</span></div>
                <div>Zapisy do: {{active.end_of_applications | momentDate:'YYYY-MM-DD HH:mm'}}</div>
              </div>
            </div>
            <div class="tournament-status">
              <div style="width: 100%; text-align: center">
              <app-tournament-status [status]="active.status"></app-tournament-status>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default" style="margin-top: 30px;">
      <div class="panel-heading">
        <div class="panel-title">Zakończone</div>
      </div>
      <div class="panel-body" style="padding: 0;">
        <h6 class="empty-list" *ngIf="data.finished.length === 0">Lista jest pusta</h6>
        <div class="mda-list mda-list-bordered" *ngIf="data.finished.length > 0">
          <div class="mda-list-item animated fadeIn"
               *ngFor="let finished of data.finished"
               (click)="goto(finished.id)">
            <div class="mda-list-item-text mda-2-line" style="padding: 13px 0;">
              <div class="mda-list-item-text">
                <div style="margin-bottom: 10px;"><span
                    class="badge bg-grey-200" style="color: #4F5256;">zakończony: {{finished.ends_date}}</span></div>
                <div class="card-title" style="font-size: 16px; margin-bottom: 3px;">{{finished.name}}</div>
                <div class="color-grey-500 text-uppercase"
                     style="margin-bottom: 10px; font-size: 12px;">{{finished.type | tournamentTypePipe}}</div>
              </div>
            </div>
            <div class="tournament-status winner">
              <div class="text-center">
                <app-avatar [userId]="finished.winner.id" [src]="finished.winner.picture"></app-avatar>
                <div style="font-size: 13px; font-weight: 600;">{{finished.winner.name}}</div>
                <div style="font-size: 12px; font-weight: 600;" class="color-green-500">zwycięzca</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import {ToastrService} from 'ngx-toastr';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthenticationService} from '@core/authentication/authentication.service';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
    recoverPassEmail = '';

    mailSent = false;
    recForm: FormGroup;

    constructor(fb: FormBuilder,
                private toastr: ToastrService,
                private authenticationService: AuthenticationService,
                private authRepository: AuthRepository) {

        this.recForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])]
        });

    }

    submitForm($ev, form: FormGroup) {
        $ev.preventDefault();
        let value = form.value;
        for (let c in form.controls) {
            form.controls[c].markAsTouched();
        }
        this.recoverPassEmail = value.email;
        this.recoverPassword();
    }

    ngOnInit() {
        this.authenticationService.logout(false);
    }

    recoverPassword() {
        this.authRepository.recoverPassword(this.recoverPassEmail)
            .subscribe(
                next => this.mailSent = true,
                err => {
                    const message = err.error.message;
                    if (message.indexOf('not found') !== -1) {
                        this.toastr.error('Podano błędny adres e-mail');
                    }
                }
            );
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import {Observable} from 'rxjs/Observable';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {API_HOST} from '../../consts';
import {catchError, tap} from 'rxjs/operators';
import {clearToken} from '@shared/utils/token';

@Injectable()
export class AppLoadService {

  constructor(private httpClient: HttpClient,
              private authRepository: AuthRepository
  ) {
  }

  initializeApp(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  refreshToken(): Promise<any> {
    if (!this.authRepository.userExist()) {
      localStorage.removeItem(`${API_HOST}-app-load-user-exist`);
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    return this.authRepository.whoAmI().pipe(
      tap(() => localStorage.setItem(`${API_HOST}-app-load-user-exist`, 'true')),
      tap(() => {
        if (window.location.hash === '') {
          localStorage.setItem(`${API_HOST}-app-load-redirect-dashboard`, 'true');
        }
      }),
      catchError(error => {
        if (error.status === 401) {
          clearToken();
          localStorage.removeItem(`${API_HOST}-app-load-user-exist`);
          return new Promise<void>((resolve) => {
            resolve();
          });
        }
      })
    ).toPromise();
  }
}

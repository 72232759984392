import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CustomValidators} from 'ng2-validation';
import {ToastrService} from 'ngx-toastr';
import {AuthRepository} from '@core/authentication/auth-repository.service';
import {AuthenticationService} from '@core/authentication/authentication.service';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

    passwordChanged = false;
    token: string;
    regForm: FormGroup;

    constructor(fb: FormBuilder,
                private toastr: ToastrService,
                private authenticationService: AuthenticationService,
                private authRepository: AuthRepository,
                private route: ActivatedRoute) {

        const loginPassword = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
        const loginPasswordConfirm = new FormControl('', CustomValidators.equalTo(loginPassword));

        this.regForm = fb.group({
            'password': loginPassword,
            'confirmPassword': loginPasswordConfirm
        });

    }

    submitForm($ev, form: FormGroup) {
        this.passwordChanged = false;
        $ev.preventDefault();
        let value = form.value;
        for (let c in form.controls) {
            form.controls[c].markAsTouched();
        }

        this.resetPassword(value.password);
    }

    ngOnInit() {
        this.authenticationService.logout(false);
        this.route.params.subscribe(
            params => {
                this.token = params.token;
            }
        );
    }

    resetPassword(password: string) {
        this.authRepository.resetPassword(password, this.token)
            .subscribe(
                next => this.passwordChanged = true,
                err => {
                    const message = err.error.message;
                    if (message.indexOf('not found') !== -1) {
                        this.toastr.error('Zmiana hasła nie powiodła się');
                    }
                }
            );
    }
}

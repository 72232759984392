import * as moment from 'moment';
import {ReservationDetails} from '../reservation/reservation-details';

export class TournamentReservation {
    ends_at: string;
    match_id: number;
    starts_at: string;
    station_id: number;
    payment_method: string;
    tariff_id: number;
    lightning: boolean;
    multisport_cards: number;
    przelewy24_return_url: string;
    // przelewy24_sdk = null;
    message = '';

    constructor(reservation: ReservationDetails, matchId: number, paymentId: string) {
        // this.ends_at = reservation.;
        this.ends_at = moment(reservation.day)
            .hours(reservation.end.hour)
            .minutes(reservation.end.minute)
            .seconds(0)
            .millisecond(0)
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        this.starts_at = moment(reservation.day)
            .hours(reservation.begin.hour)
            .minutes(reservation.begin.minute)
            .seconds(0)
            .millisecond(0)
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        this.match_id = matchId;
        this.station_id = reservation.stationId;
        this.payment_method = reservation.payment_method;
        this.tariff_id = reservation.tariff_id;
        this.lightning = reservation.lighting;
        this.multisport_cards = reservation.multisport;
        this.przelewy24_return_url = `${window.location.origin}/#${localStorage.getItem('redirectTournament')}?p24success=true&paymentId=${paymentId}`;
        // this.message = reservation.message || null;
    }
}

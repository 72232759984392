import {Pipe, PipeTransform} from '@angular/core';
import {Bracket} from '@model/tournament/bracket';

@Pipe({
    name: 'scorePipe'
})
export class ScorePipe implements PipeTransform {
    transform(bracket: Bracket, set: number, inverse = false) {

        let score1: number | string;
        let score2: number | string;

        switch (set) {
            case 1:
                score1 = bracket.left_player_first_set_result;
                score2 = bracket.right_player_first_set_result;
                break;
            case 2:
                score1 = bracket.left_player_second_set_result;
                score2 = bracket.right_player_second_set_result;
                break;
            case 3:
                score1 = bracket.left_player_third_set_result;
                score2 = bracket.right_player_third_set_result;
                break;
        }

        if (score1 === 0 && score2 === 0) {
            return '';
        }

        if (score1 === -1) {
            score1 = 'W';
        }
        if (score2 === -1) {
            score2 = 'W';
        }

        return inverse
          ? `${score2} : ${score1}`
          : `${score1} : ${score2}`;
    }
}

<div class="pricing card animated fadeIn">
    <div class="card-heading animated fadeIn bg-white">
        <div class="card-title">Cennik</div>
    </div>


    <div>
        <div class="card-heading animated fadeIn bg-white mb-5">
            <div class="card-title">
                <button *ngFor="let tab of _pricing"
                        class="btn btn-round btn-white mr-5"
                        (click)="setActiveStation(tab)"
                        [class.btn-primary]="stationIsActive(tab)"
                        type="button">
                    {{tab.name}}
                </button>
            </div>
        </div>
        <div class="card-body p0" *ngIf="activeStation" style="margin: 8px 0;">
            <div class="card-heading bg-white animated fadeIn" style="margin: 8px 0;" *ngIf="activeStation.lighting_price !== null">
                <div class="card-title flex-center lighting-section">
                    <span class="icon"><em class="ion-ios-lightbulb-outline"></em></span>
                    <span class="title">Oświetlenie płatne dodatkowo</span>
                    <span class="title color-blue-500 ">+ {{activeStation.lighting_price}} zł</span>
                </div>
            </div>
            <div class="card" [class.outdated]="dateOutOfBounds(tariff)" *ngFor="let tariff of activeStation.tariffs.filter(enabledOnly).sort(bySequenceNumber);" style="border: 0;">
                <div class="card-heading">
                    <div class="card-title">{{tariff.name}}</div>
                    <small>{{tariff.description}}</small>
                    <div><small *ngIf="tariff.starts_at && tariff.ends_at">{{tariff.starts_at}} - {{tariff.ends_at}}</small></div>
                </div>
                <div class="card-body p0">
                    <div class="animated fadeIn">
                        <div *ngIf="prices(tariff.prices, true).length > 0">
                            <div class="btn btn-header animated fadeIn">Dni zwykłe</div>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="text-center" style="width: 15%;">od</th>
                                    <th class="text-center" style="width: 15%;">do</th>
                                    <th class="text-center" style="width: 35%;">cena / 1h</th>
                                    <th class="text-center" style="width: 35%;">last minute <i
                                            tooltip="Cena dla rezerwacji dokonanej 59 minut przed jej terminem"
                                            class="text-info fa fa-info-circle"></i></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let price of prices(tariff.prices, true)">
                                    <td>{{price.start | priceHour}}</td>
                                    <td>{{price.end | priceHour}}</td>
                                    <td>
                                        <span [class.color-blue-500]="!dateOutOfBounds(tariff)">{{price.cost | priceCost}}</span>
                                    </td>
                                    <td>
                                        <div *ngIf="price.last_minute_cost !== null" [class.color-red-500]="!dateOutOfBounds(tariff)">
                                            {{price.last_minute_cost | priceCost}}
                                        </div>
                                        <span *ngIf="price.last_minute_cost === null">-</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="prices(tariff.prices, false).length > 0">
                            <div class="btn btn-header animated fadeIn">Weekend</div>
                            <table class="table">
<!--                                <thead>-->
<!--                                <tr>-->
<!--                                    <th class="text-center" style="width: 15%;">od</th>-->
<!--                                    <th class="text-center" style="width: 15%;">do</th>-->
<!--                                    <th class="text-center" style="width: 35%;">cena / 1h</th>-->
<!--                                    <th class="text-center" style="width: 35%;">last minute <i-->
<!--                                            tooltip="Cena dla rezerwacji dokonanej 59 minut przed jej terminem"-->
<!--                                            class="text-info fa fa-info-circle"></i></th>-->
<!--                                </tr>-->
<!--                                </thead>-->
                                <tbody>
                                <tr *ngFor="let price of prices(tariff.prices, false)">
                                    <td style="width: 15%;">{{price.start | priceHour}}</td>
                                    <td style="width: 15%;">{{price.end | priceHour}}</td>
                                    <td style="width: 35%;">
                                        <span [class.color-blue-500]="!dateOutOfBounds(tariff)">{{price.cost | priceCost}}</span>
                                    </td>
                                    <td style="width: 35%;">
                                        <div *ngIf="price.last_minute_cost !== null" [class.color-red-500]="!dateOutOfBounds(tariff)">
                                        {{price.last_minute_cost | priceCost}}
                                        </div>
                                        <span *ngIf="price.last_minute_cost === null">-</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

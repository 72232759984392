import * as moment from 'moment';
import {API_HOST} from '../../../../../consts';

interface ReservationPaymentRedirectState {
    url: string;
    host: string;
    createdAt: string;
    urlFragment: string;
    reservationCode: string;
    paymentId: string;
}

export const saveRedirectStateForOnlinePayment = (reservationCode: string, paymentId: string): void => {
    const data: ReservationPaymentRedirectState = {
        createdAt: moment().format(),
        host: window.location.host,
        url: window.location.href,
        urlFragment: window.location.hash.substring(1),
        reservationCode,
        paymentId,
    };

    localStorage.setItem(`paymentId-${paymentId}`, JSON.stringify(data));
};

export const loadRedirectStateForOnlinePayment = (paymentId: string): ReservationPaymentRedirectState | null => {
    const item = localStorage.getItem(`paymentId-${paymentId}`);
    if (!item) {
        return null;
    }

    return JSON.parse(item);
};

export const clearRedirectStateForOnlinePayment = (paymentId: string): void => {
    localStorage.removeItem(`paymentId-${paymentId}`);
};

export const generateId = () => {
    return Math.random().toString(16).substring(2, 10);
};

<div class="row animated fadeIn">
    <div
            *ngFor="let image of images"
            class="image">
        <a
                (click)="zoomToImage(image)"
                title="Powiększ">
            <img loading="lazy" src="{{image}}" class="img-responsive">
        </a>
    </div>
</div>


<div style="top: 100px;" class="modal modal-gallery" *ngIf="zoomImage" tabindex="-1" role="dialog" (click)="zoomImage = null">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <img loading="lazy" [src]="zoomImage"/>
            </div>
        </div>
    </div>
</div>

<div style="color: #4F5256; width: 360px;">

  <div class="">
    <h5 class="card-heading" style="padding: 0 16px;">

      <div class="pull-right">
        <button [class.disabled]="unreadCount === 0" (click)="markAllAsRead()"
                class="btn btn-default btn-flat btn-flat-icon" type="button"
                placement="bottom" tooltip="oznacz wszystkie jako przeczytane"
        >
          <em class="ion-android-checkmark-circle"></em>
        </button>
      </div>
      Powiadomienia
    </h5>
  </div>
  <hr style="margin-bottom: 0; padding-bottom: 0"/>

  <h6 *ngIf="!notifications || !notifications.length" class="text-muted" style="padding: 0 20px 20px;">Brak
    powiadomień</h6>

  <div class="mda-list mda-list-bordered">
    <div (click)="makeAction(n)" class="mda-list-item animated fadeIn" *ngFor="let n of notifications">
      <div class="mda-list-item-text">

        <h3 [innerHTML]="n.title"></h3>
        <p [innerHTML]="n.content"></p>

      </div>
    </div>
  </div>
</div>

<div class="container-full">
    <div class="page-container bg-blue-grey-900">
        <div class="container container-xs">
            <div class="text-center mv-lg">
                <img loading="lazy" width="150px" src="/assets/images/tenis4U-white-logo-S.png" class="mv-lg block-center img-responsive"/>
            </div>
            <div *ngIf="mailSent" class="card bg-success">
                <div class="card-heading"><em class="ion-checkmark-round pull-right icon-lg"></em>
                    <h4 class="mt0">E-mail wysłany</h4>
                </div>
                <div class="card-body">
                    <p>Na podany adres został wysłany e-mail z linkiem do odzyskania hasła</p>
                </div>
                <div class="card-footer text-right">
                    <a [routerLink]="'/login'" class="btn btn-success btn-flat text-white">Logowanie <em class="ion-ios-arrow-thin-right icon-fw"></em></a>
                </div>
            </div>
            <form [formGroup]="recForm" class="card form-validate" *ngIf="!mailSent" (submit)="submitForm($event, recForm)" novalidate>
                <div class="card-heading">
                    <p class="text-center text-inherit">Podaj adres e-mail aby otrzymać instrukcję resetowania hasła.</p>
                </div>
                <div class="card-offset pb0">
                    <div *ngIf="recForm.valid" class="card-offset-item text-right">
                        <button type="submit" class="btn btn-success btn-circle btn-lg"><em class="ion-checkmark-round"></em></button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="mda-form-group float-label mda-input-group">
                        <div class="mda-form-control">
                            <input type="email" name="accountName" formControlName="email" [formControl]="recForm.controls['email']" class="form-control" />
                            <div class="mda-form-control-line"></div>
                            <label>Adres e-mail</label>
                        </div>
                        <span class="text-danger" *ngIf="recForm.controls['email'].hasError('required') && (recForm.controls['email'].dirty || recForm.controls['email'].touched)">Pole wymagane</span>
                        <span class="text-danger" *ngIf="recForm.controls['email'].hasError('email') && (recForm.controls['email'].dirty || recForm.controls['email'].touched)">Niepoprawny format adresu e-mail</span>
                        <span class="mda-input-group-addon"><em class="ion-ios-email-outline icon-lg"></em></span>
                    </div>
                </div>
            </form>
            <div class="text-center text-sm"><a [routerLink]="'/login'" class="text-inherit">Logowanie</a></div>
        </div>
    </div>
</div>

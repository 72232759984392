import {Pipe, PipeTransform} from '@angular/core';
import {Bracket} from '@model/tournament/bracket';

@Pipe({
  name: 'challengeScoreSimplePipe'
})
export class ChallengeScoreSimplePipe implements PipeTransform {
  transform(match: Bracket, reverse = false) {
    if (!match) {
      return '';
    }

    const hasWalkover = match.right_player_first_set_result == -1 || match.left_player_first_set_result == -1;
    const withoutLastScores = match.left_player_third_set_result === 0 && match.right_player_third_set_result === 0;


    let result = ChallengeScoreSimplePipe.getPair(match.left_player_first_set_result, match.right_player_first_set_result, reverse);
    result = `${result} - ${ChallengeScoreSimplePipe.getPair(match.left_player_second_set_result, match.right_player_second_set_result, reverse)}`;

    if (hasWalkover || withoutLastScores) {
      return result;
    }

    result = `${result} - ${ChallengeScoreSimplePipe.getPair(match.left_player_third_set_result, match.right_player_third_set_result, reverse)}`;
    return result;
  }

  private static getScore(score: number|string): string | number {
    return score === -1 ? 'W' : score;
  }

  private static getPair(left: number | string, right: number | string, reverse: boolean) {
    return reverse
      ? `${ChallengeScoreSimplePipe.getScore(right)}:${ChallengeScoreSimplePipe.getScore(left)}`
      : `${ChallengeScoreSimplePipe.getScore(left)}:${ChallengeScoreSimplePipe.getScore(right)}`;
  }
}

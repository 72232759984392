import Swal from 'sweetalert2';

interface IProps {
  title: string;
  text?: string;
}

export const swalSuccess = ({text, title}: IProps, showAsInfo = false) => {
  Swal.fire(title, text, showAsInfo ? 'info' : 'success');
};

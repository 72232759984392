// import './vendor.ts';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {AppModule} from './app';

import { environment } from './environments/environment';

// import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";


// Sentry.init({
//   dsn: "https://0552ee7dff8f48a79ded416f7f85a332@o1244611.ingest.sentry.io/6401193",
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-textarea',
  template: `
    <div class="mda-form-group">
      <div class="mda-form-group float-label">
        <div class="mda-form-control">
          <textarea 
              rows="3" 
              [(ngModel)]="model"
              (ngModelChange)="modelChanged($event)"
              class="form-control"
              [class.focus]="alwaysFocus"
          ></textarea>
          <div class="mda-form-control-line"></div>
          <label>{{label}}</label>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TextareaComponent {

  @Input() model: string;
  @Input() label: string;
  @Input() alwaysFocus = false;
  @Output() dataChanged = new EventEmitter<string>();

  modelChanged(data: string) {
    this.dataChanged.emit(data);
  }
}

<app-notification></app-notification>
<div class="layout-container" [ngClass]="layout()">
    <!-- top navbar-->
    <app-header></app-header>
    <!-- sidebar-->
    <app-sidebar></app-sidebar>
    <div (click)="closeSidebar()" class="sidebar-layout-obfuscator"></div>
    <!-- Main section-->
    <main class="main-container">
        <!-- Page content-->
        <section>
            <router-outlet></router-outlet>
        </section>
        <!-- Page footer-->
        <footer *ngIf="!mapView"><span>Dostawcą oprogramowania jest <a href="https://tenis4u.pl/" target="_blank">tenis4U</a> | System zarządzania kortami i turniejami tenisowymi</span></footer>
    </main>
</div>

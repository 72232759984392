import {Component, EventEmitter, Input, Output} from '@angular/core';
import {API_HOST} from '../../../consts';
import {Router} from '@angular/router';
import {AppStateService} from '@core/state/app-state.service';

const DEFAULT_MINIATURE = 'https://api.tenis4u.pl/courtGallery/show/user_picture_miniature_default.png';

@Component({
  selector: 'app-avatar-with-name',
  template: `
    <div class="wrapper">
    <span [class.cursor-pointer]="withUserId && userId"
          [class.display-block]="withEditButton"
          (click)="openProfile();"
    >
        <img loading="lazy"
            alt="avatar"
            class="shadow-z1"
            *ngIf="src"
            [style.height]="size + 'px'"
            [style.width]="size + 'px'"
            src="{{src}}"
        />
<!--      <button type="button" *ngIf="withEditButton" (click)="edit()"-->
<!--              class="btn-raised btn bg-blue-500 btn-info btn-circle btn-sm"><em class="ion-edit"></em></button>-->
    </span>
      <div class="name">{{ name }}</div>
    </div>
  `,
  styles: [`
    img {
      object-fit: cover;
      border-radius:50%;
    }

    button {
      line-height: 30px;
      height: 30px;
      width: 30px;
      position: absolute;
      bottom: -10px;
      right: -10px;
    }

    div {
      position: relative;
    }

    .display-block {
      display: block;
      position: relative;
    }
    .name {
      line-height: 1.3; text-align: left; margin-left: 10px;
    }
    .wrapper {
      display: flex;align-items: center;justify-content: flex-start;
    }
  `]
})
export class AvatarWithNameComponent {
  @Input() size = 64;
  @Input() withEditButton = false;
  @Input() userId: number;
  @Input() name: string;
  @Output() onEdit = new EventEmitter<boolean>();

  withUserId = false;

  private _src = DEFAULT_MINIATURE;

  constructor(private router: Router, private appState: AppStateService) {
      this.withUserId = this.appState.userExists();
  }

  get src() {
    return this._src;
  }

  @Input('src')
  set src(value: string) {
    // @ts-ignore
    if (API_HOST === 'https://api.tenis4u.pl') {
      this._src = value;
    } else {
      this._src = DEFAULT_MINIATURE;
    }
  }

  edit() {
    this.onEdit.emit(true);
  }

  openProfile() {
    if (this.userId && this.appState.userExists()) {
      this.router.navigate(['opponent',this.userId])
    }
  }
}

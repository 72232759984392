import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'roundNameWithPositionPipe',
    pure: true
})
export class RoundNameWithPositionPipe implements PipeTransform {
    transform(round: number, position: number, group: number, isConsolationCup = true) {

        let result = '';
        switch (round) {
            case 0:
                result = 'finał';
                break;
            case 1:
                result = 'półfinał';
                break;
            case 2:
                result = 'ćwierćfinał';
                break;
            case 3:
                result = 'runda 16';
                break;
            case 4:
                result = 'runda 32';
                break;
            case 5:
                result = 'runda 64';
                break;
            case 6:
                result = 'runda 128';
                break;
            case 7:
                result = 'runda 256';
                break;
            case 8:
                result = 'runda 512';
                break;
            default:
                result = `runda ${round}`;
                break;
        }
        const consolation = isConsolationCup && group === 1 ? ' (DP)' : '';

        return `${result} - pozycja ${position + 1}${consolation}`;
    }
}

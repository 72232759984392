import {ReservationDetails} from './reservation-details';
import {PaymentTpay} from '@model/tournament/tournament-details';
import {PaymentDotpay} from '@model/reservation/paymentDotpay';
import {PaymentPrzelewy24} from '@model/reservation/paymentPrzelewy24';

export class ReservationState {
    constructor(
        public errorOccurred: boolean,
        public code: string,
        public details: ReservationDetails,
        public id: string,
        public payment: PaymentDotpay,
        public tpay_payment?: PaymentTpay,
        public przelewy24?: PaymentPrzelewy24
    ) {
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {NextReservation} from '@core/authentication/whoami';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
    template: `
      <div class="next-reservation" [class.mt-20]="courtNameHidden">
        <div *ngIf="reservation">
          <div class="flex-start">
            <em class="fa fa-calendar calendar-icon"></em>
            <div class="time-box">
              <div>{{ reservation.starts_at | momentDate:'HH:mm' }}</div>
              <div>{{ reservation.ends_at | momentDate:'HH:mm' }}</div>
            </div>
            <div>
              <div *ngIf="!courtNameHidden" class="court-name">{{ reservation.court_name }}</div>
              <div class="date">{{ dayOfWeek }} {{ reservation.starts_at | momentDate:'DD.MM' }}</div>
            </div>
          </div>
        </div>
      </div>
    `,
    selector: 'app-dashboard-next-reservation',
    styleUrls: ['../dashboard.component.scss']
})
export class DashboardNextReservationComponent {
    dayOfWeek: string;
    private _reservation: NextReservation;
    get reservation() {
        return this._reservation;
    }

    @Input() courtNameHidden = false;

    @Input('reservation')
    set reservation(value: NextReservation) {
        this._reservation = value;
        if (value) {
            this.dayOfWeek = moment(value.starts_at).locale('pl').format('dddd');
        }
    }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MapTournament} from '@model/map/MapTournament';

@Component({
    selector: 'app-card-tournament-detail',
    templateUrl: './card-tournament-detail.component.html',
    styleUrls: ['./card-tournament-detail.component.scss']
})
export class CardTournamentDetailComponent {
    @Input() tournament: MapTournament;
    @Input() moreInfo = false;
    @Input() boldHerder = false;

    @Output() onRouteChange = new EventEmitter<MapTournament>();

    routeChange(marker: MapTournament) {
        this.onRouteChange.emit(marker);
    }
}

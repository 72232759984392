import Swal from 'sweetalert2';

export default function swalConfirm(
  title = ``,
  callback: () => void,
  html = ``,
  confirmButtonText = 'Tak',
  cancelButtonText = 'Nie') {

  Swal.fire({
    title,
    html,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText
  }).then((result) => {
    if (result.value) {
      callback();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      return;
    }
  });
}

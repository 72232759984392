import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tournamentStatusPipe'
})
export class TournamentStatusPipe implements PipeTransform {
    transform(type: string) {
        switch (type) {
            case 'CREATED':
                return 'ZAPISY';
            case 'APPLICATIONS_CLOSED':
                return 'ZAMKNIĘCIE ZAPISÓW';
            case 'GENERATED':
                return 'LOSOWANIE';
            case 'STARTED':
                return 'ROZPOCZĘTY';
            case 'FINISHED':
                return 'ZAKOŃCZONY';
            case 'DELETED':
                return 'USUNIĘTY';
            default:
                return type;
        }
    }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';





import {Observable} from 'rxjs';
import {RegisterUser} from '@model/auth/RegisterUser';
import {Token} from '@model/auth/token';
import {API_HOST} from '../../consts';
import {Whoami} from '@core/authentication/whoami';
import {getWhoami} from '@shared/utils/whoami';
import {getToken} from '@shared/utils/token';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class AuthRepository {

    private tokenUrl = `${API_HOST}/oauth/token`;

    constructor(private http: HttpClient) {
    }

    login(username: string, password: string): Observable<Token> {
        const creds = 'grant_type=password' + '&username=' + username + '&password=' + encodeURIComponent(password);
        return this.http.post<Token>(this.tokenUrl, creds);
    }

    refreshToken(): Observable<Token> {
        const token = getToken();
        if (!token) {
            return of();
        }
        const creds = 'grant_type=refresh_token&refresh_token=' + token.refresh_token;
        return this.http.post<Token>(this.tokenUrl, creds);
    }

    register(user: RegisterUser): Observable<any> {
        return this.http.post<any>(`${API_HOST}/user/register`, user);
    }

    recoverPassword(email: string): Observable<any> {
        return this.http.post(`${API_HOST}/user/requestResetPassword`, {email});
    }

    resetPassword(password: string, code: string) {
        return this.http.post(`${API_HOST}/user/resetPassword`, {password, code});
    }

    whoAmI(): Observable<Whoami> {
        return this.http.get<Whoami>(`${API_HOST}/whoami`);
    }

    sendSms(code: string, phone_number: string): Observable<any> {
        return this.http.post<any>(`${API_HOST}/user/confirm`, {code: code, phone_number: phone_number});
    }

    public userExist(): boolean {
        return !!getToken()
          && !!getWhoami()
          && !!localStorage.getItem('expires_at');
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserPanelService} from '@services/user/user-panel.service';
import {Whoami} from '@core/authentication/whoami';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import swalError from '@shared/utils/swal/swalError';
import {TournamentService} from '@services/tournament/tournament.service';
import {MyTournament} from '@model/tournament/my-tournament';
import {MyReservationsService} from '@services/reservation/my-reservations.service';
import {
    ReservationIndividual, ReservationRepeatable,
    UserReservation,
    UserReservationRepeatable
} from '@model/reservation/UserReservation';
import {AppStateService} from '@core/state/app-state.service';
import {Subscription} from 'rxjs';
import {PagetitleService} from '@core/pagetitle/pagetitle.service';
import {Opponent} from '@model/opponent/opponent';
import {MyChallenge} from '@model/tournament/challenge/my-challenge';
import {Router} from '@angular/router';
import * as moment from 'moment';

export interface DashboardReservations {
    repeatable: ReservationRepeatable[];
    single: ReservationIndividual[];
    history: ReservationIndividual[];
    tournament: ReservationIndividual[];
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    public whoami: Whoami;
    public stats: Opponent;
    public userTournaments: MyTournament[] = [];
    public reservations: DashboardReservations = {
        repeatable: [],
        single: [],
        history: [],
        tournament: [],
    };
    public tournamentList = 'active';
    private sub1$: Subscription;
    private reservationsResponse: UserReservation;
    public challenges: MyChallenge[] = [];
    historyRep = false;
    historySingle = false;

    constructor(private userService: UserPanelService,
                private reservationService: MyReservationsService,
                private tournamentService: TournamentService,
                private appState: AppStateService,
                private router: Router,
                private pt: PagetitleService) {
        pt.setTitle('Dashboard');
    }

    ngOnInit() {
        this.loadData();
        this.sub1$ = this.appState.reservationUpdateStream().subscribe(() => {
            this.reservationService.getUserReservations().subscribe(next => {
                this.reservationsResponse = next;
                this.loadRepReservations(true);
                this.loadReservations(true);
            });

        });
    }

    refreshReservations() {
        this.reservationService.getUserReservations().subscribe(
            success => {
                this.reservations.single = success.individual.filter(item => this.isSingle(item) && this.active(item)).concat(...success.individual.filter(item => this.isTournament(item) && this.active(item)));
            }
        );
    }

    ngOnDestroy() {
        this.sub1$.unsubscribe();
    }

    // tylko przyszłe i te do odrobienia
    // bez rezerwacji usuniętych, które nie miały wyznaczonej ceny całościowej
    isRepeatableActive(item: ReservationRepeatable): ReservationRepeatable {
        const removeDeleted = !item.price || item.price === 0;

        item.reservations = item.reservations
            .filter(r => r.minutes_to_receive > 0 || this.active(r))
            .filter(r => removeDeleted ? r.status !== 'DELETED' : true);

        return item;
    }

    // tylko przeszłe
    // bez rezerwacji usuniętych, które nie miały wyznaczonej ceny całościowej
    isRepeatableHistory(item: ReservationRepeatable): ReservationRepeatable {
        const removeDeleted = !item.price || item.price === 0;

        item.reservations = item.reservations
            .filter(r => !this.active(r))
            .filter(r => removeDeleted ? r.status !== 'DELETED' : true);

        return item;
    }

    isSingle(item: ReservationIndividual): boolean {
        return item.match === null &&
            (item.status === 'UNCONFIRMED' || item.status === 'CONFIRMED');
    }

    isTournament(item: ReservationIndividual): boolean {
        return item.match !== null &&
            (item.status === 'UNCONFIRMED' || item.status === 'CONFIRMED');
    }

    public loadTournaments(active = false) {
        this.tournamentList = active ? 'active' : 'history';
        this.tournamentService.getMyTournaments(active).subscribe(
            next => {
                this.userTournaments = next
                    .sort((a, b) => {
                        if (a.ends_date && b.ends_date) {
                            return new Date(a.ends_date) >= new Date(b.ends_date) ? -1 : 1;
                        }
                        return 0;
                    });
            },
            err => swalError('Błąd pobierania danych')
        );

    }

    loadReservations(active: boolean) {
        this.historySingle = !active;
        this.reservations.single = active
            ? this.reservations.single = this.reservationsResponse.individual
                .filter(item => this.isSingle(item) && this.active(item))
                .concat(...this.reservationsResponse.individual.filter(item => this.isTournament(item) && this.active(item)))
                .sort((a, b) => new Date(a.starts_at) >= new Date(b.starts_at) ? 1 : -1)
            : this.reservations.history = this.reservationsResponse.individual
                .filter(item => item.status === 'CONFIRMED' && !this.active(item))
                .concat(...this.reservationsResponse.individual.filter(item => this.isTournament(item) && !this.active(item)))
                .sort((a, b) => new Date(a.starts_at) >= new Date(b.starts_at) ? -1 : 1)
        ;
    }

    loadRepReservations(active: boolean) {
        this.historyRep = !active;
        const repeatableClone = JSON.parse(JSON.stringify(this.reservationsResponse.repeatable));

        this.reservations.repeatable = active
            ? repeatableClone.map(item => this.isRepeatableActive(item)).filter(item => item.reservations.length > 0)
            : repeatableClone.map(item => this.isRepeatableHistory(item)).filter(item => item.reservations.length > 0);
    }

    public refreshChallenges() {
        this.tournamentService.getMyChallenges().subscribe(
            challenges => {
                this.challenges = challenges
                    .filter(ch => ch.side === 'CHALLENGED' && ch.status === 'PENDING');
            }
        );

    }

    public loadData() {
        this.userService.whoAmI().pipe(
            switchMap((next: Whoami) => {
                this.whoami = next;
                return this.userService.stats(next.id);
            }),
            switchMap(stats => {
                this.stats = stats;
                return this.tournamentService.getMyChallenges();
            }),
            switchMap((challenges: MyChallenge[]) => {
                this.challenges = challenges
                    .filter(ch => ch.side === 'CHALLENGED' && ch.status === 'PENDING');
                return this.tournamentService.getMyTournaments(true);
            }),
            switchMap(tournaments => {
                this.userTournaments = tournaments;
                return this.reservationService.getUserReservations();
            }),
            map(success => {
                this.reservationsResponse = success;
                this.loadRepReservations(true);
                this.loadReservations(true);
                // this.reservations.single = success.individual.filter(item => this.isSingle(item) && this.active(item)).concat(...success.individual.filter(item => this.isTournament(item) && this.active(item)));
                // this.reservations.history = success.individual.filter(item => item.status === 'CONFIRMED' && !this.active(item)).concat(...success.individual.filter(item => this.isTournament(item) && !this.active(item)));
                this.reservations.tournament = success.individual.filter(item => this.isTournament(item));
            }),
            tap(() => {
                this.initDashboardData();
            }),
            catchError(error => {
                swalError('Błąd pobierania danych');
                return throwError(error);
            })
        ).subscribe();
    }

    private initDashboardData() {
    }

    private active(reservation: ReservationIndividual | UserReservationRepeatable): boolean {
        const now = new Date();

        const a: any[] = reservation.ends_at.split(/[^0-9]/);
        const reservationDate = new Date(a[0], a[1] - 1, a[2], a[3], a[4]);

        return reservationDate > now;
    }

    goto(id: number) {
        this.router.navigate([`/tournament/${id}`], {queryParams: {'tab': 'challenge-scores'}});
    }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MyTournament} from '@model/tournament/my-tournament';
import {TournamentService} from '@services/tournament/tournament.service';

@Component({
    selector: 'app-tournaments',
    templateUrl: './tournaments.component.html',
    styleUrls: ['./tournaments.component.scss']
})
export class TournamentsComponent implements OnInit {

    public active: MyTournament[] = [];
    public finished: MyTournament[] = [];
    public tab = 'active';

    @Output() onTournamentClick = new EventEmitter();


    constructor(private route: ActivatedRoute,
                private tournamentService: TournamentService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.loadMyTournaments();
    }

    changeTab(type: string) {
        this.tab = type;
    }

    goto(id: number) {
        this.router.navigate([`tournament/${id}`], {queryParams: {'tab': 'info'}});
        this.onTournamentClick.emit(true);
    }

    private loadMyTournaments() {
        this.tournamentService.getMyTournaments(true).subscribe(
            next => this.active = next.filter(t => !(t.reserve_list && t.status !== 'CREATED')),
            // err => this.authService.toastrMsg('Błąd podczas pobierania listy aktywnych turniejów')
        );
        this.tournamentService.getMyTournaments(false).subscribe(
            next => this.finished = next.filter(t => !t.reserve_list),
            // err => this.authService.toastrMsg('Błąd podczas pobierania listy zakończonych turniejów')
        );
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {UserBasicDataDto} from '@model/user/UserBasicDataDto';
import {Whoami} from '@core/authentication/whoami';

@Component({
    selector: 'app-user-panel-edit',
    templateUrl: 'user-panel-edit.html'
})
export class UserPanelEditComponent {
    @Input() user: Whoami = null;
    @Input() userDto: UserBasicDataDto = new UserBasicDataDto();
}

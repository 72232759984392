import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'tournamentPositionPipe',
    pure: true
})
export class TournamentPositionPipe implements PipeTransform {
    transform(position: string | null) {

        if (!position) {
            return '-'
        }
        switch (position) {
            case 'WON':
                return 'Wygrana';
            case 'FINAL':
                return 'Finał';
            case 'SEMI_FINAL':
                return 'Półfinał';
            case 'QUARTERS':
                return 'Ćwierćfinał';
            case 'ROUND_OF_16':
                return 'Runda 1/16';
            case 'ROUND_OF_32':
                return 'Runda 1/32';
            case 'LEAGUE_1':
                return 'Wygrana';
            case 'LEAGUE_2':
                return '2 miejsce';
            case 'LEAGUE_3':
                return '3 miejsce';
            case 'LEAGUE_4':
                return '4 miejsce';
            case 'LEAGUE_5':
                return '5 miejsce';
            case 'LEAGUE_6':
                return '6 miejsce';
            case 'LEAGUE_7':
                return '7 miejsce';
            case 'LEAGUE_8':
                return '8 miejsce';
            case 'LEAGUE_9':
                return '9 miejsce';
            case 'LEAGUE_10':
                return '10 miejsce';
            case 'LEAGUE_11':
                return '11 miejsce';
            case 'LEAGUE_12':
                return '12 miejsce';
            case 'LEAGUE_13':
                return '13 miejsce';
            case 'LEAGUE_14':
                return '14 miejsce';
            case 'LEAGUE_15':
                return '15 miejsce';
            case 'LEAGUE_16':
                return '16 miejsce';
            case 'LEAGUE_17':
                return '17 miejsce';
            case 'LEAGUE_18':
                return '18 miejsce';
            case 'LEAGUE_19':
                return '19 miejsce';
            case 'LEAGUE_20':
                return '20 miejsce';
            case 'LEAGUE_BELOW_20':
                return 'Poniżej 20 miejsca';
        }
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '@services/notification/notification.service';
import {NotificationRepository} from '@services/notification/notification.repository';
import {Notification} from '@model/notification/notification';
import {Subscription} from 'rxjs/Subscription';
import {swalError} from '@shared/swal/swalError';
import {from} from 'rxjs/internal/observable/from';
import {catchError, flatMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AppStateService} from '@core/state/app-state.service';

// @todo: do przeniesienia do layout/header/components
@Component({
    selector: 'app-user-notifications',
    templateUrl: './user-notifications.component.html',
    styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
    public notifications: Notification[] = [];
    public unreadCount = 0;
    private subscription$: Subscription;

    constructor(private notificationService: NotificationService,
                private notificationRepository: NotificationRepository,
                private state: AppStateService,
                private router: Router) {
    }

    ngOnInit() {
        this.subscription$ = this.notificationService.notificationChanged().subscribe(
            next => {
                this.notifications = this.parseNotifications(next);
            });

        this.loadNotifications();
    }

    ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    parseNotifications(notifications: Notification[]): Notification[] {
        this.unreadCount = notifications.filter(n => !n.is_read).length;
        if (
            this.notifications.length !== 0 &&
            this.notifications.filter(n => !n.is_read).length === notifications.filter(m => !m.is_read).length
        ) {
            return this.notifications;
        }

        return notifications.map(n => {
            n.title = this.changeIcons(n.title);
            if (!n.is_read) {
                n.title = `${n.title}<i class="fa fa-circle color-green-500"></i>`;
            }
            n.content = this.changeIcons(n.content);
            return n;
        });
    }

    changeIcons(text: string): string {
        let newString = '';
        let emoji = false;
        const str = text;
        for (let i = 0; i < text.length; i++) {
            if (emoji) {
                if (str[i] == '\\') {
                    continue;
                }
                if (str[i] !== ' ') {
                    newString += str[i];
                }
                if (str[i] == ' ') {
                    // end emoji
                    emoji = false;
                    newString += '; ';
                }
                continue;
            }

            if (str[i] == '\\') {
                // start creating emoji
                emoji = true;
                newString += '&#x';
                continue;
            }
            newString += str[i];
        }
        return newString;
    }

    loadNotifications() {
        this.notificationRepository.getNotifications().subscribe(next => {
            this.notifications = this.parseNotifications(next);
            this.notificationService.notify(next);
        });
    }

    markAllAsRead() {
        const ids = this.notifications.filter(n => !n.is_read).map(m => m.id);
        if (ids.length === 0) {
            return;
        }

        this.notificationRepository.readAll().pipe(
            tap(() => this.loadNotifications()),
            catchError(err => {
                swalError({title: 'Niepowodzenie'});
                throw err;
            })
        ).subscribe();
    }

    makeAction(notification: Notification) {
        if (notification.is_read) {
            this.findAction(notification);
            return;
        }

        this.notificationRepository.markAsRead(notification.id).pipe(
            tap(() => this.loadNotifications()),
        ).subscribe(
            () => this.findAction(notification),
            () => swalError({title: 'Niepowodzenie'})
        );
    }

    private findAction(notification: Notification) {
        if (!notification.action) {
            return;
        }
        if (notification.action.includes('openMyMatches?tournamentId=')) {
            const parts = notification.action.split('tournamentId=');
            const tournamentId = parts[1];
            this.router.navigate([`tournament/${tournamentId}`]);
        }

        if ('tenis4u://myReservations' === notification.action) {
            this.router.navigate([`user/dashboard`]);
            // this.state.openMyReservation(true);
        }
    }
}

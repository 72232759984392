import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tariffCost'
})
export class TariffCostPipe implements PipeTransform {

  transform(cost: any, args?: any): any {
      return cost === 0
          ? '0zł'
          : `${Number(cost).toFixed(2).toString()}zł`;
  }

}

import {Injectable} from '@angular/core';
import {AppStateService} from '@core/state/app-state.service';
import {PanelExternalLink} from '../../routes/menu';
import {getWhoami} from '@shared/utils/whoami';
import {Warn} from '@model/user/Warn';

export interface SidebarMenuLink {
  name: string;
  link?: string;
  href?: string;
  imgpath?: string;
  order?: number;
  iconclass?: string;
  label?: any;
  subitems?: Array<any>;
  sidebar?: boolean;
  moderatorOnly?: boolean;
  courtViewOnly?: boolean;
}

@Injectable()
export class MenuService {

  menuItems: SidebarMenuLink[] = [];
  defaultMenuItems: SidebarMenuLink[] = [];
  courtViewMenuItems: SidebarMenuLink[] = [];

  constructor(private state: AppStateService) {
    this.menuItems = [];
    this.state.userChanged().subscribe(() => this.updateMenu());
  }

  updateMenu() {
    const user = getWhoami();
    this.menuItems = JSON.parse(JSON.stringify(this.defaultMenuItems));
      // console.log('can_access_panel', user ? user.can_access_panel : false);
    if (user && user.can_access_panel) {
      this.menuItems.push(PanelExternalLink);
    }

    return this.menuItems;
  }

  addMenu(defaultItems: SidebarMenuLink[], courtViewItems: SidebarMenuLink[]) {
    this.defaultMenuItems = defaultItems;
    this.courtViewMenuItems = courtViewItems;
    this.updateMenu();
  }

  getMenuSorted() {
    return this.menuItems.sort((a, b) => {
      return a.order - b.order;
    });
  }

  getMenuForCourtView() {
    const items = JSON.parse(JSON.stringify(this.courtViewMenuItems));
    const user = getWhoami();
    // console.log('can_access_panel', user ? user.can_access_panel : false);
    if (user && user.can_access_panel) {
      items.push(PanelExternalLink);
    }

    return items;
  }
}

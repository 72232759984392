import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-panel-password',
    templateUrl: 'user-panel-password.html'
})
export class UserPanelPasswordComponent {
    @Input() passwordDto: {
        oldPassword: string,
        newPassword: string,
        newPassRepeat: string
    } = null;

}

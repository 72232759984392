import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Bracket} from '@model/tournament/bracket';
import {RoundScore} from '@model/tournament/league/roundScore';
import {Summary} from '@model/tournament/league/summary';
import {TournamentMapData} from '@model/tournament/map-tournament-data';
import {MyTournament} from '@model/tournament/my-tournament';
import {Player} from '@model/tournament/player';
import {TournamentDetails, PaymentTpay} from '@model/tournament/tournament-details';
import {TournamentInfo} from '@model/tournament/tournament-info';
import {TournamentReservation} from '@model/tournament/tournament-reservation';
import {UpdateMatchDataDto} from '@model/tournament/update-match-data-dto';
import {UserMatch} from '@model/tournament/user-match';
import {API_HOST} from '../../consts';
import {StationSchedule} from '@model/tournament/schedule/StationSchedule';
import {CourtTournament} from '../../routes/court/tabs/info/court-tournaments-modal/court-tournaments-modal.component';
import {MyChallenge} from '@model/tournament/challenge/my-challenge';
import {ChallengeBracket} from '@model/tournament/challenge/challenge-bracket';
import {ChallengeOpponentParams} from '../../routes/tournament/tabs/components/challenge/startChallengeModal.component';
import {BallsPrice} from '@services/tournament/ballsPrice';
import {OrderBallsDto} from '../../routes/tournament/tabs/components/challenge/balls/balls.component';
import {EditChallengeParams} from '../../routes/tournament/tabs/components/challenge/editChallengeModal.component';
import {ChallengeSettings} from '@model/tournament/challenge/challenge-settings';
import {PftRanking} from '@model/tournament/pft-ranking';
import {TournamentPftMapData} from '@model/tournament/map-pft-tournament-data';
import {ChallengeStats} from '@model/tournament/challenge/ChallengeStats';

@Injectable()
export class TournamentService {

    constructor(private http: HttpClient) {
    }

    tournamentInfo(): Observable<TournamentInfo> {
        return this.http.get<TournamentInfo>(`${API_HOST}/tournament`);
    }

    getBrackets(tournamentId: number): Observable<Bracket[]> {
        return this.http.get<Bracket[]>(`${API_HOST}/tournament/brackets/${tournamentId}`);
    }

    getRoundsScore(tournamentId: number): Observable<RoundScore[]> {
        return this.http.get<RoundScore[]>(`${API_HOST}/tournament/brackets/league/${tournamentId}`);
    }

    getLeagueSummary(tournamentId: number): Observable<Summary[]> {
        return this.http.get<Summary[]>(`${API_HOST}/tournament/summary/league/${tournamentId}`);
    }

    getTournamentsForMap(): Observable<TournamentMapData[]> {
        return this.http.get<TournamentMapData[]>(`${API_HOST}/tournament/map`);
    }

    getTournamentsForPftMap(): Observable<TournamentPftMapData[]> {
        return this.http.get<TournamentPftMapData[]>(`${API_HOST}/tournament/series/map/pft`);
    }

    getUserMatches(): Observable<UserMatch[]> {
        return this.http.get<UserMatch[]>(`${API_HOST}/tournament/myMatches`);
    }

    getPlayers(tournamentId: number): Observable<Player[]> {
        return this.http.get<Player[]>(`${API_HOST}/tournament/players/${tournamentId}`);
    }

    getTournamentDetails(tournamentId: number): Observable<TournamentDetails> {
        return this.http.get<TournamentDetails>(`${API_HOST}/tournament/${tournamentId}`);
    }

    joinTournament(tournamentId: number): Observable<{reserve_list: boolean}> {
        return this.http.post<{reserve_list: boolean}>(`${API_HOST}/tournament/join/${tournamentId}`, {});
    }

    leaveTournament(tournamentId: number): Observable<any> {
        return this.http.post(`${API_HOST}/tournament/leave/${tournamentId}`, {});
    }

    createReservationForUser(dto: TournamentReservation): Observable<any> {
        return this.http.post(`${API_HOST}/tournament/reservation`, dto);
    }

    setMatchResult(match_id: number, dto: UpdateMatchDataDto) {
        return this.http.post(`${API_HOST}/tournament/match/result/${match_id}`, dto);
    }

    getMyTournaments(active: boolean): Observable<MyTournament[]> {
        return active
            ? this.http.get<MyTournament[]>(`${API_HOST}/tournament/myActive`)
            : this.http.get<MyTournament[]>(`${API_HOST}/tournament/myFinished`);
    }

    getCourtTournaments(courtId: number): Observable<CourtTournament> {
        return this.http.get<CourtTournament>(`${API_HOST}/tournament/byCourt/${courtId}`);
    }

    startDispute(matchId: number, message: string): Observable<any> {
        return this.http.post(`${API_HOST}/tournament/match/startDispute/${matchId}`, {message});
    }

    getSchedules(tournamentId: number): Observable<StationSchedule[]> {
        return this.http.get<StationSchedule[]>(`${API_HOST}/tournament/schedule/${tournamentId}`);
    }

    // challenge start

    challengeOpponent(dto: ChallengeOpponentParams): Observable<any> {
        return this.http.post(`${API_HOST}/tournament/challenge`, {...dto});
    }
    claimChallenge(challengeeId: string, accept: boolean|string): Observable<any> {
        return this.http.put(`${API_HOST}/tournament/challenge/claim/${challengeeId}`, {accept});
    }
    moveChallenge(challengeeId: string, dto: EditChallengeParams): Observable<any> {
        return this.http.put(`${API_HOST}/tournament/challenge/move/${challengeeId}`, dto);
    }
    cancelChallenge(challengeeId: string): Observable<any> {
        return this.http.delete(`${API_HOST}/tournament/challenge/${challengeeId}`, {});
    }
    getMyChallenges(tournamentId?: number): Observable<MyChallenge[]> {
        return this.http.get<MyChallenge[]>(`${API_HOST}/tournament/challenge/my${tournamentId ? '?tournamentId=' + tournamentId : ''}`);
    }
    getChallenges(tournamentId?: number): Observable<ChallengeBracket[]> {
        return this.http.get<ChallengeBracket[]>(`${API_HOST}/tournament/brackets/challenge/${tournamentId}`);
    }

    goVacation(vacation_ends_at: string, tournamentId: number) {
        return this.http.put(`${API_HOST}/tournament/vacation/${tournamentId}`, {vacation_ends_at});
    }

    challengeSettings(challengeId: number): Observable<ChallengeSettings> {
        return this.http.get<ChallengeSettings>(`${API_HOST}/tournament/settings/challenge/${challengeId}`);
    }

    challengeStats(challengeId: number): Observable<ChallengeStats> {
        return this.http.get<ChallengeStats>(`${API_HOST}/tournament/stats/challenge/${challengeId}`);
    }
    // challenge end

    // pft start
    getPftRanking(): Observable<PftRanking[]> {
        return this.http.get<PftRanking[]>(`${API_HOST}/tournament/series/ranking/pft`);
    }
    // pft end

    // balls shop

    getBallsPrice(quantity: number): Observable<BallsPrice> {
        return this.http.get<BallsPrice>(`${API_HOST}/promotion/balls/price?quantity=${quantity}`);
    }

    buyBalls(dto: OrderBallsDto): Observable<PaymentTpay> {
        return this.http.put<PaymentTpay>(`${API_HOST}/promotion/balls/order`, dto);
    }

    // balls shop
}

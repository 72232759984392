import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MenuService } from '@core/menu/menu.service';
import { SharedModule } from '@shared/shared.module';
import { AuthModule } from './auth/auth.module';

import {courtPageMenu, menu} from './menu';
import { routes } from './routes';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        AuthModule
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class RoutesModule {
    constructor(private menuService: MenuService) {
        menuService.addMenu(menu, courtPageMenu);
    }
}

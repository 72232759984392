import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapCourt} from '@model/map/MapCourt';

@Component({
    selector: 'app-card-court-detail',
    template: `
        <div *ngIf="court">
            <div class="card-title" [class.text-bold]="boldHerder">{{court.name}}</div>
            <div class="card-text">
                ceny / 1h: <span class="color-blue-500">{{court.pricing}}</span>
            </div>
            <div class="card-text">
                rezerwacje:
                <span *ngIf="court.reservation" class="text-success text-bold">on-line</span>
                <span *ngIf="!court.reservation" class="text-bold">off-line</span>
            </div>
            <!--<span *ngIf="court.reservation">-->
            <span *ngIf="court.marker === 'ACTIVE' || court.marker === 'RESERVATIONS_DISABLED'" class="badge bg-success">czynne</span>
            <span *ngIf="court.marker === 'FLOODED'" class="badge bg-warning">zalane</span>
            <span *ngIf="court.marker === 'NO_ACTIVE_STATIONS'" class="badge bg-gray-light">nieczynne</span>
            <!--</span>-->
            <span *ngIf="court.multisport" class="badge bg-blue-500">multisport</span>
            <span *ngIf="court.medicover" class="badge bg-blue-700">medicover</span>
            <a *ngIf="moreInfo" (click)="routeChange(court)" class="badge bg-black">zarezerwuj</a>
        </div>
    `,
    styles: [`
        .card-title {
            line-height: 24px;
            margin-bottom: 8px;
            font-size: 18px;
            font-weight: 300;
        }

        .badge {
            border-radius: 4px;
            width: min-content;
            padding: 0 4px;
            font-weight: 300;
            margin-top: 10px;
        }

        .bg-gray-light {
            color: #fff !important;
        }
    `]
})
export class CardCourtDetailComponent {
    @Input() court: MapCourt;
    @Input() moreInfo = false;
    @Input() boldHerder = false;

    @Output() onRouteChange = new EventEmitter<MapCourt>();

    routeChange(marker: MapCourt) {
        this.onRouteChange.emit(marker);
    }
}
